import { createAsyncThunk } from "@reduxjs/toolkit";
import { getManyNotificationsService } from "@src/services/Message";
import {
  NotificationEntity,
  JsonapiAsyncThunkConfig,
  JsonapiResponseFetchMany,
} from "@src/types";

const getManyNotifications = createAsyncThunk<
  JsonapiResponseFetchMany<NotificationEntity>,
  void,
  JsonapiAsyncThunkConfig
>(`notifications/fetch`, async (payload, { rejectWithValue, signal }) => {
  try {
    return await getManyNotificationsService({
      signal,
    });
  } catch (err) {
    return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
  }
});

export const getManyNotificationsActions = {
  fetch: getManyNotifications,
};
