import { Home } from "react-feather";
import { NavLink, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export type BreadCrumbsProps = {
  breadCrumbTitle?: string;
  children?: JSX.Element;
  overWriteActiveText?: string;
};

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({
  breadCrumbTitle,
  overWriteActiveText,
  children,
}) => {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const names = paths.map((o) => o.charAt(0).toUpperCase() + o.substr(1));
  for (let i = 1; i < paths.length; i++) {
    paths[i] = `${paths[i - 1]}/${paths[i]}`;
  }

  const elements = paths.map(
    (o, i) =>
      i !== 0 && (
        <BreadcrumbItem
          key={`breadcrumb-element-${i}`}
          tag="li"
          active={i === paths.length - 1}
        >
          {i === paths.length - 1 ? (
            overWriteActiveText || names[i]
          ) : (
            <NavLink to={o}>{names[i]}</NavLink>
          )}
        </BreadcrumbItem>
      )
  );

  return (
    <div className="content-header row">
      <div className="content-header-left col-md-9 col-12 mb-2">
        <div className="row breadcrumbs-top">
          <div className="col-12">
            {breadCrumbTitle && (
              <h2 className="content-header-title float-left mb-1">
                {breadCrumbTitle}
              </h2>
            )}
            <div className="breadcrumb-wrapper vx-breadcrumbs d-sm-block d-none col-12">
              <Breadcrumb tag="ol">
                <BreadcrumbItem tag="li">
                  <NavLink to="/dashboard">
                    <Home className="align-top" size={15} />
                  </NavLink>
                </BreadcrumbItem>
                {elements}
              </Breadcrumb>
            </div>
          </div>
          <div className="col">{children}</div>
        </div>
      </div>
    </div>
  );
};
