// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";
import { Reducer } from "@reduxjs/toolkit";
import { LayoutAction, LayoutState } from "@types";

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = (): boolean => {
  const item = window.localStorage.getItem("menuCollapsed");
  //** Parse stored json or if none return initialValue
  return item
    ? (JSON.parse(item) as boolean)
    : themeConfig.layout.menu.isCollapsed;
};

const layoutReducer: Reducer<LayoutState, LayoutAction> = (
  state = {
    isRTL: themeConfig.layout.isRTL,
    menuCollapsed: initialMenuCollapsed(),
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: themeConfig.layout.contentWidth,
  },
  action
) => {
  switch (action.type) {
    case "HANDLE_CONTENT_WIDTH":
      return { ...state, contentWidth: action.value };

    case "HANDLE_MENU_COLLAPSED":
      // @ts-ignore
      window.localStorage.setItem("menuCollapsed", action.value);
      return { ...state, menuCollapsed: action.value };

    case "HANDLE_MENU_HIDDEN":
      return { ...state, menuHidden: action.value };

    case "HANDLE_RTL":
      return { ...state, isRTL: action.value };

    default:
      return state;
  }
};

export default layoutReducer;
