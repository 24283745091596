export const apiConfig = {
  baseUrl: "/v3",

  deserializeOptions: {
    changeCase: "camelCase",
  },
  serializeOptions: {
    changeCase: "kebabCase",
  },
} as const;
