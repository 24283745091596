import { PropsWithChildren } from "react";
import { Trash2 } from "react-feather";
import { Button, Card, CardBody, Col, Label } from "reactstrap";

export type ReservationListFilterInputWrapperProps = PropsWithChildren<{
  label: string;
  onRemoveClick?: () => void;
}>;

export const ReservationListFilterInputWrapper: React.FC<ReservationListFilterInputWrapperProps> =
  ({ children, label, onRemoveClick }) => {
    const content = onRemoveClick ? (
      <div className="d-flex">
        <div
          className="flex-grow-1 d-flex align-items-center"
          style={{
            maxWidth: "calc(100% - 17px - 1.25rem)",
          }}
        >
          <div className="d-block w-100">{children}</div>
        </div>

        <div className="d-flex align-items-center">
          <Button
            color="flat-secondary"
            className="btn-icon"
            onClick={onRemoveClick}
          >
            <Trash2 size={17} />
          </Button>
        </div>
      </div>
    ) : (
      children
    );

    return (
      <Col sm={6} lg={4} className="col-xxl-3">
        <Card className="filter-card">
          <CardBody>
            <Label>{label}</Label>
            {content}
          </CardBody>
        </Card>
      </Col>
    );
  };
