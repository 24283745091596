import PerfectScrollbar from "react-perfect-scrollbar";
import { useAppDispatch, useAppSelector, useToggle } from "@hooks/.";
import { ChatLogSimple } from "@src/components";
import { getManyMessagesActions } from "@src/redux/Message";
import {
  ChatContact,
  ErrorState,
  GuestEntity,
  ReservationEntity,
} from "@src/types";
import {
  getDefaultAvatar,
  getGravatarUrl,
  isChatMaximized,
} from "@src/utility";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import classnames from "classnames";
import Avatar from "@components/avatar";
import { Badge } from "reactstrap";
import { ErrorModal } from "./ErrorModal";
import getMessageTemplatesService from "@src/services/Message/getMessageTemplatesService";

export type ReservationDetailsChatProps = {
  data:
    | ReservationEntity
    | {
        id: EntityId;
        mainGuest: GuestEntity;
        guests?: GuestEntity[];
      };
  currentGuestId: EntityId;
  setCurrentGuestId?: Dispatch<SetStateAction<EntityId | undefined>>;
};

// @KM: @TODO: move type out?
export type MessageAttributes = {
  authorType: string;
  authorId: number;
  authorName: string;
  authorEmail: string;
  body: string;
  read: boolean;
  readBy: any;
  guestId: number;
  sentAt: string;
};

export const ChatLog: React.FC<ReservationDetailsChatProps> = ({
  data,
  currentGuestId,
  setCurrentGuestId,
}) => {
  const [errorModalOpen, toggleErrorModal] = useToggle();

  const { entities: propertyEntities } = useAppSelector(
    (state) => state.entities.property
  );

  const { validUntil } = useAppSelector(
    (state) => state.app.message.templateExpiration
  );

  const [error, setError] = useState<ErrorState>({
    title: "Something went wrong",
    message: "",
  });
  const dispatch = useAppDispatch();

  const { notifications } = useAppSelector(
    (state) => state.app.notifications.allList
  );

  const guestMessages = useAppSelector(
    (state) => state.app.guest.byIdMessages[currentGuestId]
  );
  const messagesData = guestMessages?.messages || [];

  const chatLogEntries = messagesData?.map((msg: any) => {
    // @TODO: @KM: proper type later? (any)
    const newDate = new Date(msg.sentAt);
    const time = `${Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(newDate)} ${Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
    }).format(newDate)}`;

    return {
      authorType: msg.authorType,
      entityId: msg.id,
      isSystem: msg.authorType === "Property",
      read: msg.read,
      readBy: msg.readBy,
      senderId: msg.authorId,
      senderEmail: msg.authorEmail,
      senderName: msg.authorName,
      message: msg.body,
      time,
    };
  });

  useEffect(() => {
    if (guestMessages?.error?.code) {
      setError(guestMessages?.error);
      toggleErrorModal();
    }
  }, [guestMessages?.error]);

  const handleChangeGuestId = (guestId: EntityId) => {
    if (setCurrentGuestId) setCurrentGuestId(guestId);
    dispatch(getManyMessagesActions.fetchByGuest(guestId));
  };

  const updateChatMessages = () => {
    if (isChatMaximized) {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const guestId = params.get("id");
      handleChangeGuestId(guestId ?? data.mainGuest.id);
    } else {
      handleChangeGuestId(data.mainGuest.id);
    }
  };

  useEffect(() => {
    updateChatMessages();
  }, [notifications, data.id]);

  const contact: ChatContact = {
    avatar: data.mainGuest.email
      ? getGravatarUrl(data.mainGuest.email)
      : getDefaultAvatar(data.mainGuest.gender),
    fullName: `${data.mainGuest.firstName} ${data.mainGuest.lastName}`.trim(),
    gender: data.mainGuest.gender,
    status: "online",
  };

  useEffect(() => {
    const date = new Date();
    const today = date.getTime();
    // seconds * minutes * hours * milliseconds * days
    const week = 60 * 60 * 24 * 1000 * 7;

    const isExpired = validUntil < today;
    if (!isExpired) {
      return;
    }

    getMessageTemplatesService({ today, week, propertyEntities });
  }, []);

  const renderChatSwitchButton = (guest: GuestEntity) => {
    const { id, email, gender, firstName, lastName } = guest;

    const fullName = [firstName, lastName].join(" ");
    const avatarSrc = email ? getGravatarUrl(email) : getDefaultAvatar(gender);

    const newMessages = notifications.filter(
      (notification: any) => +notification.authorId === +guest.id
    );

    return (
      <div
        className={classnames("chat-tab", {
          "active-tab": currentGuestId === id,
          "new-message-tab": !!newMessages.length,
        })}
        key={id}
        onClick={() => handleChangeGuestId(guest.id)}
      >
        <Avatar
          img={avatarSrc}
          imgClassName="guest-image"
          fallbackSrc={getDefaultAvatar(gender)}
          imgHeight="20"
          imgWidth="20"
        />
        {fullName}
        {newMessages.length > 0 && (
          <Badge pill color="danger" className="badge-up">
            {newMessages.length}
          </Badge>
        )}
      </div>
    );
  };

  const showTabs =
    (!isChatMaximized && data.guests && data.guests?.length > 1) || false;

  return (
    <div
      className={classnames("position-relative", {
        "adjust-chat-position": showTabs,
      })}
    >
      {showTabs && (
        // to allow horizontal scrolling in case there is too many guests
        <PerfectScrollbar
          className="ps-chat-window"
          options={{
            wheelPropagation: false,
            useBothWheelAxes: true,
          }}
        >
          <div className="chat-tabs-wrapper">
            {/* main guest tab always first */}
            {renderChatSwitchButton(data.mainGuest)}
            {/* rest of the guests */}
            {data.guests
              ?.filter(({ id }) => id !== data.mainGuest.id)
              .map(renderChatSwitchButton)}
          </div>
        </PerfectScrollbar>
      )}
      <ErrorModal
        isOpen={errorModalOpen}
        toggle={toggleErrorModal}
        error={error}
      />
      <ChatLogSimple
        htmlId="chat-uno"
        guestId={currentGuestId}
        chatLogEntries={chatLogEntries}
        selectedUser={contact}
        isLoading={guestMessages?.isFetching || false}
        bookingId={data.id}
        updateChatMessages={updateChatMessages}
      />
    </div>
  );
};
