import { apiConfig } from "@configs/apiConfig";
import {
  PropertyTodayStatistics,
  __GlobalFilterServiceOptions,
} from "@src/types";
import axios from "axios";
import { deserialize } from "jsonapi-fractal";
import snakeCase from "lodash/snakeCase";

export const createStatisticsPropertyTodayService =
  <EntryId extends keyof PropertyTodayStatistics>({
    url,
    entryId,
  }: {
    url: string;
    entryId: EntryId;
  }) =>
  async ({
    params: { __globalFilter },
    signal,
  }: __GlobalFilterServiceOptions) => {
    const queryParams: string[] = [];

    (["pmsPropertyId"] as const).forEach((k) => {
      const filterValue = __globalFilter[k];

      if (filterValue) {
        queryParams.push(`filter[${snakeCase(k)}]=${filterValue}`);
      }
    });

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const res = await axios.request({
      method: "GET",
      url: `${apiConfig.baseUrl}${url}${queryString}`,
      headers: { Accept: "application/vnd.api+json" },
      withCredentials: true,
      cancelToken: source.token,
    });

    const { value } = deserialize(res.data, apiConfig.deserializeOptions) as {
      id: EntryId;
      value: number;
    };

    const payload = {
      value,
      key: entryId,
      id: __globalFilter.pmsPropertyId,
    };

    return {
      data: payload,
      params: undefined,
    };
  };
