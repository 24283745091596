import classnames from "classnames";
import { Fragment, useState } from "react";
import { Eye, EyeOff } from "react-feather";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputProps,
  Label,
} from "reactstrap";

export type InputPasswordToggle =
  | ({
      label: string;
      hideIcon?: React.ReactNode;
      showIcon?: React.ReactNode;
      visible?: boolean;
      className?: string;
      htmlFor: string;
      placeholder?: string;
      iconSize?: string;
      inputClassName?: string;
    } & InputProps)
  | ({
      label?: undefined;
      hideIcon?: React.ReactNode;
      showIcon?: React.ReactNode;
      visible?: boolean;
      className?: string;
      htmlFor?: undefined;
      placeholder?: string;
      iconSize?: string;
      inputClassName?: string;
    } & InputProps);

const InputPasswordToggle: React.FC<InputPasswordToggle> = (props) => {
  // ** Props
  const {
    label,
    hideIcon,
    showIcon,
    visible = false,
    className,
    htmlFor,
    placeholder,
    iconSize,
    inputClassName,
    ...rest
  } = props;

  // ** State
  const [inputVisibility, setInputVisibility] = useState(visible);

  // ** Renders Icon Based On Visibility
  const renderIcon = () => {
    const size = iconSize ? iconSize : 14;

    if (inputVisibility === false) {
      return hideIcon ? hideIcon : <Eye size={size} />;
    } else {
      return showIcon ? showIcon : <EyeOff size={size} />;
    }
  };

  return (
    <Fragment>
      {label ? <Label for={htmlFor}>{label}</Label> : null}
      <InputGroup className={classnames(className)}>
        <Input
          type={inputVisibility === false ? "password" : "text"}
          placeholder={placeholder ? placeholder : "············"}
          className={classnames(inputClassName)}
          /*eslint-disable */
          {...(label && htmlFor
            ? {
                id: htmlFor,
              }
            : {})}
          {...rest}
          /*eslint-enable */
        />
        <InputGroupAddon
          addonType="append"
          onClick={() => setInputVisibility(!inputVisibility)}
        >
          <InputGroupText className="cursor-pointer">
            {renderIcon()}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </Fragment>
  );
};

export default InputPasswordToggle;
