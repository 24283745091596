export const reservationListSortConfig = {
  bookedAtAsc: {
    id: "bookedAtAsc",
    label: "Booked earliest first",
    value: { key: "bookedAt", order: "ASC" },
  },
  bookedAtDesc: {
    id: "bookedAtDesc",
    label: "Booked recently first",
    value: { key: "bookedAt", order: "DESC" },
  },
  checkInDateAsc: {
    id: "checkInDateAsc",
    label: "Checking date asc",
    value: { key: "checkInDate", order: "ASC" },
  },
  checkInDateDesc: {
    id: "checkInDateDesc",
    label: "Checking date desc",
    value: { key: "checkInDate", order: "DESC" },
  },
} as const;
