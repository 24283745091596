import { createSlice } from "@reduxjs/toolkit";

import { postOneReservationAttachmentActions } from "../../Reservation";
import { attachmentPendingEntityAdapter } from ".";

export const attachmentPendingEntitySlice = createSlice({
  name: `pendingEntities/attachment`,
  initialState: attachmentPendingEntityAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postOneReservationAttachmentActions.pending, (state, action) =>
        attachmentPendingEntityAdapter.setOne(state, {
          id: action.meta.requestId,
          filename: action.meta.arg.file.name,
        })
      )
      .addCase(postOneReservationAttachmentActions.fulfilled, (state, action) =>
        attachmentPendingEntityAdapter.removeOne(state, action.meta.requestId)
      );
  },
});
