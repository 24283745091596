import { EntityId, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getReservationAttachmentsService } from "@src/services/Reservation";
import {
  AttachmentEntity,
  JsonapiAsyncThunkConfig,
  JsonapiResponseFetchMany,
} from "@src/types";

const fetchOneAttachments = createAsyncThunk<
  JsonapiResponseFetchMany<AttachmentEntity>,
  EntityId,
  JsonapiAsyncThunkConfig
>(
  `reservation/one/attachments/fetch`,
  async (arg, { rejectWithValue, signal }) => {
    try {
      return await getReservationAttachmentsService({
        arg,
        signal,
      });
    } catch (err) {
      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  }
);

const resetOneAttachmentsStatus = createAction<EntityId>(
  "reservation/one/attachments/resetStatus"
);

export const getOneReservationAttachmentsActions = {
  fetch: fetchOneAttachments,
  resetStatus: resetOneAttachmentsStatus,
};
