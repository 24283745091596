import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import { ReservationListFilter } from "@src/types";
import axios from "axios";
import { toast } from "react-toastify";

export type PostMessageToManyArgs = {
  body: string;
  filter: Partial<ReservationListFilter>;
  __globalFilter: {
    pmsPropertyId?: EntityId | null;
  };
};

export const postMessageToManyService = async ({
  signal,
  arg: { body, filter, __globalFilter },
}: {
  signal: AbortSignal;
  arg: PostMessageToManyArgs;
}) => {
  const source = axios.CancelToken.source();

  const queryParams: string[] = [];

  (["bookedAtInPropertyTz", "search", "isVip"] as const).forEach((k) => {
    const filterValue = filter[k];

    if (typeof filterValue === "boolean") {
      queryParams.push(`filter[${k}]=${filterValue}`);
    } else if (filterValue) {
      queryParams.push(`filter[${k}]=${encodeURIComponent(filterValue)}`);
    }
  });

  (["pmsStatus", "checkInDateRange", "checkOutDateRange"] as const).forEach(
    (k) => {
      const filterValue = filter[k];

      if (filterValue && filterValue.length > 0) {
        const qsFilterVal = ["checkInDateRange", "checkOutDateRange"].includes(
          k
        )
          ? `[${filterValue.map((v) => (v === null ? "nil" : v)).join(",")}]`
          : filterValue.join(",");

        queryParams.push(`filter[${k}]=${qsFilterVal}`);
      }
    }
  );

  (["pmsPropertyId"] as const).forEach((k) => {
    const filterValue = __globalFilter[k];

    if (filterValue) {
      queryParams.push(`filter[${k}]=${filterValue}`);
    }
  });

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  await axios.request({
    method: "POST",
    url: `${apiConfig.baseUrl}/messaging/reservations_broadcast${queryString}`,
    headers: { Accept: "application/vnd.api+json" },
    withCredentials: true,
    cancelToken: source.token,
    data: {
      message: {
        body,
      },
    },
  });

  toast.success("Message sent!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return { response: "success" };
};
