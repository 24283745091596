import "./ReservationCardHeader.scss";

import { reservationStatusesConfig } from "@configs/Reservation";
import { DateRange, GuestDetailLink } from "@src/components";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";
import { FileText, Key, Star, User } from "react-feather";
import { Button, CardBody, UncontrolledTooltip } from "reactstrap";

import { ReactComponent as BedIconSVG } from "./bed-svgrepo-com.svg";
import { ReservationBadge, ReservationDetailsLink } from "..";

export type ReservationCardHeaderProps = {
  data: ReservationListColumnNodeProps;

  onNotesActionClick?: () => void;
  onKeycodeActionClick: () => void;
};

export const ReservationCardHeader: React.FC<ReservationCardHeaderProps> = ({
  data,

  onNotesActionClick,
  onKeycodeActionClick,
}) => {
  const {
    id,
    adults,
    checkInDate,
    checkOutDate,
    children,
    estimatedArrivalTime,
    estimatedDepartureTime,
    isVip,
    mainGuestId,
    mainGuestName,
    pmsStatus,
    roomName,
    roomType,
    notes,
  } = data;

  const mainGuestNameParts = mainGuestName.split(" ");

  const statusText =
    (pmsStatus && reservationStatusesConfig[pmsStatus]?.alias) || "unknown";

  const vipIconWrapperTooltipped = isVip && (
    <>
      <UncontrolledTooltip target={`isVip-${id}`}>VIP</UncontrolledTooltip>
      <div className="card-header-icon-wrapper" id={`isVip-${id}`}>
        <Star className="filled-orange" />
      </div>
    </>
  );

  const adultsIconWrapperTooltipped = adults > 0 && (
    <>
      <UncontrolledTooltip target={`adults-${id}`}>Adults</UncontrolledTooltip>
      <div className="card-header-icon-wrapper" id={`adults-${id}`}>
        <span>{adults}</span>
        <User className="adult-icon" />
      </div>
    </>
  );
  const childrenIconWrapperTooltipped = children > 0 && (
    <>
      <UncontrolledTooltip target={`children-${id}`}>
        Children
      </UncontrolledTooltip>
      <div className="guests-children" id={`children-${id}`}>
        <span>{children}</span>
        <User />
      </div>
    </>
  );

  const roomIconWrapper = roomName && (
    <>
      <UncontrolledTooltip target={`room-${id}`}>
        {roomType}
      </UncontrolledTooltip>
      <div className="card-header-icon-wrapper" id={`room-${id}`}>
        <BedIconSVG className="bed-icon" />
        <span>{roomName}</span>
      </div>
    </>
  );

  const isNotesButtonDisabled = !(notes && notes.length > 0);

  const notesTooltip = isNotesButtonDisabled && (
    <UncontrolledTooltip
      target={`reservation-card-header-actions-button-container-${id}`}
    >
      No notes yet.
    </UncontrolledTooltip>
  );

  const notesButtonFragment = onNotesActionClick && (
    <>
      {notesTooltip}
      <div
        className="entity-card-header-actions-button-container"
        id={`reservation-card-header-actions-button-container-${id}`}
      >
        <Button
          disabled={isNotesButtonDisabled}
          color="action"
          outline
          onClick={onNotesActionClick}
          title="Notes"
        >
          <FileText size={18} />
        </Button>
      </div>
    </>
  );

  const guestDetailLink = (
    <GuestDetailLink id={mainGuestId} className="interactive">
      {mainGuestNameParts.length > 1 && (
        <span>
          {`${mainGuestNameParts
            .slice(0, mainGuestNameParts.length - 1)
            .join(" ")} `}
        </span>
      )}
      <span className="font-weight-bolder">
        {mainGuestNameParts[mainGuestNameParts.length - 1]}
      </span>
    </GuestDetailLink>
  );

  const checkInDateTime = `${checkInDate}T${estimatedArrivalTime}:00`;
  const checkOutDateTime = `${checkOutDate}T${estimatedDepartureTime}:00`;

  return (
    <CardBody className="reservation-card-header entity-card-header">
      <div className="entity-card-header-toggler-wrapper">
        <div className="entity-card-header-content">
          <div className="entity-card-header-info">
            <div className="d-flex flex-row">
              <UncontrolledTooltip target={`reservation-number-${id}`}>
                {statusText}
              </UncontrolledTooltip>
              <ReservationDetailsLink
                htmlId={`reservation-number-${id}`}
                className="strong-card-header reservation-number d-flex"
                id={id}
              >
                <ReservationBadge data={data} />
              </ReservationDetailsLink>
              <div className="reservation-card-header-guests-numbers">
                {vipIconWrapperTooltipped}
                {adultsIconWrapperTooltipped}
                {childrenIconWrapperTooltipped}
                {roomIconWrapper}
              </div>
              <div className="entity-card-header-actions">
                <div className="entity-card-header-actions-button-container">
                  <Button
                    color="action"
                    outline
                    onClick={onKeycodeActionClick}
                    title="Keycode"
                  >
                    <Key size={18} />
                  </Button>
                </div>

                {notesButtonFragment}
              </div>
            </div>
            <div className="reservation-card-header-main-guest-wrapper">
              {guestDetailLink}
            </div>
            <DateRange
              checkInDate={checkInDateTime}
              checkOutDate={checkOutDateTime}
              estimatedArrivalTime={estimatedArrivalTime}
              estimatedDepartureTime={estimatedDepartureTime}
            />
          </div>
        </div>
      </div>
    </CardBody>
  );
};
