import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import {
  AbstractEntity,
  JsonapiPage,
  JsonapiResponseFetchManyMeta,
  JsonapiSort,
  Override,
  ReservationEntity,
  ReservationListFilter,
  ReservationListSortKey,
  ReservationNote,
} from "@src/types";
import { reservationNoteAdapter } from "@src/utility";
import axios from "axios";
import { deserialize } from "jsonapi-fractal";

export const getManyReservationsService = async ({
  params: {
    __globalFilter,
    filter,
    sort,
    page,
    /**
     * mainGuest field is included by default. To omit this field you have to
     * specify this value directly as a false.
     */
    include: { mainGuest = true } = { mainGuest: true },
    isSearch = false,
  },
  signal,
}: {
  params: {
    __globalFilter: {
      pmsPropertyId?: EntityId | null;
    };
    filter: Partial<ReservationListFilter>;
    include?: { mainGuest?: boolean };
    isSearch?: boolean; // used in global search field in navbar
    page: JsonapiPage;
    sort: JsonapiSort<ReservationListSortKey>;
  };
  signal: AbortSignal;
}) => {
  const queryParams: string[] = [];

  if (mainGuest) {
    queryParams.push("include=mainGuest");
  }

  (["bookedAtInPropertyTz", "search", "isVip"] as const).forEach((k) => {
    const filterValue = filter[k];

    if (typeof filterValue === "boolean") {
      queryParams.push(`filter[${k}]=${filterValue}`);
    } else if (filterValue) {
      queryParams.push(`filter[${k}]=${encodeURIComponent(filterValue)}`);
    }
  });

  (["pmsStatus", "checkInDateRange", "checkOutDateRange"] as const).forEach(
    (k) => {
      const filterValue = filter[k];

      if (filterValue && filterValue.length > 0) {
        const qsFilterVal = ["checkInDateRange", "checkOutDateRange"].includes(
          k
        )
          ? `[${filterValue.map((v) => (v === null ? "nil" : v)).join(",")}]`
          : filterValue.join(",");

        queryParams.push(`filter[${k}]=${qsFilterVal}`);
      }
    }
  );

  (["pmsPropertyId"] as const).forEach((k) => {
    const filterValue = __globalFilter[k];

    if (filterValue) {
      queryParams.push(`filter[${k}]=${filterValue}`);
    }
  });

  (["number", "size"] as const).forEach((k) => {
    queryParams.push(`page[${k}]=${page[k]}`);
  });

  if (sort.key) {
    queryParams.push(`sort=${sort.order === "DESC" ? "-" : ""}${sort.key}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  const requestUrl = isSearch
    ? `${apiConfig.baseUrl}/reservations/search${queryString}`
    : `${apiConfig.baseUrl}/reservations${queryString}`;

  const res = await axios.request({
    method: "GET",
    url: requestUrl,
    headers: { Accept: "application/vnd.api+json" },
    withCredentials: true,
    cancelToken: source.token,
  });

  const deserializedData = deserialize(
    res.data,
    apiConfig.deserializeOptions
  ) as Override<
    ReservationEntity,
    {
      guests?: undefined;
      notes: ReservationNote[];
      reservations: AbstractEntity;
    }
  >[];

  const data = deserializedData.map(({ notes, ...entity }) => ({
    ...entity,
    notes: reservationNoteAdapter(notes),
  })) as Override<ReservationEntity, { guests?: undefined }>[];

  const meta = res.data.meta as JsonapiResponseFetchManyMeta;

  return { data, meta };
};
