import classnames from "classnames";
import { Card, CardBody, CardText } from "reactstrap";

export type EntityListNoDataCardProps = {
  entitiesPlural: string;
  withFilter: boolean;
};

export const EntityListNoDataCard: React.FC<EntityListNoDataCardProps> = ({
  entitiesPlural,
  withFilter,
}) => (
  <Card>
    <CardBody className="text-center py-3">
      <CardText>
        {/** Trick for joining and trimming strings */}
        {classnames(`No ${entitiesPlural}`, {
          "matching filter criteria": withFilter,
        })}
      </CardText>
    </CardBody>
  </Card>
);
