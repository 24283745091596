import { EntityId, createAction } from "@reduxjs/toolkit";
import { reservationServices } from "@src/services/Reservation";

import { jsonapiAsyncThunkFactory } from "../jsonapiAsyncThunkFactory";

const fetchOne = jsonapiAsyncThunkFactory({
  name: "reservation/one/fetch",
  serviceFn: reservationServices.fetchOne,
});

const resetOneStatus = createAction<EntityId>("reservation/one/resetStatus");

export const getOneReservationActions = {
  fetch: fetchOne,
  resetStatus: resetOneStatus,
};
