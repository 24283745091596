import "./DateRangePlaceholder.scss";

import { usePlaceholderColor } from "@hooks/usePlaceholderColor";
import { PlaceholderTextRow } from "@src/components";

export const DateRangePlaceholder: React.FC = () => {
  const placeholderColor = usePlaceholderColor();

  const dateRangeEndStyle = { borderColor: placeholderColor };

  return (
    <div className="date-range-placeholder date-range">
      <div className="date-range-wrapper date-start">
        <div className="date-range-top">
          <PlaceholderTextRow />
        </div>
        <div className="date-range-middle font-weight-bolder">
          <PlaceholderTextRow />
        </div>
        <div className="date-range-bottom">
          <PlaceholderTextRow />
        </div>
      </div>

      <div className="date-center">
        <div className="range range-start" style={dateRangeEndStyle} />
        <div className="range-middle">
          <div className="range-nights">
            <PlaceholderTextRow />
          </div>
          <div
            className="range-line"
            style={{ backgroundColor: placeholderColor }}
          />
        </div>
        <div className="range range-end" style={dateRangeEndStyle} />
      </div>

      <div className="date-range-wrapper date-end">
        <div className="date-range-top">
          <PlaceholderTextRow />
        </div>
        <div className="date-range-middle font-weight-bolder">
          <PlaceholderTextRow />
        </div>
        <div className="date-range-bottom">
          <PlaceholderTextRow />
        </div>
      </div>
    </div>
  );
};
