import { Card, CardBody, CardText, CardTitle } from "reactstrap";

export type ErrorNotFoundCardProps = {
  color?: "danger" | "info" | "warning";
  message?: string;
};

export const ErrorNotFoundCard: React.FC<ErrorNotFoundCardProps> = ({
  color = "warning",
  message,
}) => (
  <Card>
    <CardBody>
      <CardTitle className={`text-${color}`}>
        Oops! There is no content you are trying to reach.
      </CardTitle>
      {message && <CardText>{message}</CardText>}
    </CardBody>
  </Card>
);
