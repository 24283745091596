import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import { AttachmentEntity, JsonapiResponseFetchManyMeta } from "@src/types";
import axios from "axios";
import { deserialize } from "jsonapi-fractal";

export const getReservationAttachmentsService = async ({
  signal,
  arg,
}: {
  signal: AbortSignal;
  arg: EntityId;
}) => {
  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  const res = await axios.request({
    method: "GET",
    url: `${apiConfig.baseUrl}/reservations/${arg}/attachments`,
    headers: { Accept: "application/vnd.api+json" },
    withCredentials: true,
    cancelToken: source.token,
  });

  const data = deserialize(
    res.data,
    apiConfig.deserializeOptions
  ) as AttachmentEntity[];

  const meta = res.data.meta as JsonapiResponseFetchManyMeta;

  return { data, meta };
};
