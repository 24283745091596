import { useSortable } from "@dnd-kit/sortable";
import { useMountStatus } from "@hooks/useMountStatus";
import { ReservationReportColumnName } from "@src/types";

import { ReservationReportColumnItem } from "./ReservationReportColumnItem";

export type ReservationReportColumnItemSortableProps = {
  id: string;
  disabled?: boolean;
  children: string;
  index: number;
  selected?: boolean;
  onClick?: (id: ReservationReportColumnName) => void;
};

export const ReservationReportColumnItemSortable: React.FC<ReservationReportColumnItemSortableProps> =
  ({ disabled, id, index, children, selected, onClick }) => {
    const {
      setNodeRef,
      listeners,
      isDragging,
      isSorting,
      transform,
      transition,
    } = useSortable({
      id,
    });

    const mounted = useMountStatus();
    const mountedWhileDragging = isDragging && !mounted;

    const handleClick =
      onClick &&
      (() => {
        onClick(id as ReservationReportColumnName);
      });

    return (
      <ReservationReportColumnItem
        ref={disabled ? undefined : setNodeRef}
        dragging={isDragging}
        sorting={isSorting}
        transition={transition}
        transform={transform}
        fadeIn={mountedWhileDragging}
        index={index}
        listeners={listeners}
        selected={selected}
        onClick={handleClick}
      >
        {children}
      </ReservationReportColumnItem>
    );
  };
