import { createSlice } from "@reduxjs/toolkit";
import {
  getOneReservationAttachmentsActions,
  postOneReservationAttachmentActions,
} from "@src/redux/Reservation";

import { attachmentEntityAdapter } from "./attachmentEntityAdapter";

export const attachmentEntitySlice = createSlice({
  name: `entities/attachment`,
  initialState: attachmentEntityAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getOneReservationAttachmentsActions.fetch.fulfilled,
        (state, { payload }) =>
          attachmentEntityAdapter.setMany(state, payload.data)
      )
      .addCase(
        postOneReservationAttachmentActions.fulfilled,
        (state, { payload }) => attachmentEntityAdapter.setOne(state, payload)
      );
  },
});
