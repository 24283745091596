import { Draft, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { PropertyStatistics, StatisticsServiceResult } from "@src/types";

import { entityAdapters } from "../entityAdapters";

export const propertyYearStatisticsReducer = (
  state: Draft<EntityState<PropertyStatistics>>,
  {
    payload: {
      data: { id, key, value },
      params: { year },
    },
  }: PayloadAction<StatisticsServiceResult<{ year: number }>>
) => {
  const entity = state.entities[id] || { id, today: {}, year: {} };

  return entityAdapters["appStatisticsProperty"].setOne(state, {
    ...entity,
    year: {
      [year]: { ...entity.year[year], [key]: value },
    },
  });
};
