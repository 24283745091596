import "react-datepicker/dist/react-datepicker.css";

import "./InputDate.scss";

import { apiFormatDate } from "@src/utility";
import DatePicker from "react-datepicker";
import { X } from "react-feather";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

export type InputDateProps = {
  className?: string;
  value?: string;
  onChange: (value: string | null) => void;
};

export const InputDate: React.FC<InputDateProps> = ({
  value = null,
  onChange: onChangeValue,
}) => {
  const date = value ? new Date(value) : null;

  //@ts-ignore
  const CustomInput: React.FC = ({ onClick, onChange, value }) => (
    <InputGroup className="input-group-merge">
      <Input
        type="text"
        value={value}
        onClick={onClick}
        onChange={onChange}
        placeholder="Click to select date"
      />
      {value && (
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <X
              size="16"
              className="cursor-pointer"
              onClick={() => {
                onChangeValue(null);
              }}
            />
          </InputGroupText>
        </InputGroupAddon>
      )}
    </InputGroup>
  );

  return (
    <div className="d-flex ">
      <DatePicker
        selected={date}
        onChange={(date) => {
          // @ts-ignore
          onChangeValue(date ? apiFormatDate(date) : null);
        }}
        customInput={<CustomInput />}
      />
    </div>
  );
};
