import { createSlice } from "@reduxjs/toolkit";

import { createJsonapiAsyncThunkRejectedError } from "../createJsonapiAsyncThunkRejectedError";
import { postOneReservationAttachmentActions } from "../Reservation";
import { requestStatusEntityAdapter } from ".";

export const requestStatusSlice = createSlice({
  name: `requestStatus`,
  initialState: requestStatusEntityAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postOneReservationAttachmentActions.pending, (state, action) =>
        requestStatusEntityAdapter.setOne(state, {
          id: action.meta.requestId,
          isLoading: true,
          error: null,
        })
      )
      .addCase(
        postOneReservationAttachmentActions.rejected,
        (state, { payload, meta: { requestId: id } }) =>
          requestStatusEntityAdapter.setOne(state, {
            id,
            isLoading: false,
            error: createJsonapiAsyncThunkRejectedError(payload, {
              code: "reservation/one/attachments/post/rejected",
              message: "Post reservations attachment rejected.",
            }),
          })
      )
      .addCase(postOneReservationAttachmentActions.fulfilled, (state, action) =>
        requestStatusEntityAdapter.removeOne(state, action.meta.requestId)
      );
  },
});
