import { guestListFilterKeys, guestListSortKeys } from "@src/constants/guest";
import { getManyGuestsActions } from "@src/redux/Guest";
import { guestsByIdsSelector } from "@src/redux/selectors";

import { useEntityList } from "./useEntityList";

export const useGuestList = () =>
  useEntityList({
    fetch: getManyGuestsActions.fetch,
    setPageNumber: getManyGuestsActions.setPageNumber,
    setView: getManyGuestsActions.setView,
    setSort: getManyGuestsActions.setSort,

    __globalFiltersActive: { pmsPropertyId: true },
    filterKeys: guestListFilterKeys,
    sortKeys: guestListSortKeys,
    entitiesSelector: guestsByIdsSelector,
    statusSelector: (state) => state.app.guest.allList,
  });
