import "./ReservationList.scss";

import { reservationListAllTableConfig } from "@configs/Reservation/reservationListAllTableConfig";
import { reservationListFilterPredefinedConfig } from "@configs/Reservation/reservationListFilterPredefinedConfig";
import { reservationListSortConfig } from "@configs/Reservation/reservationListSortConfig";
import { useAppSelector } from "@hooks/useAppSelector";
import { useToggle } from "@hooks/useToggle";
import {
  BroadcastSmsModal,
  EntityListBody,
  EntityListFilterDropdown,
  EntityListNoDataCard,
  EntityListPagination,
  EntityListPaginationStatus,
  EntityListSortDropdown,
  EntityListViewSwitch,
  ReservationCard,
  ReservationCardPlaceholder,
  ReservationListFilterBar,
  ReservationListReportPdfModal,
} from "@src/components";
import {
  reservationDummyEntry,
  reservationListFilterKeys,
  reservationListFilterPredefinedConfigKeys,
} from "@src/constants";
import {
  JsonapiPage,
  JsonapiResponseFetchManyMeta,
  JsonapiSort,
  ListFilter,
  ListView,
  ReservationListFilter,
  ReservationListFilterPredefinedConfigKey,
  ReservationListSortKey,
} from "@src/types";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";
import { useState } from "react";
import { Collapse } from "reactstrap";

export type ReservationListProps = {
  data: ReservationListColumnNodeProps[] | null | undefined;

  filter?: Partial<ListFilter<keyof ReservationListFilter>>;
  sort?: JsonapiSort<ReservationListSortKey>;
  timeZone?: string;
  view?: ListView;
  page?: JsonapiPage;
  meta?: Partial<JsonapiResponseFetchManyMeta>;
  isLoading: boolean;

  changePage?: (pageNumber: number) => void;
  changeView: (view: ListView) => void;
  resetFilter?: () => void;
  setFilter?: (filter: Partial<ReservationListFilter>) => void;
  setSort?: (sort: JsonapiSort<ReservationListSortKey>) => void;

  downloadCsv?: () => void;
  downloadPdf?: () => void;
  broadcastSms?: () => void;
};

export const ReservationList: React.FC<ReservationListProps> = ({
  data,

  filter,
  sort,
  timeZone,
  view = "cards",
  page,
  meta,
  isLoading,

  changePage,
  changeView,
  resetFilter,
  setFilter,
  setSort,

  downloadCsv,
  downloadPdf,
  broadcastSms,
}) => {
  /**
   * @fixme
   * If there is no global property set, there would be no timeZone and
   * there should be no possibility to display any data
   */
  const filterPredefinedConfig = reservationListFilterPredefinedConfig(
    timeZone || "UTC"
  );

  const isFilterOptionActive = (
    filterConfigKey: ReservationListFilterPredefinedConfigKey | undefined
  ) => {
    for (
      let filterConfigIndex = 0;
      filterConfigIndex < reservationListFilterKeys.length;
      filterConfigIndex++
    ) {
      const filterKey = reservationListFilterKeys[filterConfigIndex];

      const configEntry = filterConfigKey
        ? filterPredefinedConfig[filterConfigKey].value[filterKey]
        : undefined;

      const filterEntry = filter?.[filterKey];

      if (Array.isArray(configEntry)) {
        if (!Array.isArray(filterEntry)) {
          return false;
        }

        if (configEntry.length !== filterEntry.length) {
          return false;
        }

        for (
          let entryIndex = 0;
          entryIndex < configEntry.length;
          entryIndex++
        ) {
          if (configEntry[entryIndex] !== filterEntry[entryIndex]) {
            return false;
          }
        }
      } else {
        if (configEntry !== filterEntry) {
          return false;
        }
      }
    }

    return true;
  };

  const getSelectedFilterOption = () => {
    for (let i = 0; i < reservationListFilterPredefinedConfigKeys.length; i++) {
      const filterConfigKey = reservationListFilterPredefinedConfigKeys[i];

      if (isFilterOptionActive(filterConfigKey)) {
        return {
          id: filterPredefinedConfig[filterConfigKey].id,
          label: filterPredefinedConfig[filterConfigKey].label,
        } as const;
      }
    }

    if (isFilterOptionActive(undefined)) {
      return { id: "all", label: "All reservations" } as const;
    }

    return { id: null, label: "Custom" } as const;
  };

  const { id: selectedFilterOptionId, label: selectedFilterOptionLabel } =
    getSelectedFilterOption();

  const [isSmsModalOpen, toggleSmsModal] = useToggle();
  const [isPdfModalOpen, togglePdfModal] = useToggle();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCsvModalOpen, toggleCsvModal] = useToggle();

  const [isCustomFilterBarOpen, setIsFilterBarOpen] = useState(
    selectedFilterOptionLabel === "Custom"
  );
  const { canBroadcastMessages } = useAppSelector(
    (state) => state.app.auth.data
  );

  const handleResetFilterClick = () => {
    setIsFilterBarOpen(false);

    resetFilter?.();
  };

  const handleFilterClick = (
    filterConfigKey: ReservationListFilterPredefinedConfigKey
  ) => {
    setIsFilterBarOpen(false);

    setFilter?.(filterPredefinedConfig[filterConfigKey].value);
  };

  const handleSetFilterCustomClick = () => {
    setIsFilterBarOpen(true);
  };

  const noDataNode = (
    <EntityListNoDataCard
      entitiesPlural="reservations"
      withFilter={selectedFilterOptionLabel !== "All reservations"}
    />
  );

  return (
    <div className="entity-list reservation-list">
      {filter && setFilter && (
        <Collapse isOpen={isCustomFilterBarOpen}>
          <ReservationListFilterBar
            filter={
              /** @fixme Create EntityListFilterBar  */
              filter as ReservationListFilter
            }
          />
        </Collapse>
      )}
      <div className="content-header">
        <div className="view-switch-container">
          <EntityListViewSwitch
            view={view}
            onChangeView={changeView}
            onCsvClick={downloadCsv ? toggleCsvModal : undefined}
            onPdfClick={downloadPdf ? togglePdfModal : undefined}
            onSmsClick={
              canBroadcastMessages && broadcastSms ? toggleSmsModal : undefined
            }
          />
        </div>

        {sort && setSort && (
          <div className="sort-select-container">
            <EntityListSortDropdown
              sort={sort}
              setSort={setSort}
              sortConfig={reservationListSortConfig}
            />
          </div>
        )}

        {filter && setFilter && resetFilter && (
          <div className="filter-dropdown-container">
            <EntityListFilterDropdown
              value={selectedFilterOptionId}
              defaultOptionLabel="All reservations"
              toggleText={selectedFilterOptionLabel}
              predefinedFilters={filterPredefinedConfig}
              onClick={handleFilterClick}
              onCustomClick={handleSetFilterCustomClick}
              onResetClick={handleResetFilterClick}
            />
          </div>
        )}
      </div>
      {page && (
        <div className="d-flex justify-content-end pagination-status-container">
          <EntityListPaginationStatus
            page={page}
            meta={meta}
            entitiesPlural="reservations"
          />
        </div>
      )}
      <EntityListBody
        data={data}
        sort={sort}
        onSort={setSort}
        placeholderDummyEntry={reservationDummyEntry}
        tableConfig={reservationListAllTableConfig}
        isLoading={isLoading}
        cardComponent={ReservationCard}
        cardPlaceholderComponent={ReservationCardPlaceholder}
        view={view}
        noDataNode={noDataNode}
      />
      {changePage && page && !!meta?.totalPages && (
        <EntityListPagination
          totalPages={meta.totalPages}
          pageNumber={page.number}
          onPageChange={changePage}
        />
      )}
      {downloadPdf && (
        <ReservationListReportPdfModal
          documentType="pdf"
          isOpen={isPdfModalOpen}
          toggle={togglePdfModal}
          unmountOnClose={false}
          selectedFilterOptionId={
            selectedFilterOptionId && selectedFilterOptionId !== "all"
              ? selectedFilterOptionId
              : undefined
          }
          filterPredefinedConfig={filterPredefinedConfig}
        />
      )}
      {downloadCsv && (
        <ReservationListReportPdfModal
          documentType="csv"
          isOpen={isCsvModalOpen}
          toggle={toggleCsvModal}
          unmountOnClose={false}
          selectedFilterOptionId={
            selectedFilterOptionId && selectedFilterOptionId !== "all"
              ? selectedFilterOptionId
              : undefined
          }
          filterPredefinedConfig={filterPredefinedConfig}
        />
      )}
      {broadcastSms && canBroadcastMessages && (
        <BroadcastSmsModal
          isOpen={isSmsModalOpen}
          toggle={toggleSmsModal}
          unmountOnClose={false}
          meta={meta}
          data={data}
        />
      )}
    </div>
  );
};
