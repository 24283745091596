import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import axios from "axios";

export const patchManyNotificationAsReadService = async ({
  signal,
  ids,
  read = true,
}: {
  signal: AbortSignal;
  ids: EntityId[];
  read: boolean;
}) => {
  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    if (!!id) {
      await axios.request({
        method: "PATCH",
        url: `${apiConfig.baseUrl}/notifications/${id}`,
        headers: { Accept: "application/vnd.api+json" },
        withCredentials: true,
        cancelToken: source.token,
        data: {
          read,
        },
      });
    }
  }

  return { data: { ids } };
};
