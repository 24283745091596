import { usePlaceholderColor } from "@hooks/usePlaceholderColor";
import { RectShape } from "react-placeholder/lib/placeholders";

export const PlaceholderRectShape: React.FC<{
  className?: string;
  style?: React.CSSProperties;
}> = ({ className, style }) => {
  const placeholderColor = usePlaceholderColor();

  return (
    <RectShape
      color={placeholderColor}
      className={className}
      style={{
        borderRadius: "3px",
        ...style,
      }}
    />
  );
};
