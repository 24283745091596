const usTimeIntl = new Intl.DateTimeFormat("en-us", {
  hour: "numeric",
  minute: "numeric",
  timeZone: "UTC",
});

export const formatTimeToUsTime = (
  input: string | null,
  { prepend = undefined }: { prepend?: string } = {}
) => {
  if (input) {
    /** fixes 5:00 into 05:00 */
    const prefixedInput = input.padStart(5, "0");

    const isValidTime = !!prefixedInput.match(
      /^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/
    );

    if (isValidTime) {
      const formattedDate = new Date(`1970-01-01T${prefixedInput}Z`);

      const formattedTimeString =
        (prepend || "") + usTimeIntl.format(formattedDate);

      return formattedTimeString;
    }
  }

  return "";
};
