import { EntityId } from "@reduxjs/toolkit";

export const deleteIdFromArray = <T extends EntityId = EntityId>(
  id: T,
  array: T[]
): T[] => {
  const index = array.indexOf(id);

  return [...array.slice(0, index), ...array.slice(index + 1)];
};
