import { Draft, SerializedError } from "@reduxjs/toolkit";
import { statusStateInit } from "@src/constants/statusStateInit";
import {
  RestapiErrorResponseData,
  StatusState,
  ThunkServiceErrorExtended,
} from "@src/types";

import { createAsyncThunkRejectedError } from "./createAsyncThunkRejectedError";

export const asyncThunkStatusReducers = {
  pending: (state: Draft<StatusState>) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (
    state: Draft<StatusState>,
    {
      payload,
      error,
    }: {
      error: SerializedError;
      payload: ThunkServiceErrorExtended<RestapiErrorResponseData> | undefined;
    }
  ) => {
    state.isLoading = false;
    state.error = createAsyncThunkRejectedError(payload, error);
  },
  fulfilled: (state: Draft<StatusState>) => {
    state.isLoading = false;
    state.error = null;
  },
  reset: (state: Draft<StatusState>) => {
    state.isLoading = statusStateInit.isLoading;
    state.error = statusStateInit.error;
  },
};
