import "./PropertyStatistics.scss";

import { RevenueChart, StatisticsCard } from "@components/.";
import { useAppDispatch } from "@hooks/.";
import { useAppSelector } from "@hooks/useAppSelector";
import { statisticActions } from "@src/redux/actions/statisticsActions";
import { PropertyEntity } from "@src/types";
import {
  formatNumberToStringWithCommasAndCurrency,
  getCurrentYear,
  numberToString,
} from "@src/utility";
import { useEffect } from "react";
import { Book, DollarSign } from "react-feather";
import { Col, Row } from "reactstrap";

export type PropertyStatisticsYearProps = {
  property: PropertyEntity;
};

export const PropertyStatisticsYear: React.FC<PropertyStatisticsYearProps> = ({
  property: { id: pmsPropertyId, property_time_zone: propertyTimeZone },
}) => {
  const dispatch = useAppDispatch();

  const propertyStatistics = useAppSelector(
    (state) => state.app.statistics.property?.entities[pmsPropertyId]
  );

  const propertyLocalYear = getCurrentYear(propertyTimeZone);

  useEffect(() => {
    const promises = [
      dispatch(
        statisticActions.fetchPropertyYearDaysBookedStatistics(
          propertyLocalYear
        )
      ),
      dispatch(
        statisticActions.fetchPropertyYearRentRevenueStatistics(
          propertyLocalYear
        )
      ),
    ];

    return () => promises.forEach((promise) => promise.abort());
  }, [pmsPropertyId, propertyLocalYear]);

  const daysBooked =
    numberToString(
      propertyStatistics?.year[propertyLocalYear]?.propertyDaysBooked
    ) || "n/d";

  /** @fixme Monetary values shouldn't be delivered as floats */
  const rentRevenue =
    propertyStatistics?.year[propertyLocalYear]?.propertyRentRevenue;

  const formattedRentRevenue =
    rentRevenue !== undefined
      ? formatNumberToStringWithCommasAndCurrency(rentRevenue)
      : "n/d";

  return (
    <>
      <Row className="mb-2">
        <Col sm="12" className="ml-50 property-statistics-title">
          <p
            className="font-medium-5 mt-1 extension-title"
            data-tour="extension-title"
          >
            Year {propertyLocalYear}
          </p>
        </Col>
      </Row>
      <Row className="match-height">
        <Col lg="6">
          <StatisticsCard
            hideChart
            iconRight
            iconBg="success"
            icon={<DollarSign className="success" size={22} />}
            stat={formattedRentRevenue}
            statTitle="Rent Revenue"
            statDataCy="property-statistics-year-rent-revenue"
          />
        </Col>
        <Col lg="6">
          <StatisticsCard
            hideChart
            iconRight
            iconBg="warning"
            icon={<Book className="warning" size={22} />}
            stat={daysBooked}
            statTitle="Days Booked"
            statDataCy="property-statistics-year-days-booked"
          />
        </Col>
      </Row>
      <Row className="match-height">
        <Col>
          <RevenueChart
            pmsPropertyId={pmsPropertyId}
            year={propertyLocalYear}
          />
        </Col>
      </Row>
    </>
  );
};
