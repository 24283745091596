export const reservationReportColumnsConfig = {
  number: {
    id: "number",
    label: "Reservation number",
  },
  guestName: {
    id: "guestName",
    label: "Guest name",
  },
  repeatGuest: {
    id: "repeatGuest",
    label: "Repeat guest",
  },
  arrivalTime: {
    id: "arrivalTime",
    label: "Arrival time",
  },
  checkInDate: {
    id: "checkInDate",
    label: "Check in Date",
  },
  checkOutDate: {
    id: "checkOutDate",
    label: "Check Out Date",
  },
  nightsCount: {
    id: "nightsCount",
    label: "Nights count",
  },
  guestsCount: {
    id: "guestsCount",
    label: "Guests count",
  },
  roomTypes: {
    id: "roomTypes",
    label: "Room types",
  },
  rooms: {
    id: "rooms",
    label: "Rooms",
  },
  checkedIn: {
    id: "checkedIn",
    label: "Checked in",
  },
  pmsStatus: {
    id: "pmsStatus",
    label: "PMS status",
  },
  notes: {
    id: "notes",
    label: "Notes",
  },
  priceTotal: { id: "priceTotal", label: "Price Total" },
  paidTotal: { id: "paidTotal", label: "Paid Total" },
  remainingBalance: {
    id: "remainingBalance",
    label: "Remaining balance",
  },
  source: { id: "source", label: "Source" },
  cancellationDate: { id: "cancellationDate", label: "Cancellation date" },
};
