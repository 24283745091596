export const badgesColors = [
  /** 0 secondary */
  "#888888",
  /** 1 info */
  "#00cfe8",
  /** 2 success */
  "#28c76f",
  /** 3 danger */
  "#ff0000",
  /** 4 danger */
  "#ff0000",
  /** 5 warning */
  "#ff9f43",
  /** 6 info ? */
  "#00cfe8",
] as const;
