import {
  messageListFilterKeys,
  messageListSortKeys,
} from "@src/constants/message";
import { messagesByIdsSelector } from "@src/redux/selectors";

import { useEntityList } from "./useEntityList";
import { getManyConversationsActions } from "@src/redux/Message";

export const useMessageList = () =>
  useEntityList({
    fetch: getManyConversationsActions.fetch,
    setPageNumber: getManyConversationsActions.setPageNumber,
    setView: getManyConversationsActions.setView,
    setSort: getManyConversationsActions.setSort,

    __globalFiltersActive: { pmsPropertyId: true },
    filterKeys: messageListFilterKeys,
    sortKeys: messageListSortKeys,
    entitiesSelector: messagesByIdsSelector,
    statusSelector: (state) => state.app.message.allList,
  });
