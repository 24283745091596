import classnames from "classnames";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export type ReservationKeyCodeModalProps = {
  isOpen: boolean;
  roomKeyCode: string | null;
  roomName: string | null;

  toggle: () => void;
};

export const ReservationKeyCodeModal: React.FC<ReservationKeyCodeModalProps> =
  ({
    isOpen,
    roomKeyCode,
    roomName,

    toggle,
  }) => {
    const headerContent = roomName ? (
      <>
        Room <span className="font-weight-bolder">{roomName}</span> Keycode
      </>
    ) : (
      "Room number not set"
    );

    const bodyClassName = classnames("text-center", {
      "display-1": roomKeyCode,
      "display-4": !roomKeyCode,
    });
    const bodyContent = roomKeyCode || "Not set";

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{headerContent}</ModalHeader>
        <ModalBody className={bodyClassName}>{bodyContent}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
