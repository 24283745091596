import { combineReducers } from "redux";

import {
  attachmentEntitySlice,
  attachmentPendingEntitySlice,
} from "./Attachment";
import layout from "./reducers/layout";
import navbar from "./reducers/navbar";
import { requestStatusSlice } from "./RequestStatus";
import {
  appAuthDataSlice,
  appGlobalFilterByPropertyIdSlice,
  appGlobalSearchGuestSlice,
  appGlobalSearchQuerySlice,
  appGlobalSearchReservationSlice,
  appGuestAllStatusSlice,
  appGuestByIdSlice,
  appMessagesAllStatusSlice,
  appMessagesByIdSlice,
  appMessagesTemplatesSlice,
  appNotificationsDataSlice,
  appReservationAllStatusSlice,
  appReservationByIdAttachmentsSlice,
  appReservationByIdPreCheckinSlice,
  appReservationByIdSlice,
  appStatisticsByPropertySlice,
  appUserLoginStatusSlice,
  validateMessageTemplatesSlice,
} from "./slices";
import { entitySlices } from "./slices/entitySlices";
import { errorSlice } from "./slices/errorSlices";

export const rootReducer = combineReducers({
  app: combineReducers({
    __global: combineReducers({
      error: combineReducers({
        errorEndpoint: errorSlice.reducer,
      }),
      filter: combineReducers({
        pmsPropertyId: appGlobalFilterByPropertyIdSlice.reducer,
      }),
      search: combineReducers({
        query: appGlobalSearchQuerySlice.reducer,
        data: combineReducers({
          guest: appGlobalSearchGuestSlice.reducer,
          reservation: appGlobalSearchReservationSlice.reducer,
        }),
      }),
    }),
    auth: combineReducers({
      data: appAuthDataSlice.reducer,
    }),
    guest: combineReducers({
      allList: appGuestAllStatusSlice.reducer,
      byId: appGuestByIdSlice.reducer,
      byIdMessages: appMessagesByIdSlice.reducer,
    }),
    reservation: combineReducers({
      allList: appReservationAllStatusSlice.reducer,
      byId: appReservationByIdSlice.reducer,
      byIdAttachments: appReservationByIdAttachmentsSlice.reducer,
      byIdPreCheckin: appReservationByIdPreCheckinSlice.reducer,
    }),
    message: combineReducers({
      allList: appMessagesAllStatusSlice.reducer,
      template: appMessagesTemplatesSlice.reducer,
      templateExpiration: validateMessageTemplatesSlice.reducer,
    }),
    statistics: combineReducers({
      property: appStatisticsByPropertySlice.reducer,
    }),
    notifications: combineReducers({
      allList: appNotificationsDataSlice.reducer,
    }),
    user: combineReducers({
      login: appUserLoginStatusSlice.reducer,
    }),
  }),

  navbar,
  layout,

  entities: combineReducers({
    attachment: attachmentEntitySlice.reducer,
    guest: entitySlices.guest.reducer,
    property: entitySlices.property.reducer,
    reservation: entitySlices.reservation.reducer,
    conversation: entitySlices.conversation.reducer,
  }),

  requestStatuses: requestStatusSlice.reducer,

  pendingEntities: combineReducers({
    attachment: attachmentPendingEntitySlice.reducer,
  }),
});
