import { useAppDispatch } from "@hooks/.";
import { useAppSelector } from "@hooks/useAppSelector";
import { EntityId } from "@reduxjs/toolkit";
import { getChartOptions } from "@src/constants";
import { statisticActions } from "@src/redux/actions/statisticsActions";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

export type RevenueChartSeries = { name: string; data: number[] }[];

export type RevenueTrendData = {
  month: string;
  actual: number;
  expected: number;
};

export type RevenueChartProps = {
  pmsPropertyId: EntityId;
  title?: string;
  year: number;
};

export type ChartProps = {
  primary: string;
  dangerLight: string;
  strokeColor: string;
  labelColor: string;
  categories: string[];
};

const defaultColors = {
  primary: "#7367F0",
  success: "#28C76F",
  danger: "#EA5455",
  warning: "#FF9F43",
  primaryLight: "#9c8cfc",
  dangerLight: "#f29292",
  strokeColor: "#b9c3cd",
  labelColor: "#e7eef7",
};

export const RevenueChart: React.FC<RevenueChartProps> = ({
  pmsPropertyId,
  title = "Revenue Trend",
  year,
}) => {
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<RevenueChartSeries>([]);

  const propertyStatistics = useAppSelector(
    (state) => state.app.statistics.property.entities[pmsPropertyId]
  );

  const revenueTrendDataToArrays = (
    revenueTrendData: RevenueTrendData[] = []
  ) => {
    const actual: number[] = [];
    const expected: number[] = [];
    const categories: string[] = [];

    revenueTrendData.forEach((data) => {
      actual.push(data.actual);
      expected.push(data.expected);
      categories.push(data.month);
    });

    return { actual, expected, categories };
  };

  const updateChart = () => {
    const revenueTrendData = revenueTrendDataToArrays(
      propertyStatistics?.year[year]?.propertyRevenueTrend
    );

    const { actual, expected, categories } = revenueTrendData;
    const { primary, dangerLight, strokeColor, labelColor } = defaultColors;

    const seriesData = [
      { name: "Actual", data: actual },
      { name: "Expected", data: expected },
    ];

    const optionsData = getChartOptions({
      categories,
      primary,
      dangerLight,
      strokeColor,
      labelColor,
    });

    setSeries(seriesData);
    setOptions(optionsData);
  };

  useEffect(() => {
    updateChart();
  }, [propertyStatistics, year]);

  useEffect(() => {
    const promise = dispatch(
      statisticActions.fetchPropertyYearRevenueTrendStatistics(year)
    );

    return () => promise.abort();
  }, [pmsPropertyId, year]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <Chart options={options} series={series} type="line" height={300} />
      </CardBody>
    </Card>
  );
};
