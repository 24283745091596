import "./ReservationTimeline.scss";

import { Timeline } from "@src/@core/components/timeline";
import { ReservationEntity } from "@src/types";
import { TimelineEntity } from "@src/types/_entities/TimelineEntity";
import { formatDate } from "@src/utility";
import { isFuture } from "@src/utility/Utils";

export type ReservationTimelineProps = { data: ReservationEntity };

export const ReservationTimeline: React.FC<ReservationTimelineProps> = ({
  data: { bookedAt, bookedAtInPropertyTz, checkInDate, checkOutDate },
}) => {
  const getTimelineStatusIcon = (param: string) =>
    isFuture(param) ? "wait" : "success";

  const timelineData: TimelineEntity[] = [
    {
      color: getTimelineStatusIcon(checkOutDate),
      title: "Check Out",
      content: isFuture(checkOutDate)
        ? "The guest has not checked out yet"
        : "The guest checked out",
      meta: formatDate(checkOutDate),
    },
    {
      color: getTimelineStatusIcon(checkInDate),
      title: "Check In",
      content: isFuture(checkInDate)
        ? "The guest has not checked in yet"
        : "The guest checked in",
      meta: formatDate(checkInDate),
    },
    {
      color: getTimelineStatusIcon(bookedAt),
      title: "Reservation",
      content: isFuture(bookedAt)
        ? "Booking not submitted yet"
        : "Submitted booking via the website",
      meta: formatDate(bookedAtInPropertyTz),
    },
  ];

  return <Timeline data={timelineData} className="reservation-timeline" />;
};
