import "./ReservationBadge.scss";

import { reservationStatusesConfig } from "@configs/Reservation";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";
import { Badge } from "reactstrap";

export type ReservationBadgeProps = { data: ReservationListColumnNodeProps };

export const ReservationBadge: React.FC<ReservationBadgeProps> = ({
  data: { pmsStatus, confirmationNumber },
}) => {
  const badgeColor =
    (pmsStatus && reservationStatusesConfig[pmsStatus]?.color) || "secondary";

  const normalPart = confirmationNumber.slice(0, 3);
  const highlightedPart = confirmationNumber.slice(-3);

  return (
    <Badge color={badgeColor} pill className="reservation-badge">
      <span className="weak-text">{normalPart}</span>
      {highlightedPart}
    </Badge>
  );
};
