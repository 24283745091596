import { apiConfig } from "@configs/apiConfig";
import { reservationNoteAdapter } from "@src/utility";
import axios from "axios";
import { deserialize } from "jsonapi-fractal";

export const jsonapiServiceFactory =
  <T extends { [key in "R" | "A"]: any }>({
    method = "GET",
    url,
    withCredentials = true,
    headers = { Accept: "application/vnd.api+json" },
    includes,
  }: {
    method?: "GET" | "POST";
    url: (arg: T["A"]) => string;
    withCredentials?: boolean;
    headers?: Partial<{ [HeaderKey in "Content-Type" | "Accept"]: string }>;
    includes?: string[];
  }) =>
  async (arg: T["A"]) => {
    const queryParams: string[] = [];

    if (includes) {
      queryParams.push(`include=${includes.join(",")}`);
    }

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const res = await axios.request({
      method,
      url: `${apiConfig.baseUrl}${url(arg)}${queryString}`,
      headers,
      withCredentials,
    });

    const deserializedData = deserialize(
      res.data,
      apiConfig.deserializeOptions
    );

    /** Temporary check for reservation notes adapter usage */
    const data =
      url(0) === "/reservations/0"
        ? {
            ...deserializedData,
            notes: reservationNoteAdapter(deserializedData.notes),
          }
        : url(0) === "/guests/0"
        ? {
            ...deserializedData,
            // @ts-ignore
            reservations: deserializedData.reservations.map((reservation) => ({
              ...reservation,
              notes: reservationNoteAdapter(reservation.notes),
            })),
          }
        : deserializedData;

    return data as Promise<T["R"]>;
  };
