import { NavbarSuggestionNodeProps, PrettyCell } from "@components/.";
import { ReservationStatus } from "@src/types";
import { formatDate } from "@src/utility";
import { BookOpen } from "react-feather";

import { ReservationConfirmationNumberPretty } from "..";

export type ReservationCardSimpleProps = NavbarSuggestionNodeProps<
  "reservation",
  {
    confirmationNumber: string;
    mainGuestName: string;
    checkOutDate: string;
    checkInDate: string;
    status: ReservationStatus;
  }
>;

export const ReservationCardSimple: React.FC<ReservationCardSimpleProps> = ({
  checkInDate,
  checkOutDate,
  confirmationNumber,
  iconComponent: IconTag = BookOpen,
  mainGuestName,
  status,
}) => (
  <div>
    <div className="item-container d-flex ellipsis-wrapper">
      <div style={{ marginTop: "3px" }}>
        <IconTag size={17} />
      </div>
      <div className="item-info ml-1 d-flex flex-grow-1 ellipsis-wrapper">
        <div
          className="flex-shrink-0 flex-grow-1"
          style={{
            width: "80px",
            minWidth: "80px",
            maxWidth: "80px",
          }}
        >
          <p className="align-middle mb-0">
            <PrettyCell
              disablePill
              showTextOnMobile
              status={status}
              node={
                <ReservationConfirmationNumberPretty
                  confirmationNumber={confirmationNumber}
                />
              }
              style={{ width: "80px", justifyContent: "center" }}
            />
          </p>
        </div>
        <div className="d-flex mx-1 ellipsis-wrapper">
          <div className="d-flex align-items-center ellipsis-wrapper">
            <p className="mb-0 ellipsis">{mainGuestName}</p>
          </div>
        </div>
        <div className="d-flex align-items-center ml-auto flex-grow-1 ellipsis-wrapper">
          <p
            className="align-middle mb-0 text-right ellipsis ml-auto"
            style={{ whiteSpace: "nowrap" }}
          >
            <small>
              {formatDate(checkInDate) || "#"} -{" "}
              {formatDate(checkOutDate) || "#"}
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
);
