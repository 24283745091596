import {
  ActionCreator,
  AnyAction,
  CaseReducer,
  EntityId,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { listStatusStateInit } from "@src/constants/listStatusStateInit";
import { statusStateInit } from "@src/constants/statusStateInit";
import {
  AbstractEntity,
  JsonapiPage,
  JsonapiPayloadFetchMany,
  JsonapiResponseFetchManyMeta,
  ListView,
  NotificationEntity,
  StatusState,
} from "@src/types";
import { deleteIdFromArray } from "@src/utility";

import {
  __globalSearchGuestsActions,
  __globalSearchReservationsActions,
  __globalSetFilterByPropertyId,
  __globalSetSearchQuery,
} from "../__Global";
import { userActions } from "../actions";
import { statisticActions } from "../actions/statisticsActions";
import { asyncThunkListStatusSliceFactory } from "../asyncThunkListStatusSliceFactory";
import { asyncThunkStatusReducers } from "../asyncThunkStatusReducers";
import { createEntityStatusStateByIdSlice } from "../createEntityStatusStateByIdSlice";
import { createJsonapiAsyncThunkRejectedError } from "../createJsonapiAsyncThunkRejectedError";
import { entityAdapters } from "../entityAdapters";
import { getManyGuestsActions, getOneGuestActions } from "../Guest";
import { propertyTodayStatisticsReducer } from "../reducers/propertyTodayStatisticsReducer";
import { propertyYearStatisticsReducer } from "../reducers/propertyYearStatisticsReducer";
import { getManyMessagesActions } from "../Message/getManyMessagesActions";
import { MessageEntity } from "../../types/_entities/MessageEntity";
import {
  getManyConversationsActions,
  getManyNotificationsActions,
  patchManyNotificationActions,
  patchOneNotificationActions,
  postMessageActions,
} from "@src/redux/Message";
import {
  getManyReservationsActions,
  getOneReservationActions,
  getOneReservationAttachmentsActions,
  postOneReservationAttachmentActions,
  postOneReservationPreCheckInActions,
} from "../Reservation";

/** app */

export const appBookingListAllFilterSlice = createSlice({
  name: `app/booking/listAll/filter`,
  initialState:
    entityAdapters["appReservationBookingTableFilter"].getInitialState(),
  reducers: {
    setAll: entityAdapters["appReservationBookingTableFilter"].setAll,
    setMany: entityAdapters["appReservationBookingTableFilter"].setMany,
    removeOne: entityAdapters["appReservationBookingTableFilter"].removeOne,
    removeAll: entityAdapters["appReservationBookingTableFilter"].removeAll,
  },
});

const setCaseReducer = <T>(state: T, { payload }: { payload: T }) => payload;

export const appGlobalFilterByPropertyIdSlice = createSlice({
  name: "__global/filter/byPropertyId",
  initialState: null as EntityId | null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(__globalSetFilterByPropertyId, setCaseReducer);
    builder.addCase(
      userActions.login.fulfilled,
      (state, { payload: { data } }) => data.scopes?.[0]?.property_id || null
    );
    builder.addCase(
      userActions.loginGoogle.fulfilled,
      (state, { payload: { data } }) => data.scopes?.[0]?.property_id || null
    );
  },
});

export const appGlobalSearchQuerySlice = createSlice({
  name: "__global/search/query",
  initialState: "" as string,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(__globalSetSearchQuery, (state, { payload }) => payload);
  },
});

type GlobalSearchEntityState = StatusState &
  Partial<JsonapiResponseFetchManyMeta> & {
    ids: EntityId[];
    requestId?: string;
    meta:
      | JsonapiResponseFetchManyMeta
      | { [key in keyof JsonapiResponseFetchManyMeta]?: undefined };
    page: JsonapiPage;
  };

const __globalSearchRejectedCaseReducer: CaseReducer<
  GlobalSearchEntityState,
  ReturnType<ActionCreator<AnyAction>>
> = (state, { payload, error, meta: { requestId } }) =>
  state.requestId === requestId
    ? {
        ...state,
        error: createJsonapiAsyncThunkRejectedError(payload, error),
        isLoading: false,
        requestId: undefined,
        meta: { totalCount: undefined, totalPages: undefined },
      }
    : state;

const __globalSearchFulfilledCaseReducer: CaseReducer<
  GlobalSearchEntityState,
  ReturnType<
    ActionCreator<
      PayloadAction<
        JsonapiPayloadFetchMany<AbstractEntity>,
        string,
        { requestId: string }
      >
    >
  >
> = (state, { payload: { data, meta }, meta: { requestId } }) =>
  state.requestId === requestId
    ? {
        ...state,
        ids: [...state.ids, ...data.map(({ id }) => id)],
        error: null,
        isLoading: false,
        requestId: undefined,
        meta,
      }
    : state;

const initialAppGlobalSearchEntityState: GlobalSearchEntityState = {
  error: null,
  isLoading: false,
  requestId: undefined,
  page: { number: 1, size: 5 },
  meta: { totalCount: undefined, totalPages: undefined },
  ids: [],
};

export const appGlobalSearchGuestSlice = createSlice({
  name: "__global/search/guest",
  initialState: initialAppGlobalSearchEntityState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        __globalSearchGuestsActions.refetch.pending,
        (state, { meta: { requestId } }) => ({
          ...state,
          error: null,
          isLoading: true,
          requestId,
          page: { ...state.page, number: 1 },
          ids: [],
        })
      )
      .addCase(
        __globalSearchGuestsActions.fetchMore.pending,
        (state, { meta: { requestId } }) => ({
          ...state,
          error: null,
          isLoading: true,
          requestId,
        })
      )
      .addCase(
        __globalSearchGuestsActions.refetch.rejected,
        __globalSearchRejectedCaseReducer
      )
      .addCase(
        __globalSearchGuestsActions.fetchMore.rejected,
        __globalSearchRejectedCaseReducer
      )
      .addCase(
        __globalSearchGuestsActions.refetch.fulfilled,
        __globalSearchFulfilledCaseReducer
      )
      .addCase(
        __globalSearchGuestsActions.fetchMore.fulfilled,
        __globalSearchFulfilledCaseReducer
      )
      .addCase(
        __globalSearchGuestsActions.resetStatus,
        () => initialAppGlobalSearchEntityState
      )
      .addCase(
        __globalSearchGuestsActions.setPageNumber,
        (state, { payload }) => ({
          ...state,
          page: { ...state.page, number: payload },
        })
      )
      .addCase(
        __globalSearchGuestsActions.setPageSize,
        (state, { payload }) => ({
          ...state,
          page: { ...state.page, size: payload },
        })
      )
      .addCase(__globalSetSearchQuery, (state, { payload }) => ({
        ...state,
        ids: [],
        isLoading: !!payload,
      }));
  },
});

export const appGlobalSearchReservationSlice = createSlice({
  name: "__global/search/reservation",
  initialState: initialAppGlobalSearchEntityState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        __globalSearchReservationsActions.refetch.pending,
        (state, { meta: { requestId } }) => ({
          ...state,
          error: null,
          isLoading: true,
          requestId,
          page: { ...state.page, number: 1 },
          ids: [],
        })
      )
      .addCase(
        __globalSearchReservationsActions.fetchMore.pending,
        (state, { meta: { requestId } }) => ({
          ...state,
          error: null,
          isLoading: true,
          requestId,
        })
      )
      .addCase(
        __globalSearchReservationsActions.refetch.rejected,
        __globalSearchRejectedCaseReducer
      )
      .addCase(
        __globalSearchReservationsActions.fetchMore.rejected,
        __globalSearchRejectedCaseReducer
      )
      .addCase(
        __globalSearchReservationsActions.refetch.fulfilled,
        __globalSearchFulfilledCaseReducer
      )
      .addCase(
        __globalSearchReservationsActions.fetchMore.fulfilled,
        __globalSearchFulfilledCaseReducer
      )
      .addCase(
        __globalSearchReservationsActions.resetStatus,
        () => initialAppGlobalSearchEntityState
      )
      .addCase(
        __globalSearchReservationsActions.setPageNumber,
        (state, { payload }) => ({
          ...state,
          page: { ...state.page, number: payload },
        })
      )
      .addCase(
        __globalSearchReservationsActions.setPageSize,
        (state, { payload }) => ({
          ...state,
          page: { ...state.page, size: payload },
        })
      )
      .addCase(__globalSetSearchQuery, (state, { payload }) => ({
        ...state,
        ids: [],
        isLoading: !!payload,
      }));
  },
});

export const appAuthDataSlice = createSlice({
  name: "auth/data",
  initialState: { isUserLoggedIn: false, email: undefined } as {
    isUserLoggedIn: boolean;
    email: string | undefined;
    isAgent: boolean;
    canBroadcastMessages: boolean;
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userActions.login.pending, (state, { meta: { arg } }) => {
        state.email = arg.email;
      })
      .addCase(userActions.login.rejected, (state) => {
        state.email = undefined;
      })
      .addCase(
        userActions.login.fulfilled,
        (state, { payload: { data }, meta: { arg } }) => {
          state.isUserLoggedIn = true;
          state.email = arg.email;
          state.isAgent = data.is_agent ?? false;
          state.canBroadcastMessages = data.can_broadcast_messages ?? false;
        }
      )
      .addCase(userActions.loginGoogle.pending, (state, { meta: { arg } }) => {
        state.email = arg.email;
      })
      .addCase(userActions.loginGoogle.rejected, (state) => {
        state.email = undefined;
      })
      .addCase(
        userActions.loginGoogle.fulfilled,
        (state, { payload: { data }, meta: { arg } }) => {
          state.isUserLoggedIn = true;
          state.email = arg.email;
          state.isAgent = data.is_agent ?? false;
          state.canBroadcastMessages = data.can_broadcast_messages ?? false;
        }
      );
  },
});

export const appStatisticsByPropertySlice = createSlice({
  name: "app/statistics/property",
  initialState: entityAdapters["appStatisticsProperty"].getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        statisticActions.fetchPropertyYearDaysBookedStatistics.fulfilled,
        propertyYearStatisticsReducer
      )
      .addCase(
        statisticActions.fetchPropertyYearRentRevenueStatistics.fulfilled,
        propertyYearStatisticsReducer
      )

      .addCase(
        statisticActions.fetchPropertyTodayRentRevenueStatistics.fulfilled,
        propertyTodayStatisticsReducer
      )
      .addCase(
        statisticActions.fetchPropertyTodayReservationsTotalCount.fulfilled,
        propertyTodayStatisticsReducer
      )
      .addCase(
        statisticActions.fetchPropertyTodayNotYetChecked.fulfilled,
        propertyTodayStatisticsReducer
      )
      .addCase(
        statisticActions.fetchPropertyTodayCheckedIn.fulfilled,
        propertyTodayStatisticsReducer
      )

      .addCase(
        statisticActions.fetchPropertyYearRevenueTrendStatistics.fulfilled,
        propertyYearStatisticsReducer
      );
  },
});

export const appReservationAllStatusSlice = asyncThunkListStatusSliceFactory({
  name: "app/reservation/all/status",
  ...getManyReservationsActions,
  __setGlobalFilterPmsPropertyId: __globalSetFilterByPropertyId,
  initialState: listStatusStateInit({ key: "bookedAt", order: "DESC" }),
});

export const appReservationByIdSlice = createEntityStatusStateByIdSlice({
  name: "app/reservation/byId",
  ...getOneReservationActions,
});

export const appReservationByIdAttachmentsSlice = createSlice({
  name: "app/reservation/byId/attachments",
  initialState: {} as Partial<
    Record<
      EntityId,
      StatusState & {
        meta:
          | JsonapiResponseFetchManyMeta
          | { [key in keyof JsonapiResponseFetchManyMeta]?: undefined };
        ids: EntityId[] | null;
        pendingIds: EntityId[];
      }
    >
  >,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getOneReservationAttachmentsActions.fetch.pending,
        (state, { meta }) => {
          const entity = state[meta.arg];

          if (entity) {
            state[meta.arg] = {
              ...entity,
              isLoading: true,
              error: null,
            };
          } else {
            state[meta.arg] = {
              meta: { totalCount: undefined, totalPages: undefined },
              isLoading: true,
              error: null,
              ids: null,
              pendingIds: [],
            };
          }
        }
      )
      .addCase(
        getOneReservationAttachmentsActions.fetch.rejected,
        (state, { meta, payload, error }) => {
          if (error.name !== "AbortError") {
            const entity = state[meta.arg];

            if (entity) {
              state[meta.arg] = {
                ...entity,
                isLoading: false,
                error: createJsonapiAsyncThunkRejectedError(payload, error),
                meta: { totalCount: undefined, totalPages: undefined },
              };
            } else {
              state[meta.arg] = {
                isLoading: false,
                error: createJsonapiAsyncThunkRejectedError(payload, error),
                meta: { totalCount: undefined, totalPages: undefined },
                ids: null,
                pendingIds: [],
              };
            }
          }
        }
      )
      .addCase(
        getOneReservationAttachmentsActions.fetch.fulfilled,
        (state, { meta: { arg }, payload: { data, meta } }) => {
          const ids = data.map(({ id }) => id);

          state[arg] = {
            isLoading: false,
            error: null,
            meta,
            ids,
            pendingIds: [],
          };
        }
      )
      .addCase(
        getOneReservationAttachmentsActions.resetStatus,
        (state, { payload }) => {
          state[payload] = {
            isLoading: false,
            error: null,
            meta: { totalCount: undefined, totalPages: undefined },
            ids: null,
            pendingIds: [],
          };
        }
      )
      .addCase(
        postOneReservationAttachmentActions.pending,
        (state, { meta }) => {
          const entity = state[meta.arg.id];
          if (entity) {
            state[meta.arg.id] = {
              ...entity,
              pendingIds: [...entity.pendingIds, meta.requestId],
            };
          } else {
            state[meta.arg.id] = {
              isLoading: false,
              error: null,
              meta: { totalCount: undefined, totalPages: undefined },
              ids: null,
              pendingIds: [meta.requestId],
            };
          }
        }
      )
      .addCase(
        postOneReservationAttachmentActions.fulfilled,
        (state, { meta, payload }) => {
          const entity = state[meta.arg.id];
          if (entity) {
            const ids = [...(state[meta.arg.id]?.ids || []), payload.id];

            const pendingIds = deleteIdFromArray(
              meta.requestId,
              entity.pendingIds
            );

            state[meta.arg.id] = {
              ...entity,
              meta: {
                totalPages: entity.meta.totalPages || 1,
                totalCount: (entity.meta.totalCount || 0) + 1,
              },
              ids,
              pendingIds,
            };
          }
        }
      );
  },
});

export const appReservationByIdPreCheckinSlice = createSlice({
  name: "app/reservation/byId/PreCheckin",
  initialState: {} as Partial<Record<EntityId, StatusState>>,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        postOneReservationPreCheckInActions.fetch.pending,
        (state, { meta }) => {
          const entity = state[meta.arg.id];

          if (entity) {
            state[meta.arg.id] = {
              ...entity,
              isLoading: true,
              error: null,
            };
          } else {
            state[meta.arg.id] = {
              isLoading: true,
              error: null,
            };
          }
        }
      )
      .addCase(
        postOneReservationPreCheckInActions.fetch.rejected,
        (state, { meta, payload, error }) => {
          if (error.name !== "AbortError") {
            const entity = state[meta.arg.id];

            if (entity) {
              state[meta.arg.id] = {
                ...entity,
                isLoading: false,
                error: createJsonapiAsyncThunkRejectedError(payload, error),
              };
            } else {
              state[meta.arg.id] = {
                isLoading: false,
                error: createJsonapiAsyncThunkRejectedError(payload, error),
              };
            }
          }
        }
      )
      .addCase(
        postOneReservationPreCheckInActions.fetch.fulfilled,
        (state, { meta: { arg } }) => {
          state[arg.id] = {
            isLoading: false,
            error: null,
          };
        }
      )
      .addCase(
        postOneReservationPreCheckInActions.resetStatus,
        (state, { payload }) => {
          state[payload] = {
            isLoading: false,
            error: null,
          };
        }
      );
  },
});

export const appNotificationsDataSlice = createSlice({
  name: "app/notifications/all",
  initialState: { notifications: [] as NotificationEntity[] } as StatusState & {
    notifications: NotificationEntity[];
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getManyNotificationsActions.fetch.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(patchOneNotificationActions.markAsRead.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(patchManyNotificationActions.markAsRead.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        getManyNotificationsActions.fetch.rejected,
        (state, { payload, error }) => {
          if (error.name !== "AbortError") {
            state.notifications = [];
            state.isLoading = false;
            state.error = createJsonapiAsyncThunkRejectedError(payload, error);
          }
        }
      )
      .addCase(
        getManyNotificationsActions.fetch.fulfilled,
        (state, { payload: { data } }) => {
          state.notifications = data;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        patchOneNotificationActions.markAsRead.rejected,
        (state, { error }) => {
          if (error.name !== "AbortError") {
            state.isLoading = false;
          }
        }
      )
      .addCase(
        patchOneNotificationActions.markAsRead.fulfilled,
        (
          state,
          {
            payload: {
              data: { id },
            },
          }
        ) => {
          const newNotifications = state.notifications;
          const index = newNotifications.findIndex((o) => o.id === id);
          if (index > -1) {
            newNotifications.splice(index, 1);
          }

          state.notifications = newNotifications;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        patchManyNotificationActions.markAsRead.rejected,
        (state, { error }) => {
          if (error.name !== "AbortError") {
            state.isLoading = false;
          }
        }
      )
      .addCase(
        patchManyNotificationActions.markAsRead.fulfilled,
        (
          state,
          {
            payload: {
              data: { ids },
            },
          }
        ) => {
          const newNotifications = state.notifications;

          for (let i = 0; i < ids.length; i++) {
            const index = newNotifications.findIndex((o) => o.id === ids[i]);
            if (index > -1) {
              newNotifications.splice(index, 1);
            }
          }

          state.notifications = newNotifications;
          state.isLoading = false;
          state.error = null;
        }
      );
  },
});

export const appMessagesAllStatusSlice = asyncThunkListStatusSliceFactory({
  name: "app/messages/all/status",
  ...getManyConversationsActions,
  __setGlobalFilterPmsPropertyId: __globalSetFilterByPropertyId,
  initialState: listStatusStateInit({ key: "readBySentAt", order: "ASC" }),
});

export const appMessagesByIdSlice = createSlice({
  name: "app/messages/byIdGuest",
  initialState: {} as Partial<
    Record<
      EntityId,
      StatusState & { isFetching: boolean; messages: MessageEntity[] }
    >
  >,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getManyMessagesActions.fetchByGuest.pending,
        (state, { meta }) => {
          state[meta.arg] = {
            messages: [],
            ...state[meta.arg],
            isLoading: true,
            isFetching: !state[meta.arg],
            error: null,
          };
        }
      )
      .addCase(
        getManyMessagesActions.fetchByGuest.rejected,
        (state, { meta, payload, error }) => {
          if (error.name !== "AbortError") {
            state[meta.arg] = {
              messages: [],
              ...state[meta.arg],
              isLoading: false,
              isFetching: false,
              error: createJsonapiAsyncThunkRejectedError(payload, error),
            };
          }
        }
      )
      .addCase(
        getManyMessagesActions.fetchByGuest.fulfilled,
        (state, { meta, payload: { data } }) => {
          state[meta.arg] = {
            ...state[meta.arg],
            messages: [...data].reverse(),
            isLoading: false,
            isFetching: false,
            error: null,
          };
        }
      )
      .addCase(postMessageActions.sendToOne.pending, (state, { meta }) => {
        const messages = state[meta.arg.guestId]?.messages ?? [];
        state[meta.arg.guestId] = {
          ...state[meta.arg.guestId],
          messages: [
            ...messages,
            {
              authorType: "User",
              authorName: "Sending...",
              authorId: -1,
              body: meta.arg.body,
              sentAt: new Date().toISOString(),
            } as any,
          ],
          isLoading: false,
          isFetching: false,
          error: null,
        };
      })
      .addCase(
        postMessageActions.sendToOne.rejected,
        (state, { meta, payload, error: errorResp }) => {
          const messages = state[meta.arg.guestId]?.messages ?? [];
          const error = payload?.response?.data?.error ?? null;
          if (errorResp.name !== "AbortError") {
            state[meta.arg.guestId] = {
              messages: messages.slice(0, -1),
              isLoading: false,
              isFetching: false,
              error: {
                code: error?.code,
                title: error?.message,
                message: meta.arg.body,
              },
            };
          }
        }
      );
  },
});

export const appMessagesTemplatesSlice = createSlice({
  name: "messages/fetchTemplates",
  initialState: { entities: {} },
  reducers: {
    setTemplates(state, action) {
      state.entities = action.payload;
    },
  },
});

export const validateMessageTemplatesSlice = createSlice({
  name: "messages/validateTemplates",
  initialState: { validUntil: 0 },
  reducers: {
    setExpirationTime(state, action) {
      state.validUntil = action.payload;
    },
  },
});

export const appGuestByIdSlice = createSlice({
  name: "app/guest/byId",
  initialState: {} as Partial<
    Record<EntityId, StatusState & { reservationsView: ListView }>
  >,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOneGuestActions.fetch.pending, (state, { meta }) => {
        state[meta.arg] = {
          ...(state[meta.arg] || { reservationsView: "cards" }),
          isLoading: true,
          error: null,
        };
      })
      .addCase(
        getOneGuestActions.fetch.rejected,
        (state, { meta, payload, error }) => {
          if (error.name !== "AbortError") {
            state[meta.arg] = {
              ...(state[meta.arg] || { reservationsView: "cards" }),
              isLoading: false,
              error: createJsonapiAsyncThunkRejectedError(payload, error),
            };
          }
        }
      )
      .addCase(getOneGuestActions.fetch.fulfilled, (state, { payload }) => {
        state[payload.id] = {
          ...(state[payload.id] || { reservationsView: "cards" }),
          isLoading: false,
          error: null,
        };
      })
      .addCase(getOneGuestActions.resetStatus, (state, { payload }) => {
        state[payload] = {
          ...(state[payload] || { reservationsView: "cards" }),
          isLoading: false,
          error: null,
        };
      })
      .addCase(
        getOneGuestActions.setReservationsView,
        (state, { payload: { id, view } }) => {
          state[id] = {
            ...(state[id] || { isLoading: false, error: null }),
            reservationsView: view,
          };
        }
      );
  },
});

export const appGuestAllStatusSlice = asyncThunkListStatusSliceFactory({
  name: "app/guest/all/status",
  ...getManyGuestsActions,
  __setGlobalFilterPmsPropertyId: __globalSetFilterByPropertyId,
  initialState: listStatusStateInit({ key: "lastName", order: "ASC" }),
});

export const appUserLoginStatusSlice = createSlice({
  name: "app/user/login/status",
  initialState: statusStateInit,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      userActions.login.pending,
      asyncThunkStatusReducers.pending
    );
    builder.addCase(
      userActions.login.rejected,
      asyncThunkStatusReducers.rejected
    );
    builder.addCase(
      userActions.loginGoogle.pending,
      asyncThunkStatusReducers.pending
    );
    builder.addCase(
      userActions.loginGoogle.rejected,
      asyncThunkStatusReducers.rejected
    );
    builder.addCase(
      userActions.login.fulfilled,
      asyncThunkStatusReducers.fulfilled
    );
    builder.addCase(
      userActions.loginGoogle.fulfilled,
      asyncThunkStatusReducers.fulfilled
    );
    builder.addCase(
      userActions.loginResetStatus,
      asyncThunkStatusReducers.reset
    );
  },
});
