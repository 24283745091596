import "./GlobalFilterByPropertyForm.scss";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { EntityId } from "@reduxjs/toolkit";
import { __globalSetFilterByPropertyId } from "@src/redux/__Global";
import { PropertyEntity, Skin } from "@src/types";
import { getDateNow } from "@src/utility";
import { selectCustomStyles } from "@src/utility/Utils";
import { selectors } from "@store/selectors";
import classnames from "classnames";
import Select from "react-select";
import { Form, FormGroup, Label } from "reactstrap";

export type GlobalFilterByPropertyFormProps = {
  className?: string;
  skin: Skin;
  showPropertySelect: boolean;
};

export const GlobalFilterByPropertyForm: React.FC<GlobalFilterByPropertyFormProps> =
  ({ className, skin, showPropertySelect }) => {
    const dispatch = useAppDispatch();

    const convertPropertyToSelectOption = ({
      id,
      property_name,
    }: PropertyEntity) => ({
      label: property_name,
      value: id,
    });

    const options = useAppSelector((state) =>
      selectors.property.selectAll(state).map(convertPropertyToSelectOption)
    );

    const valueEntity = useAppSelector((state) => {
      const { pmsPropertyId } = state.app.__global.filter;

      if (pmsPropertyId) {
        const entity = state.entities.property.entities[pmsPropertyId];

        if (entity) {
          return entity;
        }
      }
    });

    if (options.length === 0) {
      return null;
    }

    const value = valueEntity
      ? convertPropertyToSelectOption(valueEntity)
      : null;

    const handleChange = (
      selectValue: { label: string; value: EntityId } | null
    ) => {
      if (value?.value === selectValue?.value) {
        return;
      }

      if (selectValue) {
        dispatch(__globalSetFilterByPropertyId(selectValue.value));
      }
    };

    const nowDate = getDateNow();

    const localPropertyDateNowFormatted =
      valueEntity &&
      Intl.DateTimeFormat("en-US", {
        weekday: "long",
        month: "short",
        day: "numeric",
        year: "numeric",
        timeZone: valueEntity.property_time_zone,
      }).format(nowDate);

    const localPropertyDateNowFormattedShort =
      valueEntity &&
      Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        timeZone: valueEntity.property_time_zone,
      }).format(nowDate);

    return (
      <Form
        inline
        className={classnames("global-filter-by-property-form", className)}
      >
        <FormGroup className="mb-0 flex-grow-1">
          <div className="d-flex align-items-center mr-1 w-100">
            {showPropertySelect && (
              <Select
                options={options}
                className="react-select global-filter-by-property-select flex-grow-1 animate__animated animate__fadeIn"
                classNamePrefix="select"
                styles={selectCustomStyles(skin)}
                value={value}
                onChange={handleChange}
              />
            )}
            {localPropertyDateNowFormatted && (
              <div className="d-none d-md-block ml-auto">
                <Label className="h6 mb-0 ml-1">
                  {localPropertyDateNowFormatted}
                </Label>
              </div>
            )}
            {localPropertyDateNowFormattedShort && (
              <div className="d-block d-md-none ml-auto">
                <Label className="h6 mb-0 ml-1">
                  {localPropertyDateNowFormattedShort}
                </Label>
              </div>
            )}
          </div>
        </FormGroup>
      </Form>
    );
  };
