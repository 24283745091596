export const getFileExtension = (fileName: string) => {
  const nameChunks = fileName.toLowerCase().split(".");
  const length = nameChunks.length;

  if (length > 0) {
    const extString = nameChunks[length - 1];

    if (extString.length > 0) {
      return extString;
    }
  }

  return false;
};
