export const getNightsRange = (start: string, end: string) => {
  const arr = [];
  for (
    const dt = new Date(start);
    dt < new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};
