import "./ReservationConfirmationNumberPretty.scss";

export type ReservationConfirmationNumberPrettyProps = {
  confirmationNumber: string;
};

export const ReservationConfirmationNumberPretty: React.FC<ReservationConfirmationNumberPrettyProps> =
  ({ confirmationNumber }) => (
    <span className="reservation-confirmation-number-pretty">
      <span className="text-weak">{confirmationNumber.slice(0, 3)}</span>
      {confirmationNumber.slice(-3)}
    </span>
  );
