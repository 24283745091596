import { usePlaceholderColor } from "@hooks/usePlaceholderColor";
import { unitifySize } from "@src/utility";
import { TextRow } from "react-placeholder/lib/placeholders";

export type PlaceholderTextRowProps = {
  className?: string;
  height?: number | string;
  minWidth?: number | string;
  width?: number | string;
  style?: React.CSSProperties;
};

export const PlaceholderTextRow: React.FC<PlaceholderTextRowProps> = ({
  className,
  style,
  ...props
}) => {
  const color = usePlaceholderColor();

  const width = unitifySize(props.width);
  const minWidth = unitifySize(props.minWidth);

  return (
    <TextRow
      color={color}
      className={className}
      style={{
        minWidth,
        width,
        marginTop: undefined,
        borderRadius: "3px",
        ...style,
      }}
    />
  );
};
