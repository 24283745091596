import {
  GuestCardSimple,
  GuestCardSimpleProps,
  ReservationCardSimple,
  ReservationCardSimpleProps,
} from "..";

export type NavbarSearchCustomItemProps =
  | GuestCardSimpleProps
  | ReservationCardSimpleProps;

export const NavbarSearchCustomItem: React.FC<NavbarSearchCustomItemProps> = (
  item
) => {
  switch (item.type) {
    case "guest":
      return <GuestCardSimple {...item} />;
    case "reservation":
      return <ReservationCardSimple {...item} />;
  }
};
