import "./NotesListModal.scss";

import { Note } from "@components/.";
import { EntityId } from "@reduxjs/toolkit";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export type NoteListModalProps = {
  isOpen: boolean;
  notes: { id: EntityId; content: string }[];

  header: React.ReactNode;

  toggle: () => void;
};

export const NoteListModal: React.FC<NoteListModalProps> = ({
  isOpen,
  notes,

  header,

  toggle,
}) => {
  const noteElements = notes.map((note) => (
    <Note key={`note-body-${note.id}`} {...note} />
  ));

  const modalHeaderElement = header && (
    <ModalHeader toggle={toggle}>{header}</ModalHeader>
  );

  const notesModal =
    notes.length > 0 ? (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
        scrollable
        className="notes-list-modal"
      >
        {modalHeaderElement}
        <ModalBody className="d-flex flex-column flex-grow-1">
          {noteElements}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    ) : null;

  return notesModal;
};
