import { ReservationStatus } from "@src/types";
import {
  Book,
  Check,
  EyeOff,
  HelpCircle,
  LogIn,
  LogOut,
  X,
} from "react-feather";

import { badgesColors } from "../badgesColors";

export const reservationStatusesConfig: Record<
  ReservationStatus,
  {
    icon?: JSX.Element;
    alias: string;
    color: string;
    colorHex: string;
  }
> = {
  checked_out: {
    icon: <LogOut />,
    alias: "Check Out",
    color: "secondary",
    colorHex: badgesColors[0],
  },
  checked_in: {
    icon: <LogIn />,
    alias: "Check In",
    color: "info",
    colorHex: badgesColors[1],
  },
  confirmed: {
    icon: <Check />,
    alias: "Confirmed",
    color: "success",
    colorHex: badgesColors[2],
  },
  cancelled: {
    icon: <X />,
    alias: "Cancelled",
    color: "danger",
    colorHex: badgesColors[3],
  },
  not_confirmed: {
    icon: <HelpCircle />,
    alias: "Not confirmed",
    color: "danger",
    colorHex: badgesColors[4],
  },
  no_show: {
    icon: <EyeOff />,
    alias: "No Show",
    color: "warning",
    colorHex: badgesColors[5],
  },
  reserved: {
    icon: <Book />,
    alias: "Reserved",
    color: "info",
    colorHex: badgesColors[6],
  },
} as const;
