import { apiConfig } from "@configs/apiConfig";
import axios, { AxiosResponse } from "axios";

export const restapiServiceFactory =
  <T extends { [key in "R" | "A"]: any }>({
    method = "GET",
    url,
    withCredentials = true,
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }: {
    method?: "GET" | "POST";
    url: (arg: T["A"]) => string;
    withCredentials?: boolean;
    headers?: Partial<{ [HeaderKey in "Content-Type" | "Accept"]: string }>;
  }) =>
  async (arg: T["A"]) => {
    const response = await axios.request<T["R"]>({
      method,
      url: `${apiConfig.baseUrl}${url(arg)}`,
      headers,
      withCredentials,
      data: arg,
    });

    return response as AxiosResponse<Promise<T["R"]>>;
  };
