import { unitifySize } from "@src/utility";
import classnames from "classnames";

import { PlaceholderRectShape } from "..";

export type IconPlaceholderProps = {
  className?: string;
  size?: number | string;
  style?: React.CSSProperties;
};

export const IconPlaceholder: React.FC<IconPlaceholderProps> = ({
  className,
  size = 24,
  style,
}) => {
  const rectShapeDimension = unitifySize(size);

  return (
    <PlaceholderRectShape
      className={classnames(className)}
      style={{
        width: rectShapeDimension,
        minWidth: rectShapeDimension,
        height: rectShapeDimension,
        marginRight: undefined,
        ...style,
      }}
    />
  );
};
