import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import axios from "axios";

export type PostMessageToOneArgs = {
  pmsPropertyId: EntityId;
  guestId: EntityId;
  body: string;
};

export const postMessageToOneService = async ({
  signal,
  arg: { pmsPropertyId, guestId, body },
}: {
  signal: AbortSignal;
  arg: PostMessageToOneArgs;
}) => {
  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  await axios.request({
    method: "POST",
    url: `${apiConfig.baseUrl}/guests/${guestId}/messages`,
    headers: { Accept: "application/vnd.api+json" },
    withCredentials: true,
    cancelToken: source.token,
    data: {
      pms_property_id: pmsPropertyId,
      guest_id: guestId,
      body,
    },
  });

  return { response: "success" };
};
