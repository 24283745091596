import "./LoginForm.scss";

import {
  ButtonRippled,
  ErrorStateAlert,
  LoginFormGoogleLoginButton,
} from "@components/.";
import InputPasswordToggle from "@components/input-password-toggle";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useUserLoginForm } from "@hooks/useUserLoginForm";
import { userActions } from "@src/redux/actions";
import classnames from "classnames";
import { GoogleLogin, GoogleLoginResponse } from "react-google-login";
import { CustomInput, Form, FormGroup, Input, Label } from "reactstrap";

export type LoginFormProps = { className?: string };

export const LoginForm: React.FC<LoginFormProps> = ({ className }) => {
  const {
    error,
    isLoading,

    formValues,

    handleFormFieldChange,
    handleFormSubmit,

    handleStatusReset,
  } = useUserLoginForm();

  const dispatch = useAppDispatch();

  const errorElement = error && (
    <ErrorStateAlert error={error} toggle={handleStatusReset} />
  );

  const googleLoginElement = process.env.REACT_APP_GOOGLE_LOGIN_ID && (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_LOGIN_ID}
      accessType="online"
      // @ts-ignore
      onSuccess={({ tokenId, profileObj: { email } }: GoogleLoginResponse) => {
        dispatch(
          userActions.loginGoogle({
            email,
            token: tokenId,
          })
        );
      }}
      render={LoginFormGoogleLoginButton}
    />
  );

  return (
    <Form
      className={classnames("login-form", className)}
      onSubmit={handleFormSubmit}
    >
      <h4 className="h4 font-weight-bold mb-1">Welcome to</h4>
      <h1 className="h1 font-weight-bold mb-4">BODE OPS 3</h1>
      {errorElement}
      <FormGroup>
        <Label className="form-label" for="login-email">
          Email
        </Label>
        <Input
          type="email"
          name="email"
          id="login-email"
          placeholder="john@example.com"
          autoFocus
          value={formValues["email"]}
          onChange={handleFormFieldChange}
          disabled={isLoading}
          data-cy="login-form-input-email"
        />
      </FormGroup>
      <FormGroup>
        <div className="d-flex justify-content-between">
          <Label className="form-label" for="login-password">
            Password
          </Label>
        </div>
        <InputPasswordToggle
          className="input-group-merge"
          id="login-password"
          name="password"
          value={formValues["password"]}
          onChange={handleFormFieldChange}
          disabled={isLoading}
          data-cy="login-form-input-password"
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="checkbox"
          className="custom-control-Primary"
          id="remember-me"
          label="Remember Me"
          disabled={isLoading}
        />
      </FormGroup>
      <ButtonRippled
        color="primary"
        className="login-form-button-submit mb-2"
        block
        type="submit"
        disabled={isLoading}
        data-cy="login-form-button-submit"
      >
        Sign in
      </ButtonRippled>
      {googleLoginElement}
    </Form>
  );
};
