import { EntityListCardsView, EntityListTableView } from "@src/components";
import {
  AbstractEntity,
  EntityListTableConfig,
  JsonapiSort,
  ListView,
} from "@src/types";

export type EntityListBodyProps<
  EK extends string,
  T extends AbstractEntity,
  SK extends keyof T
> = {
  className?: string;

  data?: T[] | null;
  isLoading: boolean;
  view: ListView;
  sort?: JsonapiSort<SK>;

  cardComponent: React.ComponentType<{ data: T }>;
  cardPlaceholderComponent: React.ComponentType;
  noDataNode: React.ReactNode;

  placeholderDummyEntry: T;

  tableConfig: EntityListTableConfig<T, EK>;

  onRowClicked?: (row: T) => void;
  onSort?: (sort: JsonapiSort<SK>) => void;
};

export const EntityListBody = <
  T extends AbstractEntity,
  EK extends string,
  SK extends keyof T & string
>({
  className,

  data,
  isLoading,
  view,
  sort,

  placeholderDummyEntry,
  tableConfig,
  noDataNode,
  cardComponent: EntityCard,
  cardPlaceholderComponent: EntityCardPlaceholder,
  onRowClicked,
  onSort,
}: EntityListBodyProps<EK, T, SK>) => {
  const cardsViewElement = (
    <EntityListCardsView
      data={data}
      cardComponent={EntityCard}
      cardPlaceholderComponent={EntityCardPlaceholder}
      noDataNode={noDataNode}
    />
  );

  const tableViewElement = (
    <EntityListTableView
      data={data}
      sort={sort}
      placeholderDummyEntry={placeholderDummyEntry}
      tableConfig={tableConfig}
      isLoading={isLoading}
      noDataNode={noDataNode}
      onRowClicked={onRowClicked}
      className={className}
      onSort={onSort}
    />
  );

  const contentViews = {
    cards: cardsViewElement,
    table: tableViewElement,
  };

  return contentViews[view];
};
