import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import {
  JsonapiPage,
  JsonapiResponseFetchManyMeta,
  JsonapiSort,
  // MessageEntity,
  ConversationEntity,
} from "@src/types";
import { MessageListFilter, MessageListSortKey } from "@src/types/Message";
import axios from "axios";
import { deserialize } from "jsonapi-fractal";

export const getManyConversationsService = async ({
  params: { page, sort, filter },
  signal,
}: {
  params: {
    __globalFilter: {
      pmsPropertyId?: EntityId | null;
    };
    filter: Partial<MessageListFilter>;
    page: JsonapiPage;
    sort?: JsonapiSort<MessageListSortKey>;
  };
  signal: AbortSignal;
}) => {
  const queryParams: string[] = [];

  (["pmsPropertyId"] as const).forEach((k) => {
    if (filter.pmsPropertyId === "all") return;
    const filterValue = filter[k];

    if (filterValue) {
      queryParams.push(`filter[${k}]=${filterValue}`);
    }
  });

  (["number", "size"] as const).forEach((k) => {
    queryParams.push(`page[${k}]=${page[k]}`);
  });

  if (sort?.key) {
    // queryParams.push(`sort=${sort.order === "DESC" ? "-" : ""}${sort.key}`);
    queryParams.push(`sort=-readBySentAt`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  const res = await axios.request({
    method: "GET",
    url: `${apiConfig.baseUrl}/inbox_messages${queryString}`,
    headers: { Accept: "application/vnd.api+json" },
    withCredentials: true,
    cancelToken: source.token,
  });

  const data = deserialize(
    res.data,
    apiConfig.deserializeOptions
  ) as ConversationEntity[];
  const meta = res.data.meta as JsonapiResponseFetchManyMeta;

  return { data, meta };
};
