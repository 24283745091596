import {
  getPropertyTodayRentRevenueStatisticsService,
  getPropertyYearDaysBookedStatisticsService,
  getPropertyYearRentRevenueStatisticsService,
  getPropertyYearRevenueTrendStatisticsService,
} from "@src/services/PropertyStatistic";

import { createStatisticsPropertyTodayAsyncThunk } from "../createStatisticsPropertyTodayAsyncThunk";
import { createStatisticsPropertyYearAsyncThunk } from "../createStatisticsPropertyYearAsyncThunk";
import { getPropertyTodayNotYetCheckedStatisticsService } from "@src/services/PropertyStatistic/getPropertyTodayNotYetCheckedStatisticsService";
import { getPropertyTodayCheckedStatisticsService } from "@src/services/PropertyStatistic/getPropertyTodayCheckedInStatisticsService";
import { getPropertyTodayReservationsTotalCount } from "@src/services/PropertyStatistic/getPropertyTodayReservationsTotalCount";

const fetchPropertyTodayRentRevenueStatistics =
  createStatisticsPropertyTodayAsyncThunk({
    name: "statistics/property/today/rentRevenue",
    serviceFn: getPropertyTodayRentRevenueStatisticsService,
  });

const fetchPropertyTodayNotYetChecked = createStatisticsPropertyTodayAsyncThunk(
  {
    name: "statistics/property/today/reservationsNotYetCheckedInTodayTotalCount",
    serviceFn: getPropertyTodayNotYetCheckedStatisticsService,
  }
);

const fetchPropertyTodayCheckedIn = createStatisticsPropertyTodayAsyncThunk({
  name: "statistics/property/today/reservationsCheckedInTodayTotalCount",
  serviceFn: getPropertyTodayCheckedStatisticsService,
});

const fetchPropertyTodayReservationsTotalCount =
  createStatisticsPropertyTodayAsyncThunk({
    name: "statistics/property/today/reservationsTotalCount",
    serviceFn: getPropertyTodayReservationsTotalCount,
  });

const fetchPropertyYearDaysBookedStatistics =
  createStatisticsPropertyYearAsyncThunk({
    name: "statistics/property/year/daysBooked",
    serviceFn: getPropertyYearDaysBookedStatisticsService,
  });

const fetchPropertyYearRentRevenueStatistics =
  createStatisticsPropertyYearAsyncThunk({
    name: "statistics/property/year/rentRevenue",
    serviceFn: getPropertyYearRentRevenueStatisticsService,
  });

const fetchPropertyYearRevenueTrendStatistics =
  createStatisticsPropertyYearAsyncThunk({
    name: "statistics/property_revenue_trend",
    serviceFn: getPropertyYearRevenueTrendStatisticsService,
  });

export const statisticActions = {
  fetchPropertyTodayRentRevenueStatistics,
  fetchPropertyTodayNotYetChecked,
  fetchPropertyTodayReservationsTotalCount,
  fetchPropertyTodayCheckedIn,

  fetchPropertyYearDaysBookedStatistics,
  fetchPropertyYearRentRevenueStatistics,
  fetchPropertyYearRevenueTrendStatistics,
};
