import {
  ActionCreatorWithPayload,
  AsyncThunkAction,
  EntityId,
} from "@reduxjs/toolkit";
import {
  AbstractEntity,
  AppState,
  JsonapiAsyncThunkConfig,
  JsonapiResponseFetchMany,
  JsonapiSort,
  ListFilter,
  ListFilterValue,
  ListStatusState,
  ListView,
} from "@src/types";
import { useEffect } from "react";

import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export const useEntityList = <
  ST extends AbstractEntity,
  ET extends AbstractEntity & Partial<ST>,
  SortKey extends string,
  FilterKey extends string,
  Filter extends ListFilter<FilterKey>,
  EntityListStatusState extends ListStatusState<
    SortKey,
    Filter
  > = ListStatusState<SortKey, Filter>,
  GlobalFilter extends {
    [key in string]: ListFilterValue;
  } &
    AppState["app"]["__global"]["filter"] = {
    [key in string]: ListFilterValue;
  } &
    AppState["app"]["__global"]["filter"],
  GlobalFilterKey extends keyof GlobalFilter = keyof GlobalFilter
>({
  fetch,
  setPageNumber,
  setView,
  setSort,

  __globalFiltersActive,

  entitiesSelector,
  filterKeys,
  statusSelector,
}: {
  fetch: () => AsyncThunkAction<
    JsonapiResponseFetchMany<ST>,
    void,
    JsonapiAsyncThunkConfig
  >;
  setPageNumber: ActionCreatorWithPayload<number>;
  setSort: ActionCreatorWithPayload<JsonapiSort<SortKey>>;
  setView: ActionCreatorWithPayload<ListView>;

  __globalFiltersActive: {
    [GFK in GlobalFilterKey]: boolean;
  };
  filterKeys: Readonly<FilterKey[]>;
  sortKeys: Readonly<SortKey[]>;

  entitiesSelector: (
    state: AppState,
    ids: EntityId[] | undefined
  ) => ET[] | undefined;
  statusSelector: (state: AppState) => EntityListStatusState;
}) => {
  const dispatch = useAppDispatch();

  /** ListState */

  const [
    {
      filter,
      ids,
      isLoading,
      page,
      sort,
      view,
      meta: { totalCount, totalPages },
    },
    __globalFilter,
  ] = useAppSelector((state) => [
    statusSelector(state),
    state.app.__global.filter as GlobalFilter,
  ]);

  const { pmsPropertyId } = __globalFilter;

  /** Fetching */

  const fetchDeps = [
    page.number,
    page.size,
    sort.key,
    sort.order,
  ] as ListFilterValue[];

  (Object.keys(__globalFiltersActive) as GlobalFilterKey[]).forEach(
    (globalFilterKey) => {
      if (__globalFiltersActive[globalFilterKey]) {
        fetchDeps.push(__globalFilter[globalFilterKey]);
      }
    }
  );

  filterKeys.forEach((filterKey) => {
    fetchDeps.push(filter[filterKey]);
  });

  useEffect(() => {
    const promise = dispatch(fetch());

    return () => {
      promise.abort();
    };
  }, fetchDeps);

  /** Scrolling UI to top on page change */

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page.number]);

  /** Entities */

  const data = useAppSelector((state) => entitiesSelector(state, ids));

  /** View */
  const handleViewChange = (view: ListView) => {
    dispatch(setView(view));
  };

  /** Sort */

  const handleSort = (sortValue: typeof sort) => {
    dispatch(setSort(sortValue));
  };

  /** Pagination */

  const handlePageChange = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
  };

  /** Manual refresh */
  const handleRefresh = () => {
    dispatch(fetch());
  };

  /** Current property timezone */

  const timeZone = pmsPropertyId
    ? useAppSelector(
        (state) =>
          state.entities.property.entities[pmsPropertyId]?.property_time_zone
      )
    : undefined;

  return {
    dispatch,

    filter,
    ids,
    isLoading,
    page,
    sort,
    view,
    totalCount,
    totalPages,
    __globalFilter,

    data,

    timeZone,

    handleRefresh,
    handlePageChange,
    handleSort,
    handleViewChange,
  };
};
