import { EntityId } from "@reduxjs/toolkit";
import { AppState, AttachmentEntity } from "@src/types";

import { attachmentEntityAdapter } from "./attachmentEntityAdapter";

const selectors = attachmentEntityAdapter.getSelectors<AppState>(
  (state) => state.entities.attachment
);

export const selectByIds = (state: AppState, ids?: EntityId[] | null) => {
  const returnData: AttachmentEntity[] = [];

  if (!ids) {
    return;
  }

  ids.forEach((id) => {
    const entity = selectors.selectById(state, id);

    if (entity) {
      returnData.push(entity);
    }
  });

  return returnData;
};

export const attachmentEntitySelectors = { ...selectors, selectByIds };
