import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  StatisticsServiceResult,
  __GlobalFilterServiceOptions,
  __GlobalFilterThunkConfig,
} from "@src/types";

export const createStatisticsPropertyYearAsyncThunk = ({
  name,
  serviceFn,
}: {
  name: string;
  serviceFn: (
    options: __GlobalFilterServiceOptions<{ year: number }>
  ) => Promise<StatisticsServiceResult<{ year: number }>>;
}) =>
  createAsyncThunk<
    StatisticsServiceResult<{ year: number }>,
    number,
    __GlobalFilterThunkConfig
  >(name, async (arg, { rejectWithValue, getState, signal }) => {
    try {
      const { pmsPropertyId } = getState().app.__global.filter;

      if (pmsPropertyId) {
        return await serviceFn({
          params: {
            __globalFilter: { pmsPropertyId },
            filter: { year: arg },
          },
          signal,
        });
      } else {
        throw new Error("Missing property id");
      }
    } catch (err) {
      return rejectWithValue(err as __GlobalFilterThunkConfig["rejectValue"]);
    }
  });
