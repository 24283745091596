import { Draft, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { PropertyStatistics, StatisticsServiceResult } from "@src/types";

import { entityAdapters } from "../entityAdapters";

export const propertyTodayStatisticsReducer = (
  state: Draft<EntityState<PropertyStatistics>>,
  {
    payload: {
      data: { id, key, value },
    },
  }: PayloadAction<StatisticsServiceResult>
) => {
  const entity = state.entities[id] || { id, today: {}, year: {} };

  return entityAdapters["appStatisticsProperty"].setOne(state, {
    ...entity,
    today: {
      ...entity.today,
      [key]: value,
    },
  });
};
