import { EntityId } from "@reduxjs/toolkit";
import { Override } from "@src/types";
import { Link, LinkProps } from "react-router-dom";

export type ReservationDetailsLinkProps = Override<
  LinkProps,
  { id: EntityId; to?: undefined; htmlId?: string }
>;

export const ReservationDetailsLink: React.FC<ReservationDetailsLinkProps> = ({
  id,
  htmlId,
  ...props
}) => <Link {...props} to={`/reservations/${id}`} id={htmlId} />;
