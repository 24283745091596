import { liquidEngine } from "@src/utility";

export const reservationQuickReplyConfig = {
  resendCheckInText: {
    title: "Resend Check In Text",
    template: liquidEngine.parse(
      "Welcome to Bode! We’re ready for you and so is your room. Your room number is {{roomName}} and the access code is {{roomKeyCode}}#."
    ),
  },
  resendConfirmationText: {
    title: "Resend Confirmation Text",
    template: liquidEngine.parse(
      "Confirmation #{{confirmationNumber}} {{roomType}} Check in: {{checkInDate | formatDate}} at 4:00 PM Check out: {{checkOutDate | formatDate}} at 11:00 AM"
    ),
  },
  earlyCheckInRequest: {
    title: "Early Check in Request",
    template: liquidEngine.parse(
      "Early check in is not guaranteed. We can make note of your ETA and do our best to accommodate you. Also, you will receive a text with check in instructions automatically once your room is ready, and you have completed our pre-arrival procedures. Once that text is received you may check in at anytime!\r\n\r\nIf you need to store luggage, please see our Porch staff."
    ),
  },
  lateCheckOut: {
    title: "Late Check out (No Availability)",
    template: liquidEngine.parse(
      "Unfortunately, there is no availability for late check out at this time. You are welcome to store your luggage down at The Porch while you explore the city or wait for your flight!"
    ),
  },
  ourPleasure: {
    title: "Our pleasure!",
    template: liquidEngine.parse("Our pleasure!"),
  },
};
