import { usePlaceholderColor } from "@hooks/usePlaceholderColor";
import { unitifySize } from "@src/utility";
import { RoundShape } from "react-placeholder/lib/placeholders";

export const PlaceholderRoundShape: React.FC<{
  className?: string;
  size?: number | string;
  style?: React.CSSProperties;
}> = ({ className, size, style }) => {
  const placeholderColor = usePlaceholderColor();

  const roundShapeDimension = unitifySize(size);

  return (
    <RoundShape
      className={className}
      color={placeholderColor}
      style={{
        width: roundShapeDimension,
        minWidth: roundShapeDimension,
        height: roundShapeDimension,
        ...style,
      }}
    />
  );
};
