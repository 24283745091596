import { reservationStatusesConfig } from "@configs/Reservation/reservationStatusesConfig";
import { ReservationStatus } from "@src/types";
import classnames from "classnames";
import { HelpCircle } from "react-feather";
import { Badge } from "reactstrap";

type PrettyCellProps = {
  disablePill?: boolean;
  node?: React.ReactNode;
  showTextOnMobile?: boolean;
  status?: ReservationStatus;
  text?: string;

  className?: string;
  style?: React.CSSProperties;
};

export const getStatusDisplay = ({ status }: Required<PrettyCellProps>) =>
  reservationStatusesConfig[status].alias;

/**
 * @todo
 * Move reservation status  related logic to new ReservationStatusPrettyCell
 * component
 */
export const PrettyCell: React.FC<PrettyCellProps> = ({
  disablePill = false,
  node,
  showTextOnMobile = false,
  status,
  text,

  className,
  style,
}) => {
  const defaultCell = {
    icon: <HelpCircle />,
    color: "cornflowerblue",
    alias: "Wrong status provided",
  };

  const prettyCell = status ? reservationStatusesConfig[status] : defaultCell;

  const content = node || text || prettyCell.alias;

  return (
    <Badge
      className={classnames(className)}
      color={prettyCell.color}
      pill={!disablePill}
      style={style}
    >
      {prettyCell.icon}

      <span
        className={classnames({
          "d-none d-md-inline": !showTextOnMobile,
          "d-inline": showTextOnMobile,
        })}
      >
        {content}
      </span>
    </Badge>
  );
};
