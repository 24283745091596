import { EntityId, createEntityAdapter } from "@reduxjs/toolkit";
import { reservationSelectById } from "@src/redux/selectors";
import { AppState, GuestEntity, Override, ReservationEntity } from "@src/types";

const guestEntityAdapter = createEntityAdapter<
  GuestEntity & { reservations?: EntityId[] | null }
>();

const selectors = guestEntityAdapter.getSelectors<AppState>(
  (state) => state.entities.guest
);

export const selectById = (state: AppState, id: EntityId) => {
  const entity = selectors.selectById(state, id);

  if (entity) {
    const reservations: Override<ReservationEntity, { guests: undefined }>[] =
      [];

    entity.reservations?.forEach((id) => {
      const reservation = reservationSelectById(state, id);

      if (reservation) {
        reservations.push(reservation);
      }
    });

    return { ...entity, reservations };
  }
};

export const guestEntitySelectors = { ...selectors, selectById };
