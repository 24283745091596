import Avatar from "@components/avatar";
import classnames from "classnames";
import { useState } from "react";
import { Tooltip } from "reactstrap";

export type AvatarWithTooltipProps = {
  className?: string;
  avatarClassName?: string;
  imgClassName?: string;
  fallbackSrc: string;
  htmlId: string;
  img: string | undefined;
  tooltipPlacement: "top" | "right";
  tooltipText: string;
  imgHeight?: string;
  imgWidth?: string;
};

export const AvatarWithTooltip: React.FC<AvatarWithTooltipProps> = ({
  className,
  avatarClassName,
  htmlId,
  tooltipText,
  tooltipPlacement,
  ...avatarProps
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className={classnames(className)}>
      <Avatar
        htmlId={htmlId}
        className={classnames("cursor-pointer", avatarClassName)}
        {...avatarProps}
      />
      {
        <Tooltip
          isOpen={tooltipOpen}
          target={htmlId}
          toggle={() => setTooltipOpen(!tooltipOpen)}
          placement={tooltipPlacement}
        >
          {tooltipText}
        </Tooltip>
      }
    </div>
  );
};
