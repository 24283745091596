import { GuestEntity } from "../types/_entities/GuestEntity";

export const EmphasisedName: React.FC<{
  guest: GuestEntity;
}> = (props) => {
  const { guest } = props;

  const guestNameElements = [
    guest.title,
    guest.firstName,
    guest.lastName && (
      <span className="font-weight-bolder"> {guest.lastName}</span>
    ),
  ]
    .filter(Boolean)
    .map((item) => item);

  return <>{guestNameElements}</>;
};
