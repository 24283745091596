import "./ReservationReportDroppableCard.scss";

import { useDroppable } from "@dnd-kit/core";
import classnames from "classnames";
import { FormGroup, Label } from "reactstrap";

export type ReservationReportDroppableCardProps = {
  children: React.ReactNode;
  label?: string;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  unstyled?: boolean;
  onClick?(): void;
  onRemove?(): void;

  id: string;
  items: string[];
  style?: React.CSSProperties;
};

export const ReservationReportDroppableCard: React.FC<ReservationReportDroppableCardProps> =
  ({ id, items, children, label, style }) => {
    const { active, over, setNodeRef } = useDroppable({
      id,
      data: {
        type: "container",
      },
    });

    const isDraggableOver = over
      ? (id === over.id && active?.data.current?.type !== "container") ||
        items.includes(over.id)
      : false;

    return (
      <div
        style={style}
        className={classnames("droppable-card-wrapper", {
          "draggable-over": isDraggableOver,
        })}
      >
        <FormGroup>
          <Label>{label}</Label>
          <ul className="card card-body droppable-card" ref={setNodeRef}>
            {children}
          </ul>
        </FormGroup>
      </div>
    );
  };
