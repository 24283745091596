import axios from "axios";
import classnames from "classnames";
import { ComponentType, forwardRef, useEffect, useRef } from "react";
import { Badge } from "reactstrap";

type Color =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "info"
  | "warning"
  | "dark"
  | "light-primary"
  | "light-secondary"
  | "light-success"
  | "light-danger"
  | "light-info"
  | "light-warning"
  | "light-dark";

export type AvatarProps =
  | {
      imgClassName?: string;
      className?: string;
      tag?: "div" | "span" | ComponentType;
      src?: string;
      badgeUp?: boolean;
      content: string;
      icon?: React.ReactNode;
      contentStyles?: {};
      badgeText?: string;
      imgHeight?: string | number;
      imgWidth?: string | number;
      size?: "sm" | "lg" | "xl" | "xxl";
      status?: "online" | "offline" | "away" | "busy";
      badgeColor?: Color;
      color?: Color;
      initials: true;
      img?: string | false;
      title?: string;
      fallbackSrc?: string;
      onClick?: () => void;
      htmlId?: string;
    }
  | {
      imgClassName?: string;
      className?: string;
      tag?: "div" | "span" | ComponentType;
      src?: string;
      badgeUp?: boolean;
      content?: undefined;
      icon?: React.ReactNode;
      contentStyles?: {};
      badgeText?: string;
      imgHeight?: string | number;
      imgWidth?: string | number;
      size?: "sm" | "lg" | "xl" | "xxl";
      status?: "online" | "offline" | "away" | "busy";
      badgeColor?: Color;
      color?: Color;
      initials?: undefined | false;
      img?: string | false;
      title?: string;
      fallbackSrc?: string;
      onClick?: () => void;
      htmlId?: string;
    };

const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  // ** Props
  const {
    color,
    className,
    imgClassName,
    // initials,
    size,
    badgeUp,
    content,
    icon,
    badgeColor,
    badgeText,
    img,
    imgHeight,
    imgWidth,
    status,
    tag: Tag = "div",
    contentStyles,
    title,
    fallbackSrc,
    htmlId,
    ...rest
  } = props;

  // ** Function to extract initials from content
  const getInitials = (str: string) => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      results.push(e[0]);
    });
    return results.join("");
  };

  const handleError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (!fallbackSrc) return;
    event.currentTarget.src = fallbackSrc;
  };

  const src = img ?? fallbackSrc;
  const imageElement = useRef<HTMLImageElement>(null);

  const setAvatarSrc = async () => {
    if (!imageElement?.current) return;
    if (!img || !fallbackSrc) return;
    try {
      const res = await axios.request({
        method: "HEAD",
        url: img,
      });
      const gravatarExists = res.headers["content-length"] !== "151";
      imageElement.current.src = gravatarExists ? img : fallbackSrc;
    } catch (err) {
      if (imageElement.current) imageElement.current.src = fallbackSrc;
    }
  };

  useEffect(() => {
    setAvatarSrc();
  }, []);

  return (
    <Tag
      className={classnames("avatar", className, {
        [`bg-${color}`]: color,
        [`avatar-${size}`]: size,
      })}
      ref={ref}
      title={title}
      id={htmlId}
      {...rest}
    >
      {src === false || src === undefined ? (
        <span
          className={classnames("avatar-content", {
            "position-relative": badgeUp,
          })}
          style={contentStyles}
        >
          {props.initials ? getInitials(props.content) : content}

          {icon ? icon : null}
          {badgeUp ? (
            <Badge
              color={badgeColor ? badgeColor : "primary"}
              className="badge-sm badge-up"
              pill
            >
              {badgeText ? badgeText : "0"}
            </Badge>
          ) : null}
        </span>
      ) : (
        <img
          ref={imageElement}
          className={classnames(imgClassName)}
          src={fallbackSrc}
          height={imgHeight && !size ? imgHeight : 32}
          width={imgWidth && !size ? imgWidth : 32}
          onError={handleError}
        />
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size,
          })}
        ></span>
      ) : null}
    </Tag>
  );
});

export default Avatar;
