import { PlaceholderTextRow } from "@src/components";
import { JsonapiPage } from "@src/types";
import ReactPlaceholder from "react-placeholder";

export type EntityListPaginationStatusProps = {
  page: JsonapiPage;
  meta: { totalCount?: number; totalPages?: number } | undefined;

  entitiesPlural: string;
};

export const EntityListPaginationStatus: React.FC<EntityListPaginationStatusProps> =
  ({
    page,
    meta: { totalCount, totalPages } = {},
    entitiesPlural = "entities",
  }) => {
    const zeroIndex = page.size * (page.number - 1);

    const lastIndex =
      totalPages !== undefined
        ? totalPages === 0
          ? 0
          : totalPages === page.number
          ? totalCount
          : page.size * page.number
        : undefined;

    return (
      <div>
        <p>
          Displaying {entitiesPlural}{" "}
          <span className="font-weight-bolder">{`${
            lastIndex === 0 ? 0 : zeroIndex + 1
          }`}</span>{" "}
          -{" "}
          <ReactPlaceholder
            showLoadingAnimation
            ready={lastIndex !== undefined}
            customPlaceholder={
              <PlaceholderTextRow
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "1rem",
                  fontSize: "1rem",
                  marginBottom: 0,
                  lineHeight: "1rem",
                  content: " ",
                  position: "relative",
                  top: "2px",
                }}
              />
            }
          >
            <span className="font-weight-bolder">{lastIndex}</span>
          </ReactPlaceholder>{" "}
          of{" "}
          <ReactPlaceholder
            showLoadingAnimation
            ready={totalCount !== undefined}
            customPlaceholder={
              <PlaceholderTextRow
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "1rem",
                  fontSize: "1rem",
                  marginBottom: 0,
                  lineHeight: "1rem",
                  content: " ",
                  position: "relative",
                  top: "2px",
                }}
              />
            }
          >
            <span className="font-weight-bolder">{totalCount}</span>
          </ReactPlaceholder>{" "}
          (page{" "}
          <span className="font-weight-bolder">
            {totalCount === 0 ? 0 : page.number}
          </span>{" "}
          of{" "}
          <ReactPlaceholder
            showLoadingAnimation
            ready={totalPages !== undefined}
            customPlaceholder={
              <PlaceholderTextRow
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "1rem",
                  fontSize: "1rem",
                  marginBottom: 0,
                  lineHeight: "1rem",
                  content: " ",
                  position: "relative",
                  top: "2px",
                }}
              />
            }
          >
            <span className="font-weight-bolder">{totalPages}</span>
          </ReactPlaceholder>
          )
        </p>
      </div>
    );
  };
