import { handleRTL } from "@src/redux/actions";
import { SetStateAction, useEffect } from "react";

import { useAppDispatch, useAppSelector } from ".";

export const useRTL = () => {
  const dispatch = useAppDispatch();

  const isRtl = useAppSelector((state) => state.layout.isRTL);

  const setValue = (value: SetStateAction<boolean>) => {
    try {
      const valueToStore = value instanceof Function ? value(isRtl) : value;

      dispatch(handleRTL(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const htmlElement = document.getElementsByTagName("html")[0];

    const newDir = isRtl ? "rtl" : "ltr";

    htmlElement.setAttribute("dir", newDir);
  }, [isRtl]);

  return [isRtl, setValue] as const;
};
