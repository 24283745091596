import "./DateRange.scss";

import {
  formatDate,
  formatTimeToUsTime,
  getDayNameFromDateString,
  getNightsRange,
} from "@src/utility";

export type DateRangeProps = {
  checkInDate: string;
  checkOutDate: string;
  estimatedArrivalTime: string | null;
  estimatedDepartureTime: string | null;
};

export const DateRange: React.FC<DateRangeProps> = ({
  checkInDate,
  checkOutDate,
  estimatedArrivalTime,
  estimatedDepartureTime,
}) => (
  <div className="date-range">
    <div className="date-range-wrapper date-start">
      <div className="date-range-top">Arrival</div>
      <div className="date-range-middle font-weight-bolder">
        {formatDate(checkInDate)}
      </div>
      <div className="date-range-bottom">
        {getDayNameFromDateString(checkInDate, { length: "short" })}
        {formatTimeToUsTime(estimatedArrivalTime, { prepend: " at " })}
      </div>
    </div>

    <div className="date-center">
      <div className="range range-start"></div>
      <div className="range-middle">
        <div className="range-nights">
          {getNightsRange(checkInDate, checkOutDate).length}
        </div>
        <div className="range-line"></div>
      </div>
      <div className="range range-end"></div>
    </div>

    <div className="date-range-wrapper date-end">
      <div className="date-range-top">Departure</div>
      <div className="date-range-middle font-weight-bolder">
        {formatDate(checkOutDate)}
      </div>
      <div className="date-range-bottom">
        {getDayNameFromDateString(checkOutDate, { length: "short" })}
        {formatTimeToUsTime(estimatedDepartureTime, { prepend: " at " })}
      </div>
    </div>
  </div>
);
