import { NavbarSuggestionNodeProps } from "@components/.";
import { User } from "react-feather";

export type GuestCardSimpleProps = NavbarSuggestionNodeProps<
  "guest",
  {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }
>;

export const GuestCardSimple: React.FC<GuestCardSimpleProps> = ({
  firstName,
  lastName,
  iconComponent: IconTag = User,
  email,
  phone,
}) => (
  <div>
    <div className="item-container d-flex ellipsis-wrapper">
      <div style={{ marginTop: "3px" }}>
        <IconTag size={17} />
      </div>
      <div
        className="d-flex align-items-center item-info ml-1 d-flex flex-grow-1 ellipsis-wrapper"
        style={{ whiteSpace: "nowrap" }}
      >
        <p className="align-middle mb-0 ellipsis">
          {`${firstName} ${lastName}`.trim()}
        </p>
        <div
          className="d-flex align-items-center mx-1 ellipsis-wrapper"
          style={{ overflow: "hidden", whiteSpace: "nowrap" }}
        >
          <p className="align-middle mb-0 ellipsis">
            <small className="text-muted">{phone}</small>
          </p>
        </div>
        <div className="d-flex align-items-center text-right ml-auto ellipsis-wrapper">
          <p className="align-middle mb-0 ellipsis">
            <small>{email}</small>
          </p>
        </div>
      </div>
    </div>
  </div>
);
