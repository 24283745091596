import { DateRange, ReservationListFilterPredefinedConfig } from "@src/types";
import { apiFormatNowDate } from "@src/utility";

export const reservationListFilterPredefinedConfig = (
  timeZone: string
): ReservationListFilterPredefinedConfig => {
  const apiFormattedDateNow = apiFormatNowDate(timeZone);

  const todayDateRange: DateRange = [apiFormattedDateNow, apiFormattedDateNow];

  return {
    cancelled: {
      id: "cancelled",
      label: "Cancelled",
      value: {
        pmsStatus: ["cancelled"],
      },
      defaultReportSelectedColumns: [
        "guestName",
        "number",
        "checkInDate",
        "checkOutDate",
        "roomTypes",
        "source",
        "priceTotal",
        "cancellationDate",
      ],
    },
    bookedToday: {
      id: "bookedToday",
      label: "Booked today",
      value: {
        pmsStatus: [
          "checked_in",
          "checked_out",
          "confirmed",
          "no_show",
          "reserved",
        ],
        bookedAtInPropertyTz: apiFormattedDateNow,
      },
      defaultReportSelectedColumns: [],
    },
    checkedIn: {
      id: "checkedIn",
      label: "Checked-in",
      value: {
        pmsStatus: ["checked_in"],
      },
      defaultReportSelectedColumns: [
        "number",
        "guestName",
        "checkInDate",
        "checkOutDate",
        "nightsCount",
        "guestsCount",
        "rooms",
        "priceTotal",
        "paidTotal",
        "remainingBalance",
        "notes",
      ],
    },
    arrivalsNotYetCheckedIn: {
      id: "arrivalsNotYetCheckedIn",
      label: "Pending arrivals",
      value: {
        pmsStatus: ["confirmed"],
        checkInDateRange: todayDateRange,
      },
      defaultReportSelectedColumns: [
        "number",
        "guestName",
        "repeatGuest",
        "arrivalTime",
        "checkInDate",
        "checkOutDate",
        "nightsCount",
        "guestsCount",
        "roomTypes",
        "rooms",
        "checkedIn",
        "notes",
      ],
    },
    departures: {
      id: "departures",
      label: "Departures",
      value: {
        checkOutDateRange: todayDateRange,
      },
      defaultReportSelectedColumns: [
        "number",
        "guestName",
        "checkInDate",
        "checkOutDate",
        "nightsCount",
        "guestsCount",
        "roomTypes",
        "rooms",
        "pmsStatus",
      ],
    },
    noShow: {
      id: "noShow",
      label: "No show",
      value: {
        pmsStatus: ["no_show"],
      },
      defaultReportSelectedColumns: ["number"],
    },
    vipsOnly: {
      id: "vipsOnly",
      label: "VIPs only",
      value: {
        isVip: true,
      },
      defaultReportSelectedColumns: [],
    },
  };
};
