import { EntityListTableCellPlaceholder } from "@src/components/";
import { AbstractEntity, EntityListTableConfig } from "@src/types";
import { IDataTableColumn } from "react-data-table-component";

export const createEntityListTablePlaceholderConfig = <
  EK extends string,
  T extends AbstractEntity
>(
  entityListTableConfig: EntityListTableConfig<T, EK>
) =>
  (
    Object.keys(entityListTableConfig.columns) as (keyof EntityListTableConfig<
      T,
      EK
    >["columns"])[]
  ).reduce(
    (prevVal, curVal) => ({
      ...prevVal,
      [curVal]: {
        ...entityListTableConfig.columns[curVal],
        cell: entityListTableConfig.columns[curVal]
          ? entityListTableConfig.placeholderOptions[curVal]?.cell
            ? entityListTableConfig.placeholderOptions[curVal]?.cell
            : EntityListTableCellPlaceholder
          : undefined,
      },
    }),
    {} as Partial<EntityListTableConfig<T, EK>["columns"]>
  ) as IDataTableColumn<T>;
