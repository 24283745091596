import "./LoginFormGoogleLoginButton.scss";

import { LoginFormGoogleIcon } from "@components/.";
import { ThemeContext } from "@src/utility/context/Theme";
import { useContext } from "react";
import { GoogleLoginProps } from "react-google-login";
import { Button } from "reactstrap";

export type LoginFormGoogleLoginButtonComponent = Exclude<
  GoogleLoginProps["render"],
  undefined
>;

export const LoginFormGoogleLoginButton: LoginFormGoogleLoginButtonComponent =
  ({ onClick, disabled }) => {
    const { skin } = useContext(ThemeContext);

    return (
      <Button
        onClick={onClick}
        disabled={disabled}
        outline={skin === "light"}
        color={skin === "light" ? "primary" : "light"}
        className="google-login-button waves-effect "
        block
      >
        <div className="google-login-icon-wrapper">
          <LoginFormGoogleIcon />
        </div>
        <div className="google-login-text-wrapper">Sign in with Google</div>
      </Button>
    );
  };
