import { EntityId } from "@reduxjs/toolkit";
import { getOneReservationActions } from "@src/redux/Reservation";
import { reservationIncludedGuestsSelectById } from "@src/redux/selectors";

import { useEntityDetails } from "./useEntityDetails";

export const useReservationDetails = (id: EntityId) =>
  useEntityDetails(id, {
    fetch: getOneReservationActions.fetch,
    entitySelector: reservationIncludedGuestsSelectById,
    statusSelector: (state, id) => state.app.reservation.byId[id],
  });
