import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import { guestListFilterKeys } from "@src/constants/guest";
import {
  GuestEntity,
  GuestListFilter,
  GuestListSortKey,
  JsonapiPage,
  JsonapiResponseFetchManyMeta,
  JsonapiSort,
} from "@src/types";
import axios from "axios";
import { deserialize } from "jsonapi-fractal";

export const getManyGuestsService = async ({
  params: { __globalFilter, filter, isSearch = false, page, sort },
  signal,
}: {
  params: {
    __globalFilter: {
      pmsPropertyId?: EntityId | null;
    };
    filter: Partial<GuestListFilter>;
    isSearch?: boolean; // used in global search field in navbar
    page: JsonapiPage;
    sort?: JsonapiSort<GuestListSortKey>;
  };
  signal: AbortSignal;
}) => {
  const queryParams: string[] = [];

  guestListFilterKeys.forEach((k) => {
    const filterValue = filter[k];

    if (filterValue) {
      queryParams.push(`filter[${k}]=${encodeURIComponent(filterValue)}`);
    }
  });

  (["pmsPropertyId"] as const).forEach((k) => {
    const filterValue = __globalFilter[k];

    if (filterValue) {
      queryParams.push(`filter[${k}]=${filterValue}`);
    }
  });

  (["number", "size"] as const).forEach((k) => {
    queryParams.push(`page[${k}]=${page[k]}`);
  });

  if (sort?.key) {
    queryParams.push(`sort=${sort.order === "DESC" ? "-" : ""}${sort.key}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  const requestUrl = isSearch
    ? `${apiConfig.baseUrl}/guests/search${queryString}`
    : `${apiConfig.baseUrl}/guests${queryString}`;

  const res = await axios.request({
    method: "GET",
    url: requestUrl,
    headers: { Accept: "application/vnd.api+json" },
    withCredentials: true,
    cancelToken: source.token,
  });

  const data = deserialize(
    res.data,
    apiConfig.deserializeOptions
  ) as GuestEntity[];

  const meta = res.data.meta as JsonapiResponseFetchManyMeta;

  return { data, meta };
};
