import { AppState, ErrorState, StatusState } from "@src/types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "./useAppSelector";

export type UseFormOptions<T> = {
  initValues: T;
  resetOnMount?: boolean;
  statusSelector: (state: AppState) => StatusState;

  resetFormErrorActionCreator: () => void;
  submitFormActionCreator: (formValues: T) => void;
};

export type UseFormReturnType<T> = {
  isLoading: boolean;
  error: ErrorState | null;

  formValues: T;
  setFormValues: React.Dispatch<React.SetStateAction<T>>;

  handleFormFieldChange: React.ChangeEventHandler<
    HTMLInputElement & { name: keyof T }
  >;
  handleFormSubmit: React.FormEventHandler<HTMLFormElement>;

  handleStatusReset: () => void;
};

type FormValues = { [key: string]: string | FormValues };

type UseForm = {
  <T extends FormValues>({
    initValues,
    statusSelector,

    resetFormErrorActionCreator,
    submitFormActionCreator,
  }: UseFormOptions<T>): UseFormReturnType<T>;
};

export const useForm: UseForm = <T extends FormValues>({
  initValues,
  resetOnMount = true,
  statusSelector,

  resetFormErrorActionCreator,
  submitFormActionCreator,
}: UseFormOptions<T>): UseFormReturnType<T> => {
  const dispatch = useDispatch();

  const handleStatusReset = () => {
    dispatch(resetFormErrorActionCreator());
  };

  useEffect(() => {
    if (resetOnMount) {
      handleStatusReset();
    }
  }, []);

  const { isLoading, error } = useAppSelector(statusSelector);

  const [formValues, setFormValues] = useState<T>(initValues);

  const handleFormFieldChange: React.ChangeEventHandler<
    HTMLInputElement & { name: T }
  > = (e) => {
    e.preventDefault();

    setFormValues({
      ...formValues,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    dispatch(submitFormActionCreator(formValues));
  };

  return {
    isLoading,
    error,

    formValues,
    setFormValues,

    handleFormFieldChange,
    handleFormSubmit,

    handleStatusReset,
  };
};
