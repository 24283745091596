import "./AttachmentCard.scss";
import "./AttachmentCardPlaceholder.scss";

import { CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import { AttachmentPlaceholder } from ".";
import { PlaceholderTextRow } from "..";

export const AttachmentCardPlaceholder: React.FC = () => (
  <div className="card attachment-card attachment-card-placeholder">
    <CardHeader className="align-items-end">
      <CardTitle>
        <PlaceholderTextRow />
      </CardTitle>
    </CardHeader>
    <CardBody>
      <Row>
        <Col xs="12" sm="6" md="4" lg="3" xl="12" className="col-xxl-6">
          <AttachmentPlaceholder />
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" xl="12" className="col-xxl-6">
          <AttachmentPlaceholder />
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" xl="12" className="col-xxl-6">
          <AttachmentPlaceholder />
        </Col>
      </Row>
    </CardBody>
  </div>
);
