import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";
import { patchManyNotificationAsReadService } from "@src/services/Message";
import { JsonapiAsyncThunkConfig } from "@src/types";

const patchManyNotificationAsRead = createAsyncThunk<
  { data: { ids: EntityId[] } },
  {
    ids: EntityId[];
    read?: boolean;
  },
  JsonapiAsyncThunkConfig
>(
  `notifications/patch/many`,
  async ({ ids, read = true }, { rejectWithValue, signal }) => {
    try {
      return await patchManyNotificationAsReadService({
        signal,
        ids,
        read,
      });
    } catch (err) {
      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  }
);

export const patchManyNotificationActions = {
  markAsRead: patchManyNotificationAsRead,
};
