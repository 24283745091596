import { EntityId, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { postReservationPreCheckInService } from "@src/services";
import { JsonapiAsyncThunkConfig } from "@src/types";

const postOneReservationPreCheckIn = createAsyncThunk<
  void,
  { id: EntityId; guestId: EntityId },
  JsonapiAsyncThunkConfig
>(
  `reservation/one/precheckin/post`,
  async (arg, { rejectWithValue, signal }) => {
    try {
      return await postReservationPreCheckInService({
        arg,
        signal,
      });
    } catch (err) {
      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  }
);

const resetStatus = createAction<EntityId>(
  "reservation/one/precheckin/resetStatus"
);

export const postOneReservationPreCheckInActions = {
  fetch: postOneReservationPreCheckIn,
  resetStatus,
};
