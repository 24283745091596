import { SingleServiceTConfig } from "@src/types";

import { jsonapiServiceFactory } from "./jsonapiServiceFactory";
import { restapiServiceFactory } from "./restapiServiceFactory";

export const moduleServicesFactory = <
  MServiceTConfig extends {
    [key in string]: SingleServiceTConfig<any, any>;
  },
  MServiceName extends keyof MServiceTConfig = keyof MServiceTConfig,
  MServiceConfig extends {
    [MServiceNameK in MServiceName]: {
      method?: "GET" | "POST";
      url: (arg: MServiceTConfig[MServiceNameK]["A"]) => string;
      serviceType?: "RESTAPI";
    };
  } = {
    [MServiceNameK in MServiceName]: {
      method?: "GET" | "POST";
      url: (arg: MServiceTConfig[MServiceNameK]["A"]) => string;
      serviceType?: "RESTAPI";
    };
  },
  ReturnT = {
    [MServiceNameK in MServiceName]: (
      arg: MServiceTConfig[MServiceNameK]["A"]
    ) => Promise<MServiceTConfig[MServiceNameK]["R"]>;
  }
>(
  moduleServiceConfig: MServiceConfig
) =>
  (Object.keys(moduleServiceConfig) as MServiceName[]).reduce(
    (prevVal, serviceName) => {
      const { serviceType, ...serviceOptions } =
        moduleServiceConfig[serviceName];
      const serviceFactory =
        serviceType === "RESTAPI"
          ? restapiServiceFactory
          : jsonapiServiceFactory;

      return {
        ...prevVal,
        [serviceName]:
          serviceFactory<MServiceTConfig[MServiceName]>(serviceOptions),
      };
    },
    {} as Partial<ReturnT>
  ) as ReturnT;
