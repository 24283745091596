import { useAppDispatch } from "@hooks/useAppDispatch";
import { postMessageActions } from "@src/redux/Message";
import { JsonapiResponseFetchManyMeta } from "@src/types";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import { useState, useEffect } from "react";

export type BroadcastSmsModalProps = {
  isOpen: boolean;
  unmountOnClose?: boolean;
  toggle: () => void;
  meta?: Partial<JsonapiResponseFetchManyMeta>;
  data?: ReservationListColumnNodeProps[] | null;
};

export const BroadcastSmsModal: React.FC<BroadcastSmsModalProps> = ({
  isOpen,
  unmountOnClose,
  toggle,
  meta,
  data,
}) => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    setMessage("");
  }, [isOpen]);

  const onSmsClick = async () => {
    await dispatch(
      postMessageActions.sendToMany({
        body: message,
      })
    );
    toggle();
  };

  const showFirstNumGuests = 4;
  const firstFewGuests = data
    ?.map((reservation) => reservation.mainGuestName)
    ?.slice(0, showFirstNumGuests);
  const omittedGuestsNumber =
    meta?.totalCount && meta?.totalCount > showFirstNumGuests
      ? meta?.totalCount - showFirstNumGuests
      : 0;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      unmountOnClose={unmountOnClose}
      className="modal-lg report-modal"
      centered={true}
    >
      <ModalHeader toggle={toggle}>Broadcast SMS</ModalHeader>
      <ModalBody>
        {!!meta?.totalCount ? (
          <>
            <div>
              You are about to <b>broadcast SMS</b> to reservations under:
            </div>
            <div>
              <ul>
                {firstFewGuests?.map((name, index) => (
                  <li key={`${name}-${index}`}>{name}</li>
                ))}
                {!!omittedGuestsNumber && (
                  <li>
                    ...and <b>{omittedGuestsNumber}</b> more
                  </li>
                )}
              </ul>
            </div>
            <div>
              <TextareaAutosize
                className="form-control"
                placeholder="Type your message here"
                style={{ resize: "none" }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </div>
          </>
        ) : (
          <>No reservations matching filter criteria</>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} outline>
          {!!meta?.totalCount ? "Cancel" : "OK"}
        </Button>
        {!!meta?.totalCount && (
          <Button color="primary" onClick={onSmsClick} disabled={!message}>
            Broadcast to {meta?.totalCount} reservations
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
