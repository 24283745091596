import { GuestEntity } from "@src/types";

export const guestDummyEntry: GuestEntity = {
  id: "",

  address1: "",
  address2: "",
  badges: [],
  birthday: "",
  city: "",
  country: "",
  customFields: {},
  email: "",
  firstName: "",
  gender: null,
  isVip: false,
  isCurrentlyStaying: false,
  language: "",
  lastName: "",
  lastStay: null,
  lastStayDate: "",
  notes: [],
  phone: "",
  pmsGuestId: "",
  state: "",
  title: "",
  totalSpent: 0,
  totalNights: 0,
  totalReservations: 0,
  zipCode: "",
};
