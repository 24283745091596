import {
  IconPlaceholder,
  PlaceholderRectShape,
  PlaceholderTextRow,
} from "@components/.";

export const ReservationCardSimplePlaceholder: React.FC = () => (
  <a style={{ pointerEvents: "none", cursor: "default" }}>
    <li className="suggestion-item suggestion-item-placeholder">
      <div>
        <div className="item-container d-flex ellipsis-wrapper">
          <div style={{ marginTop: "3px" }}>
            <IconPlaceholder size={17} />
          </div>
          <div className="item-info ml-1 d-flex flex-grow-1 ellipsis-wrapper">
            <div
              style={{
                width: "80px",
                minWidth: "80px",
                maxWidth: "80px",
              }}
            >
              <div className="align-middle mb-0">
                <PlaceholderRectShape
                  className="badge"
                  style={{
                    height: "25px",
                    borderRadius: "0.358rem",
                  }}
                />
              </div>
            </div>
            <div className="d-flex mx-1 ellipsis-wrapper">
              <div className="d-flex align-items-center ellipsis-wrapper">
                <div
                  className="mb-0 ellipsis"
                  style={{ fontSize: "calc(1.35 * 1rem)" }}
                >
                  <PlaceholderTextRow style={{ width: "150px" }} />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center ml-auto flex-grow-1 ellipsis-wrapper">
              <div
                className="align-middle mb-0 text-right ellipsis ml-auto"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "1.1rem",
                }}
              >
                <PlaceholderTextRow style={{ width: "130px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </a>
);
