import { SerializedError } from "@reduxjs/toolkit";
import {
  JsonapiErrorResponseData,
  ThunkServiceErrorExtended,
} from "@src/types";

export const createJsonapiAsyncThunkRejectedError = <
  T extends JsonapiErrorResponseData
>(
  payload: ThunkServiceErrorExtended<T> | undefined,
  error: SerializedError
) => {
  if (!payload) {
    return {
      message: error.message || "Something went wrong. We are working on it",
      code: error.code,
    };
  }

  const { message, response } = payload;

  return {
    message:
      response?.data?.errors?.[0].message ||
      response?.data?.errors?.[0].detail ||
      message,
    title: response?.data?.errors?.[0].title,
    code: response?.data?.errors?.[0].code || error.code,
    status: response?.status,
  };
};
