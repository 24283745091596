import "./NavbarSearch.scss";

import {
  GuestCardSimpleProps,
  NavbarAutocomplete,
  NavbarAutocompleteSuggestionGroup,
  NavbarSearchCustomItem,
  NavbarSuggestionComponentProps,
  ReservationCardSimpleProps,
} from "@components/.";
import { useAppDispatch, useAppSelector } from "@hooks/.";
import {
  __globalSearchGuestsActions,
  __globalSearchReservationsActions,
  __globalSetSearchQuery,
} from "@src/redux/__Global";
import { reservationsByIdsSelector } from "@src/redux/selectors";
import { GuestEntity } from "@src/types";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";
import classnames from "classnames";
import throttle from "lodash/throttle";
import { useEffect, useRef, useState, KeyboardEvent, MouseEvent } from "react";
import * as Icon from "react-feather";
import { useHotkeys } from "react-hotkeys-hook";
import { NavItem, NavLink } from "reactstrap";

export type NavbarSearchProps = {};

export const NavbarSearch: React.FC<NavbarSearchProps> = () => {
  const dispatch = useAppDispatch();

  const { query, data, guestsEntities, reservationEntities } = useAppSelector(
    (state) => {
      const {
        app: {
          __global: {
            search: { query, data },
          },
        },
      } = state;

      const guestsEntities: GuestEntity[] = [];
      const reservationEntities: ReservationListColumnNodeProps[] =
        reservationsByIdsSelector(state, data.reservation.ids) || [];

      data.guest.ids?.forEach((id) => {
        const entity = state.entities.guest.entities[id];

        if (entity) {
          guestsEntities.push(entity);
        }
      });

      return { query, guestsEntities, reservationEntities, data };
    }
  );

  const fnToThrottle = (query: string) => {
    dispatch(__globalSetSearchQuery(query));
  };

  const throttled = useRef(throttle(fnToThrottle, 300, { leading: false }));

  const [navbarSearch, setNavbarSearch] = useState(!!query);

  useEffect(() => {
    if (query) {
      const promises = [
        dispatch(__globalSearchGuestsActions.refetch()),
        dispatch(__globalSearchReservationsActions.refetch()),
      ];

      return () => promises.forEach((promise) => promise.abort());
    }
  }, [query]);

  useHotkeys("ctrl+f, command+f", (e) => {
    e.preventDefault();

    setNavbarSearch(true);
  });

  const handleClearQueryInStore = () => dispatch(__globalSetSearchQuery(""));

  const handleExternalClick = () => {
    if (navbarSearch) {
      setNavbarSearch(false);
      handleClearQueryInStore();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape" || e.key === "Enter") {
      setTimeout(() => {
        setNavbarSearch(false);

        handleClearQueryInStore();
      }, 1);
    }
  };

  const handleSuggestionItemClick = () => {
    setTimeout(() => {
      setNavbarSearch(false);

      handleClearQueryInStore();
    }, 1);
  };

  const handleListItemClick = (
    func: (link: string, e: React.MouseEvent<HTMLLIElement>) => void,
    link: string,
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    func(link, e);

    setTimeout(() => {
      setNavbarSearch(false);
    }, 1);
    handleClearQueryInStore();
  };

  const handleQueryChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    throttled.current(value);
  };

  const handleQueryClear: React.MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation();

    setNavbarSearch(false);

    handleClearQueryInStore();
  };

  const Suggestion: React.FC<NavbarSuggestionComponentProps> = ({
    item,
    filteredData,
    activeSuggestion,
    onSuggestionItemClick,
    onSuggestionItemHover,
  }) => (
    <a href={item.link} onClick={(e) => e.preventDefault()}>
      <li
        className={classnames("suggestion-item", {
          active: filteredData.indexOf(item) === activeSuggestion,
        })}
        onClick={(e) => {
          e.preventDefault();
          handleListItemClick(onSuggestionItemClick, item.link, e);
        }}
        onMouseEnter={() => {
          onSuggestionItemHover(filteredData.indexOf(item));
        }}
      >
        <NavbarSearchCustomItem {...item} />
      </li>
    </a>
  );

  const reservationToSuggestionMapper = ({
    id,
    confirmationNumber,
    mainGuestName,
    pmsStatus,
    checkInDate,
    checkOutDate,
  }: ReservationListColumnNodeProps): ReservationCardSimpleProps => ({
    type: "reservation",
    link: `/reservations/${id}`,
    id,
    confirmationNumber,
    mainGuestName,
    status: pmsStatus,
    checkInDate,
    checkOutDate,
  });

  const guestToSuggestionMapper = ({
    id,
    firstName,
    lastName,
    email,
    phone,
  }: GuestEntity): GuestCardSimpleProps => ({
    type: "guest",
    link: `/guests/${id}`,
    id,
    firstName,
    lastName,
    email,
    phone,
  });

  const suggestionGroups: NavbarAutocompleteSuggestionGroup[] = [
    {
      type: "reservation",
      groupTitle: "Reservations",
      searchLimit: 5,
      data: reservationEntities.map(reservationToSuggestionMapper),
      isLoading: data.reservation.isLoading,
    },
    {
      type: "guest",
      groupTitle: "Guests",
      searchLimit: 5,
      data: guestsEntities.map(guestToSuggestionMapper),
      isLoading: data.guest.isLoading,
    },
  ];

  const autocompleteElement = navbarSearch && (
    <NavbarAutocomplete
      className="form-control"
      suggestionGroups={suggestionGroups}
      placeholder="Explore OPS3..."
      autoFocus={true}
      onSuggestionItemClick={handleSuggestionItemClick}
      externalClick={handleExternalClick}
      onKeyDown={handleKeyDown}
      onChange={handleQueryChange}
      value={query}
      suggestionComponent={Suggestion}
    />
  );

  return (
    <NavItem className="nav-search" onClick={() => setNavbarSearch(true)}>
      <NavLink className="nav-link-search">
        <Icon.Search className="ficon" />
      </NavLink>
      <div
        className={classnames("search-input", {
          open: navbarSearch,
        })}
      >
        <div className="search-input-icon">
          <Icon.Search />
        </div>
        {autocompleteElement}
        <div className="search-input-close">
          <Icon.X className="ficon" onClick={handleQueryClear} />
        </div>
      </div>
    </NavItem>
  );
};
