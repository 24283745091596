import { useAppDispatch } from "@hooks/.";
import { AsyncThunkAction, EntityId } from "@reduxjs/toolkit";
import { ErrorNotFoundCard, ErrorStateAlert } from "@src/components";
import {
  AbstractEntity,
  AppState,
  JsonapiAsyncThunkConfig,
  StatusState,
} from "@src/types";
import { useEffect } from "react";

import { useAppSelector } from "./useAppSelector";

export const useEntityDetails = <
  ST extends AbstractEntity,
  ET extends AbstractEntity & ST
>(
  id: EntityId,
  {
    fetch,

    entitySelector,
    statusSelector,
  }: {
    fetch: (
      arg: EntityId
    ) => AsyncThunkAction<ST, EntityId, JsonapiAsyncThunkConfig>;

    entitySelector: (state: AppState, id: EntityId) => ET | undefined;
    statusSelector: (state: AppState, id: EntityId) => StatusState | undefined;
  }
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const promise = dispatch(fetch(id));

    return () => promise.abort();
  }, [id]);

  const [{ error, isLoading } = { error: null, isLoading: false }, data] =
    useAppSelector((state) => [
      statusSelector(state, id),
      entitySelector(state, id),
    ]);

  const errorElement =
    !isLoading && error ? (
      error.status === 404 ? (
        <ErrorNotFoundCard message={error.message} />
      ) : (
        <ErrorStateAlert error={error} />
      )
    ) : null;

  return { dispatch, data, error, errorElement, isLoading };
};
