import { ChartProps } from "@components/.";
import { formatNumberToStringWithCommasAndCurrency } from "@src/utility";
import { ApexOptions } from "apexcharts";

const monetaryFormatter = (value: number) =>
  formatNumberToStringWithCommasAndCurrency(value);

export const getChartOptions = (props: ChartProps): ApexOptions => {
  return {
    chart: {
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 8],
      width: [4, 2],
    },
    grid: {
      borderColor: props.labelColor,
    },
    legend: {
      show: false,
    },
    colors: [props.dangerLight, props.strokeColor],

    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        inverseColors: false,
        gradientToColors: [props.primary, props.strokeColor],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 0,
      hover: {
        size: 5,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: props.strokeColor,
        },
      },
      axisTicks: {
        show: false,
      },
      categories: props.categories,
      axisBorder: {
        show: false,
      },
      tickPlacement: "on",
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        style: {
          colors: props.strokeColor,
        },
        formatter: monetaryFormatter,
      },
    },
    tooltip: {
      x: { show: false },
    },
  };
};
