import "./ReservationReport.scss";

import { reservationReportColumnsConfig } from "@configs/Reservation";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  LayoutMeasuringStrategy,
  MouseSensor,
  TouchSensor,
  defaultDropAnimation,
  useSensor,
  useSensors,
  rectIntersection,
  ViewRect,
  RectEntry,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
// import { Transform } from "@dnd-kit/utilities";
import { reservationReportColumnNames } from "@src/constants/reservation";
import {
  ReservationListFilter,
  ReservationListFilterKey,
  ReservationListFilterPredefinedConfigKey,
  ReservationReportColumnName,
} from "@src/types";
import { deleteIdFromArray } from "@src/utility";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { ChevronsLeft, ChevronsRight } from "react-feather";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

import { ReservationReportColumnItem } from "./ReservationReportColumnItem";
import { ReservationReportColumnItemSortable } from "./ReservationReportColumnItemSortable";
import { ReservationReportDroppableCard } from "./ReservationReportDroppableCard";

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
};

// function restrictToBoundingRect(
//   transform: Transform,
//   rect: ViewRect,
//   boundingRect: ViewRect
// ): Transform {
//   const value = {
//     ...transform,
//   };

//   if (rect.top + transform.y <= boundingRect.top) {
//     value.y = boundingRect.top - rect.top;
//   } else if (
//     rect.bottom + transform.y >=
//     boundingRect.top + boundingRect.height
//   ) {
//     value.y = boundingRect.top + boundingRect.height - rect.bottom;
//   }

//   if (rect.left + transform.x <= boundingRect.left) {
//     value.x = boundingRect.left - rect.left;
//   } else if (
//     rect.right + transform.x >=
//     boundingRect.left + boundingRect.width
//   ) {
//     value.x = boundingRect.left + boundingRect.width - rect.right;
//   }

//   return value;
// }

export type ReportColumnContainer = "selected" | "available";

export type ReservationReportProps = {
  filterPredefinedConfig: {
    [K in ReservationListFilterPredefinedConfigKey]: {
      id: K;
      label: string;
      value: { [FK in ReservationListFilterKey]?: ReservationListFilter[FK] };
      defaultReportSelectedColumns: ReservationReportColumnName[];
    };
  };
  selectedFilterOptionId?: ReservationListFilterPredefinedConfigKey;
};

type Items = Record<ReportColumnContainer, ReservationReportColumnName[]>;
/**
 * @todo Implement modifiers for sortables to not escape reservation report
 */
export const ReservationReport = forwardRef<any, ReservationReportProps>(
  ({ filterPredefinedConfig, selectedFilterOptionId }, ref) => {
    const [selectedItem, setSelectedItem] =
      useState<ReservationReportColumnName | null>(null);

    const [items, setItems] = useState<Items>({
      selected: [],
      available: [],
    });

    const [reportName, setReportName] = useState<string>("");

    useImperativeHandle(
      ref,
      () => {
        return { reportName, items };
      },
      [items, reportName]
    );

    useEffect(() => {
      if (selectedFilterOptionId === null) {
        return;
      }

      const defaultSelectedColumns = selectedFilterOptionId
        ? filterPredefinedConfig[selectedFilterOptionId]
            .defaultReportSelectedColumns
        : [];

      setItems({
        selected: defaultSelectedColumns,
        available: reservationReportColumnNames.filter(
          (columnName) => defaultSelectedColumns.indexOf(columnName) < 0
        ),
      });
    }, [selectedFilterOptionId]);

    const containers = Object.keys(items) as ReportColumnContainer[];

    const [activeId, setActiveId] =
      useState<ReservationReportColumnName | null>(null);

    const recentlyMovedToNewContainer = useRef(false);

    const isSortingContainer = activeId
      ? containers.includes(activeId as ReportColumnContainer)
      : false;

    const [clonedItems, setClonedItems] = useState<Items | null>(null);

    // const [reportViewRect, setReportViewRect] = useState<ViewRect | null>(null);

    const sensors = useSensors(
      useSensor(MouseSensor),
      useSensor(TouchSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    const findContainer = (
      id: ReservationReportColumnName | ReportColumnContainer
    ): ReportColumnContainer | ReservationReportColumnName | undefined => {
      if (id in items) {
        return id as ReservationReportColumnName;
      }

      return containers.find((key) =>
        items[key].includes(id as ReservationReportColumnName)
      );
    };

    useEffect(() => {
      requestAnimationFrame(() => {
        recentlyMovedToNewContainer.current = false;
      });
    }, [items]);

    // const reservationReportElement = useRef<HTMLDivElement>(null);

    // const reservationReportElement = useCallback((node) => {
    //   if (node !== null) {
    //     const reservationReportElementRect = node.getBoundingClientRect();

    //     const reservationReportRect = {
    //       x: reservationReportElementRect.x,
    //       y: reservationReportElementRect.y,
    //       width: reservationReportElementRect.width,
    //       height: reservationReportElementRect.height,
    //       top: reservationReportElementRect.top,
    //       right: reservationReportElementRect.right,
    //       bottom: reservationReportElementRect.bottom,
    //       left: reservationReportElementRect.left,
    //       offsetLeft: node.offsetLeft,
    //       offsetTop: node.offsetTop,
    //     };

    //     // setReportViewRect(reservationReportRect);
    //   }
    // }, []);

    // const restrictToReservationReport: Modifier = ({
    //   transform,
    //   activeNodeRect,
    // }) => {
    //   if (!reservationReportElement) {
    //     console.log("No element");

    //     return transform;
    //   }

    //   if (!activeNodeRect || !reportViewRect) {
    //     return transform;
    //   }

    //   return restrictToBoundingRect(transform, activeNodeRect, reportViewRect);
    // };

    const handleSortableItemClick = (id: ReservationReportColumnName) => {
      const newSelectedValue = id === selectedItem ? null : id;
      setSelectedItem(newSelectedValue);
    };

    const handleDragStart = ({
      active,
    }: DragStartEvent & { active: { id: ReservationReportColumnName } }) => {
      setActiveId(active.id);
      setClonedItems(items);
    };

    const handleDragOver = ({
      active,
      over,
    }: DragOverEvent & { active: { id: ReservationReportColumnName } }) => {
      const overId = over?.id as ReservationReportColumnName | undefined;

      if (!overId || active.id in items) {
        return;
      }

      const overContainer = findContainer(overId) as ReportColumnContainer;
      const activeContainer = findContainer(active.id) as ReportColumnContainer;

      if (!overContainer || !activeContainer) {
        return;
      }

      if (activeContainer !== overContainer) {
        setItems((items) => {
          const activeItems = items[activeContainer];
          const overItems = items[overContainer];
          const overIndex = overItems.indexOf(overId);
          const activeIndex = activeItems.indexOf(active.id);

          let newIndex: number;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.offsetTop >
                over.rect.offsetTop + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex =
              overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          recentlyMovedToNewContainer.current = true;

          return {
            ...items,
            [activeContainer]: items[activeContainer].filter(
              (item) => item !== active.id
            ),
            [overContainer]: [
              ...items[overContainer].slice(0, newIndex),
              items[activeContainer][activeIndex],
              ...items[overContainer].slice(
                newIndex,
                items[overContainer].length
              ),
            ],
          };
        });
      }
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
      const activeContainer = findContainer(
        active.id as ReservationReportColumnName | ReportColumnContainer
      ) as ReportColumnContainer;

      if (!activeContainer) {
        setActiveId(null);
        return;
      }

      const overId = over?.id as ReservationReportColumnName | undefined;

      if (!overId) {
        setActiveId(null);
        return;
      }

      const overContainer = findContainer(overId) as ReportColumnContainer;

      if (overContainer) {
        const activeIndex = items[activeContainer].indexOf(
          active.id as ReservationReportColumnName
        );
        const overIndex = items[overContainer].indexOf(overId);

        if (activeIndex !== overIndex) {
          setItems((items) => ({
            ...items,
            [overContainer]: arrayMove(
              items[overContainer],
              activeIndex,
              overIndex
            ),
          }));
        }
      }

      setActiveId(null);
    };

    const handleDragCancel = () => {
      if (clonedItems) {
        setItems(clonedItems);
      }

      setActiveId(null);
      setClonedItems(null);
    };

    const dragOverlayPortal = createPortal(
      <DragOverlay
        className="reservation-report-drag-overlay"
        dropAnimation={dropAnimation}
        zIndex={2000}
        // modifiers={[restrictToReservationReport]}
      >
        {activeId && (
          <ReservationReportColumnItem dragOverlay>
            {reservationReportColumnsConfig[activeId].label}
          </ReservationReportColumnItem>
        )}
      </DragOverlay>,
      document.body
    );

    function customCollisionDetectionStrategy(
      rects: RectEntry[],
      rect: ViewRect
    ) {
      const intersectingRect = rectIntersection(rects, rect);
      return intersectingRect || rects[0][0];
    }

    return (
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        layoutMeasuring={{ strategy: LayoutMeasuringStrategy.Always }}
        collisionDetection={customCollisionDetectionStrategy}
      >
        <div className="reservation-report" id="reservation-report">
          <Row>
            <Col>
              <FormGroup>
                <Label for="reportName">Report name</Label>
                <Input
                  type="text"
                  name="name"
                  id="reportName"
                  placeholder="Type report name here..."
                  defaultValue={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="report-column-selector-wrapper">
            <Row className="report-column-selector">
              <div className="column droppable-card-column">
                <ReservationReportDroppableCard
                  id={"selected"}
                  label="Selected columns"
                  items={items["selected"]}
                  scrollable={true}
                >
                  <SortableContext
                    items={items["selected"]}
                    strategy={verticalListSortingStrategy}
                  >
                    {items["selected"].map((value, index) => (
                      <ReservationReportColumnItemSortable
                        disabled={isSortingContainer}
                        key={value}
                        id={value}
                        index={index}
                        selected={selectedItem === value}
                        onClick={handleSortableItemClick}
                      >
                        {reservationReportColumnsConfig[value].label}
                      </ReservationReportColumnItemSortable>
                    ))}
                  </SortableContext>
                </ReservationReportDroppableCard>
              </div>
              <div className="toggle-buttons-column">
                <Button
                  outline
                  disabled={
                    !selectedItem || items.available.indexOf(selectedItem) < 0
                  }
                  onClick={() => {
                    if (
                      selectedItem &&
                      items.available.indexOf(selectedItem) > -1
                    ) {
                      setItems({
                        available: deleteIdFromArray(
                          selectedItem,
                          items.available
                        ),
                        selected: [...items.selected, selectedItem],
                      });
                      setSelectedItem(null);
                    }
                  }}
                >
                  <ChevronsLeft size="18" />
                </Button>
                <Button
                  outline
                  disabled={
                    !selectedItem || items.selected.indexOf(selectedItem) < 0
                  }
                  onClick={() => {
                    if (
                      selectedItem &&
                      items.selected.indexOf(selectedItem) > -1
                    ) {
                      setItems({
                        selected: deleteIdFromArray(
                          selectedItem,
                          items.selected
                        ),
                        available: [...items.available, selectedItem],
                      });
                      setSelectedItem(null);
                    }
                  }}
                >
                  <ChevronsRight size="18" />
                </Button>
              </div>
              <div className="column droppable-card-column">
                <ReservationReportDroppableCard
                  id="available"
                  label="Available columns"
                  items={items["available"]}
                  scrollable={true}
                >
                  <SortableContext
                    items={items["available"]}
                    strategy={verticalListSortingStrategy}
                  >
                    {items["available"].map((value, index) => (
                      <ReservationReportColumnItemSortable
                        disabled={isSortingContainer}
                        key={value}
                        id={value}
                        index={index}
                        selected={selectedItem === value}
                        onClick={handleSortableItemClick}
                      >
                        {reservationReportColumnsConfig[value].label}
                      </ReservationReportColumnItemSortable>
                    ))}
                  </SortableContext>
                </ReservationReportDroppableCard>
              </div>
            </Row>
          </div>
          {dragOverlayPortal}
        </div>
      </DndContext>
    );
  }
);
