import { NoteListModal } from "..";

export type ReservationNotesListModalProps = {
  confirmationNumber: string;
  isOpen: boolean;
  notes: string[];

  toggle: () => void;
};

export const ReservationNotesListModal: React.FC<ReservationNotesListModalProps> =
  ({
    confirmationNumber: representableId,
    isOpen,
    notes: noteStrings,

    toggle,
  }) => {
    const notes = noteStrings.map((note, index) => ({
      id: `reservation-${representableId}-${index}`,
      content: note,
    }));

    const notesModalHeader = (
      <>
        Reservation{" "}
        <span className="font-weight-bolder">{representableId}</span> notes
      </>
    );

    return (
      <NoteListModal
        isOpen={isOpen}
        toggle={toggle}
        notes={notes}
        header={notesModalHeader}
      />
    );
  };
