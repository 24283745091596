import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@styles/react/libs/toastify/toastify.scss";
import "@assets/fonts/feather/iconfont.css";
import "@components/ripple-button";
import "@styles/core.scss";
import "react-placeholder/lib/reactPlaceholder.css";

import "./services/utils/axiosSetup";
import "./assets/scss/style.scss";

import { persistor, store } from "@store/storeConfig/store";
import Cypress from "cypress";
import ReactDOM from "react-dom";
import { Store } from "redux";

import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { AppState } from "./types";

declare global {
  interface Window {
    Cypress: Cypress.Cypress;
    __store__: Store<AppState>;
  }
}

ReactDOM.render(
  <App persistor={persistor} store={store} />,
  document.getElementById("root")
);

if (window.Cypress) {
  window.__store__ = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
