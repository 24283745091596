import { usePlaceholderColor } from "@hooks/usePlaceholderColor";
import { IconPlaceholder, PlaceholderTextRow } from "@src/components";
import { unitifySize } from "@src/utility";
import classnames from "classnames";

export type AvatarPlaceholderProps = {
  fullSize: boolean;
  iconSize?: number | string;
  rounded: boolean;
};

export const AvatarPlaceholder: React.FC<AvatarPlaceholderProps> = ({
  fullSize,
  iconSize,
  rounded = true,
}) => {
  const backgroundColor = usePlaceholderColor();

  const avatarIconDimension = unitifySize(iconSize);

  return (
    <>
      <div
        className={classnames("avatar", { rounded })}
        style={{ backgroundColor }}
      >
        <span
          className="avatar-content"
          style={{ height: avatarIconDimension, width: avatarIconDimension }}
        >
          <IconPlaceholder
            size={iconSize}
            style={{ backgroundColor: "none" }}
          />
        </span>
      </div>
      {fullSize && (
        <PlaceholderTextRow
          className="card-buttons-text align-middle"
          width={100}
          style={{
            marginTop: "0.8rem",
            marginBottom: "0.8rem",
            height: "1.2rem",
          }}
        />
      )}
    </>
  );
};
