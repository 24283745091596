import "./ReservationPreCheckinModal.scss";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { EntityId } from "@reduxjs/toolkit";
import { postOneReservationPreCheckInActions } from "@src/redux/Reservation";
import classnames from "classnames";
import { useEffect } from "react";
import { AlertTriangle, Loader } from "react-feather";
import { Alert, Button, Modal, ModalBody, ModalFooter } from "reactstrap";

export type ReservationPreCheckinModalProps = {
  data: { id: EntityId; confirmationNumber: string };
  guestId?: EntityId;
  toggle: () => void;
};

export const ReservationPreCheckinModal: React.FC<ReservationPreCheckinModalProps> =
  ({ data: { id, confirmationNumber }, guestId, toggle }) => {
    const dispatch = useAppDispatch();

    const { isLoading, error } = useAppSelector(
      (state) =>
        state.app.reservation.byIdPreCheckin[id] || {
          isLoading: false,
          error: null,
        }
    );

    useEffect(() => {
      dispatch(postOneReservationPreCheckInActions.resetStatus(id));
    }, [guestId]);

    const handlePreCheckinConfirm = () => {
      if (guestId) {
        dispatch(
          postOneReservationPreCheckInActions.fetch({
            id,
            guestId,
          })
        );
      }
    };

    return (
      <Modal
        className="reservation-precheckin-modal"
        isOpen={!!guestId}
        toggle={toggle}
        centered
      >
        <ModalBody>
          <Alert color="danger">
            <div className="alert-body">
              Are you sure you want to complete pre-checkin process for
              reservation <strong>{confirmationNumber}</strong>?
            </div>
          </Alert>
          {error && (
            <div className="d-flex text-center" style={{ color: "#ea5455" }}>
              <div className="m-2">
                <AlertTriangle size={24} />
              </div>
              <div className="d-flex flex-grow-1 flex-column align-content-center">
                <p>An error has occurred.</p>
                <p className="mb-0">
                  Please try again or contact with administrator.
                </p>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="outline" onClick={toggle}>
            No
          </Button>

          <Button
            className={classnames("btn-confirm", {
              "btn-loading": isLoading,
            })}
            color="danger"
            onClick={handlePreCheckinConfirm}
          >
            <Loader size={18} />
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
