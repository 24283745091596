import { ThemeConfig } from "@types";

const themeConfig: ThemeConfig = {
  app: {
    appName: "OPS 3",
    appLogoImage: {
      bode: {
        dark: require("../assets/images/logo/bodeops3-dark.png"), // null or image asset like this: require("../assets/images/logo/logo.svg"),
        light: require("../assets/images/logo/bodeops3-light.png"),
        compactDark: require("../assets/images/logo/bodeops3-dark-compact.png"),
        compactLight: require("../assets/images/logo/bodeops3-light-compact.png"),
      },
      drift: {
        dark: require("../assets/images/logo/driftops3-dark.png"), // null or image asset like this: require("../assets/images/logo/logo.svg"),
        light: require("../assets/images/logo/driftops3-light.png"),
        compactDark: require("../assets/images/logo/driftops3-dark-compact.png"),
        compactLight: require("../assets/images/logo/driftops3-light-compact.png"),
      },
    },
  },
  layout: {
    isRTL: false,
    skin: "light",
    routerTransition: "fadeIn",
    type: "vertical",
    contentWidth: "full",
    menu: {
      isHidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "floating",
      backgroundColor: "white",
    },
    footer: {
      type: "static",
    },
    customizer: false,
    scrollTop: true,
  },
};

export default themeConfig;
