import { EntityId } from "@reduxjs/toolkit";
import { AttachmentCard, AttachmentCardPlaceholder } from "@src/components";
import {
  attachmentEntitySelectors,
  attachmentPendingEntitySelectors,
} from "@src/redux/Attachment";
import {
  getOneReservationAttachmentsActions,
  postOneReservationAttachmentActions,
} from "@src/redux/Reservation";
import { useAppDispatch, useAppSelector } from "@src/utility/hooks/";
import { useEffect } from "react";
import ReactPlaceholder from "react-placeholder/lib";

export type ReservationAttachmentsCardProps = { id: EntityId };

export const ReservationAttachmentsCard: React.FC<ReservationAttachmentsCardProps> =
  ({ id }) => {
    const dispatch = useAppDispatch();

    const handleFilesDrop = (files: File[]) => {
      dispatch(postOneReservationAttachmentActions({ id, file: files[0] }));
    };

    const attachmentCardProps = useAppSelector((state) => {
      const statusState = state.app.reservation.byIdAttachments[id];

      const data = attachmentEntitySelectors.selectByIds(
        state,
        statusState?.ids
      );
      const pendingData = attachmentPendingEntitySelectors.selectByIds(
        state,
        statusState?.pendingIds || []
      );

      return {
        data,
        pendingData,
        statusState,
      };
    });

    useEffect(() => {
      const actionThunk = dispatch(
        getOneReservationAttachmentsActions.fetch(id)
      );

      return () => {
        actionThunk.abort();
      };
    }, [id]);

    return (
      <ReactPlaceholder
        ready={!!attachmentCardProps.data}
        customPlaceholder={<AttachmentCardPlaceholder />}
        showLoadingAnimation={true}
      >
        {!!attachmentCardProps.data && (
          <AttachmentCard
            {...attachmentCardProps}
            onFilesDrop={handleFilesDrop}
          />
        )}
      </ReactPlaceholder>
    );
  };
