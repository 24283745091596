import { EntityId, createAsyncThunk } from "@reduxjs/toolkit";
import { getManyMessagesByGuestService } from "@src/services/Message";
import {
  MessageEntity,
  JsonapiAsyncThunkConfig,
  JsonapiResponseFetchMany,
} from "@src/types";

const getManyMessagesByGuest = createAsyncThunk<
  JsonapiResponseFetchMany<MessageEntity>,
  EntityId,
  JsonapiAsyncThunkConfig
>(`messages/fetchByGuestId`, async (guestId, { rejectWithValue, signal }) => {
  try {
    return await getManyMessagesByGuestService({
      guestId,
      signal,
    });
  } catch (err) {
    return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
  }
});

export const getManyMessagesActions = {
  fetchByGuest: getManyMessagesByGuest,
};
