import "./TimelinePlaceholder.scss";

import { IconPlaceholder, PlaceholderTextRow } from "@src/components";
import { arrayOf3 } from "@src/constants";
import classnames from "classnames";

export const TimelinePlaceholder: React.FC = () => (
  <ul className="timeline timeline-placeholder">
    {arrayOf3.map((v, i) => (
      <li className="timeline-item" key={i}>
        <span className="timeline-point">
          <IconPlaceholder className="round" />
        </span>
        <div className="timeline-event">
          <div
            className={
              "d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            }
          >
            <PlaceholderTextRow
              width={100}
              className={"h6"}
              style={{ height: "1.6rem" }}
            />
            <PlaceholderTextRow
              className="timeline-event-time"
              width={70}
              style={{ height: "1.2rem", marginTop: "0.25rem" }}
            />
          </div>
          <div
            className={classnames({
              "mb-0": i === arrayOf3.length - 1,
            })}
          >
            <PlaceholderTextRow
              width={"100%"}
              style={{ height: "1.4rem", marginBottom: "0.25rem" }}
            />
            <PlaceholderTextRow width={"80%"} style={{ height: "1.4rem" }} />
          </div>
        </div>
      </li>
    ))}
  </ul>
);
