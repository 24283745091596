import { PrettyCellPlaceholder } from "@src/components";
import { EntityListTableConfig } from "@src/types";
import {
  BookedAt,
  CheckIn,
  CheckOut,
  LinkCol,
  MainGuest,
  PrettyCol,
  ReservationListColumnNodeProps,
  RightParagraph,
} from "@src/views/Reservation/reservationListColumnNodes";

export const reservationListAllTableConfig: EntityListTableConfig<
  ReservationListColumnNodeProps,
  "property.property_name"
> = {
  columns: {
    id: {
      name: "ID",
      selector: "id",
      omit: true,
    },
    confirmationNumber: {
      name: "Confirmation number",
      selector: "confirmationNumber",
      minWidth: "110px",
      cell: LinkCol,
    },
    bookedAt: {
      name: "Booked At",
      selector: "bookedAt",
      minWidth: "110px",
      cell: BookedAt,
      center: true,
      sortable: true,
    },
    checkInDate: {
      name: "Check In",
      selector: "checkInDate",
      minWidth: "110px",
      cell: CheckIn,
      center: true,
      sortable: true,
    },
    checkOutDate: {
      name: "Check Out",
      selector: "checkOutDate",
      minWidth: "110px",
      cell: CheckOut,
      center: true,
    },
    mainGuestName: {
      name: "Main guest",
      selector: "mainGuestName",
      cell: MainGuest,
      minWidth: "200px",
      center: true,
    },
    roomName: {
      name: "Room name",
      selector: "roomName",
      minWidth: "70px",
    },
    pmsStatus: {
      name: "Status",
      selector: "pmsStatus",
      cell: PrettyCol,
      /** @fixme Column size doesnt'n change after resize */
      maxWidth: window.innerWidth < 768 ? "50px" : undefined,
      minWidth: window.innerWidth > 768 ? "70px" : undefined,
      center: true,
    },
    priceTotal: {
      name: "Total Price",
      selector: "priceTotal",
      cell: RightParagraph,
      minWidth: "120px",
      right: true,
    },
  },
  placeholderOptions: {
    pmsStatus: { cell: PrettyCellPlaceholder },
  },
} as const;
