import "./ReservationCardPlaceholder.scss";

import { PlaceholderRectShape } from "@components/.";
import { DateRangePlaceholder, PlaceholderTextRow } from "@src/components";
import { Card, CardBody } from "reactstrap";

export const ReservationCardPlaceholder: React.FC = () => (
  <Card className="reservation-card-placeholder entity-card-placeholder reservation-card entity-card">
    <CardBody className="reservation-card-header entity-card-header">
      <div className="entity-card-header-toggler-wrapper">
        <div className="entity-card-header-content">
          <div className="entity-card-header-info">
            <div className="d-flex flex-row">
              <div className="reservation-badge-wrapper">
                <PlaceholderRectShape className="badge reservation-badge" />
              </div>

              <div className="reservation-card-header-guests-numbers">
                <div className="card-header-icon-wrapper">
                  <div className="adult-icon-text-wrapper icon-text-wrapper">
                    <PlaceholderRectShape />
                  </div>
                  <div className="svg">
                    <PlaceholderRectShape />
                  </div>
                </div>
                <div className="guests-children">
                  <div className="icon-text-wrapper">
                    <PlaceholderRectShape />
                  </div>
                  <div className="svg">
                    <PlaceholderRectShape />
                  </div>
                </div>
                <div className="card-header-icon-wrapper">
                  <div className="svg bed-icon">
                    <PlaceholderRectShape />
                  </div>
                  <div>
                    <PlaceholderRectShape />
                  </div>
                </div>
              </div>

              <div className="entity-card-header-actions">
                <div className="btn">
                  <PlaceholderRectShape />
                </div>
                <div className="btn">
                  <PlaceholderRectShape />
                </div>
              </div>
            </div>

            <div className="reservation-card-header-main-guest-wrapper">
              <span>
                <PlaceholderTextRow />
              </span>
            </div>

            <DateRangePlaceholder />
          </div>
        </div>
      </div>
    </CardBody>
  </Card>
);
