import { PlaceholderTextRow, TimelinePlaceholder } from "@src/components";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

export const TimelineCardPlaceholder: React.FC = () => (
  <Card>
    <CardHeader>
      <CardTitle>
        <PlaceholderTextRow width={90} style={{ height: "2rem" }} />
      </CardTitle>
    </CardHeader>
    <CardBody>
      <TimelinePlaceholder />
    </CardBody>
  </Card>
);
