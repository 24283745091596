import { useState } from "react";

export const useToggle = (initialIsTrue: boolean = false) => {
  const [isTrue, setIsTrue] = useState(initialIsTrue);

  const toggleIsTrue = () => {
    setIsTrue((isTrue) => !isTrue);
  };

  const setTrue = () => {
    setIsTrue(false);
  };
  const setFalse = () => {
    setIsTrue(false);
  };

  return [isTrue, toggleIsTrue, setTrue, setFalse, setIsTrue] as const;
};
