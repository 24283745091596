import "bs-stepper/dist/css/bs-stepper.min.css";
import "@src/@core/scss/base/plugins/forms/form-wizard.scss";

import "./GuestCard.scss";
import "./GuestCardPlaceholder.scss";

import { AvatarPlaceholder, PlaceholderTextRow } from "@components/.";
import { Card, CardBody, Col, Row } from "reactstrap";

export const GuestCardPlaceholder: React.FC = () => (
  <Card className="entity-card guest-card guest-card-placeholder entity-card-placeholder">
    <CardBody className="guest-card-header entity-card-header">
      <div className="entity-card-header-toggler-wrapper">
        <div className="entity-card-header-content">
          <div className="mr-1">
            <AvatarPlaceholder
              fullSize={false}
              rounded={false}
              iconSize="6rem"
            />
          </div>
          <div className="entity-card-header-info">
            <div
              className="align-items-start"
              style={{ display: "flex", height: "36px" }}
            >
              <div className="entity-card-header-name">
                <PlaceholderTextRow />
              </div>
            </div>
            <div className="entity-card-header-data-row-wrapper">
              <div className="entity-card-header-data-row ellipsis-wrapper">
                <PlaceholderTextRow />
              </div>
              <Row>
                <Col sm={4}>
                  <div className="entity-card-header-data-row ellipsis-wrapper">
                    <PlaceholderTextRow />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="entity-card-header-data-row ellipsis-wrapper">
                    <PlaceholderTextRow />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="entity-card-header-data-row ellipsis-wrapper">
                    <PlaceholderTextRow />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="entity-card-header-data-row ellipsis-wrapper">
                    <PlaceholderTextRow />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="entity-card-header-data-row ellipsis-wrapper">
                    <PlaceholderTextRow />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </CardBody>
  </Card>
);
