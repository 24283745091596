import { Currency } from "../types";

export const formatNumberToStringWithCommasAndCurrency = (
  param: number | string,
  currency: Currency = "USD",
  maximumFractionDigits: number = 0,
  /**
   * Due to unique Firefox behavior when `toLocaleString`
   * is used with maximumFractionDigits = 0, locales = "en-US"
   * and currency = "USD", minimumFractionDigits has to be
   * set to 0! In other way RangeError is thrown.
   */
  minimumFractionDigits: number = 0
) =>
  (+param).toLocaleString("en-US", {
    style: "currency",
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  });
