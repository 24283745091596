import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { RestapiAsyncThunkConfig } from "@src/types";

export const apiAsyncThunkFactory = <R, P, Name extends string>({
  name,
  serviceFn,
}: {
  name: Name;
  serviceFn: (payload: P) => Promise<R>;
}): AsyncThunk<R, P, RestapiAsyncThunkConfig> =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    try {
      return await serviceFn(payload);
    } catch (err) {
      return rejectWithValue(err as RestapiAsyncThunkConfig["rejectValue"]);
    }
  });
