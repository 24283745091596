import {
  ActionCreatorWithPayload,
  AsyncThunk,
  EntityId,
  createSlice,
} from "@reduxjs/toolkit";
import {
  AbstractEntity,
  JsonapiErrorResponseData,
  StatusState,
  ThunkServiceErrorExtended,
} from "@src/types";

import { createJsonapiAsyncThunkRejectedError } from "./createJsonapiAsyncThunkRejectedError";

export const createEntityStatusStateByIdSlice = <
  Name extends string,
  Entity extends AbstractEntity,
  FetchAsynkThunk extends AsyncThunk<
    any,
    EntityId,
    {
      rejectValue: ThunkServiceErrorExtended<JsonapiErrorResponseData>;
    }
  > = AsyncThunk<
    Entity,
    EntityId,
    {
      rejectValue: ThunkServiceErrorExtended<JsonapiErrorResponseData>;
    }
  >
>({
  name,
  initialState = {},
  fetch,
  resetStatus,
}: {
  name: Name;
  initialState?: Partial<Record<EntityId, StatusState>>;

  fetch: FetchAsynkThunk;
  resetStatus: ActionCreatorWithPayload<EntityId>;
}) =>
  createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetch.pending, (state, { meta }) => {
          state[meta.arg] = { isLoading: true, error: null };
        })
        .addCase(fetch.rejected, (state, { meta, payload, error }) => {
          if (error.name !== "AbortError") {
            state[meta.arg] = {
              isLoading: false,
              error: createJsonapiAsyncThunkRejectedError(payload, error),
            };
          }
        })
        .addCase(fetch.fulfilled, (state, { payload }) => {
          state[payload.id] = { isLoading: false, error: null };
        })
        .addCase(resetStatus, (state, { payload }) => {
          state[payload] = { isLoading: false, error: null };
        });
    },
  });
