import "./ReservationListReportPdfModal.scss";

import { ReservationReport } from "@src/components";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ReservationReportProps } from "..";
import { useRef } from "react";
import downloadAndSaveFile from "@src/utility/downloadReport";
import { apiConfig } from "@configs/apiConfig";
import { useAppSelector } from "@src/utility/hooks";
import { isEmpty } from "lodash";
import { camelToSnakeCase } from "@src/utility/camelToSnake";

export type ReservationListReportPdfModalProps = {
  isOpen: boolean;
  unmountOnClose?: boolean;
  toggle: () => void;
  documentType: "pdf" | "csv";
} & ReservationReportProps;

type ReportDataType = {
  reportName: string;
  items: { [key: string]: string[] };
};

export const ReservationListReportPdfModal: React.FC<ReservationListReportPdfModalProps> =
  ({ isOpen, unmountOnClose, documentType, toggle, ...reportProps }) => {
    const __globalFilter = useAppSelector((state) => state.app.__global.filter);
    const { filter, sort, page } = useAppSelector(
      (state) => state.app.reservation.allList
    );

    const reportRef = useRef<ReportDataType>({} as ReportDataType);

    const queryParams: string[] = [];

    (["pmsPropertyId"] as const).forEach((key) => {
      const filterValue = __globalFilter[key];

      if (filterValue) {
        queryParams.push(`filter[${key}]=${filterValue}`);
      }
    });

    (["bookedAtInPropertyTz", "search", "isVip"] as const).forEach((key) => {
      const filterValue = filter[key];

      if (typeof filterValue === "boolean") {
        queryParams.push(`filter[${key}]=${filterValue}`);
      } else if (filterValue) {
        queryParams.push(`filter[${key}]=${encodeURIComponent(filterValue)}`);
      }
    });

    (["pmsStatus", "checkInDateRange", "checkOutDateRange"] as const).forEach(
      (key) => {
        const filterValue = filter[key];

        if (filterValue && !isEmpty(filterValue)) {
          const qsFilterVal = [
            "checkInDateRange",
            "checkOutDateRange",
          ].includes(key)
            ? `[${filterValue
                .map((value) => (value === null ? "nil" : value))
                .join(",")}]`
            : filterValue.join(",");

          queryParams.push(`filter[${key}]=${qsFilterVal}`);
        }
      }
    );

    (["number", "size"] as const).forEach((k) => {
      queryParams.push(`page[${k}]=${page[k]}`);
    });

    if (sort.key) {
      queryParams.push(`sort=${sort.order === "DESC" ? "-" : ""}${sort.key}`);
    }

    const queryString = !isEmpty(queryParams)
      ? `?${queryParams.join("&")}`
      : "";

    const handleDownload = () => {
      const { selected } = reportRef.current.items;
      const { reportName } = reportRef.current;

      const queryColumns = selected.reduce(
        (acc, curr) => `${acc}&columns[]=${camelToSnakeCase(curr)}`,
        ""
      );

      const fileName = reportName || filter.pmsStatus?.[0] || "report";

      downloadAndSaveFile({
        url: `${apiConfig.baseUrl}/reports/reservations_report.${documentType}${queryString}&name=${fileName}${queryColumns}`,
        defaultFileName: fileName,
      }).then(toggle);
    };

    const documentTypeName = documentType.toUpperCase();

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        unmountOnClose={unmountOnClose}
        className="modal-lg report-modal"
        centered={true}
      >
        <ModalHeader toggle={toggle}>
          Download reservations report as {documentTypeName}
        </ModalHeader>
        <ModalBody>
          <ReservationReport {...reportProps} ref={reportRef} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} outline>
            Cancel
          </Button>
          <Button color="primary" onClick={handleDownload}>
            Download as {documentTypeName}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
