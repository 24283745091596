import { EntityId } from "@reduxjs/toolkit";
import { AuthServiceTConfig } from "@src/services/Auth/authServices";
import { ReservationServiceTConfig } from "@src/services/Reservation";
import { UserLoginPayload } from "@src/types";

export type ServiceTConfig = {
  auth: AuthServiceTConfig;
  reservation: ReservationServiceTConfig;
};

export const serviceConfig = {
  auth: {
    login: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      url: (arg: UserLoginPayload) => `/auth`,
      method: "POST",
      withCredentials: false,
      serviceType: "RESTAPI",
    },
    loginGoogle: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      url: (arg: { token: string }) => `/auth?provider=google`,
      method: "POST",
      withCredentials: false,
      serviceType: "RESTAPI",
    },
  },
  guest: {
    fetchMany: {
      url: () => `/guests`,
      method: "GET",
    },
    fetchOne: {
      url: (arg: EntityId) => `/guests/${arg}`,
      method: "GET",
      includes: ["reservations"],
    },
  },
  reservation: {
    fetchMany: {
      url: () => `/reservations`,
      method: "GET",
    },
    fetchOne: {
      url: (arg: EntityId) => `/reservations/${arg}`,
      method: "GET",
      includes: ["mainGuest", "guests"],
    },
  },
} as const;
