import { serviceConfig } from "@configs/serviceConfig";
import {
  SingleServiceTConfig,
  UserLoginPayload,
  UserLoginResponseData,
} from "@src/types";
import { AxiosResponse } from "axios";

import { moduleServicesFactory } from "../utils/moduleServicesFactory";

export type AuthServiceTConfig = {
  login: SingleServiceTConfig<
    AxiosResponse<UserLoginResponseData>,
    UserLoginPayload
  >;
  loginGoogle: SingleServiceTConfig<
    AxiosResponse<UserLoginResponseData>,
    { token: string }
  >;
};

export const authServices = moduleServicesFactory<AuthServiceTConfig>(
  serviceConfig.auth
);
