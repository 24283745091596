import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";
import { patchOneNotificationAsReadService } from "@src/services/Message";
import { JsonapiAsyncThunkConfig } from "@src/types";

const patchOneNotificationAsRead = createAsyncThunk<
  { data: { id: EntityId } },
  {
    id: EntityId;
    read?: boolean;
  },
  JsonapiAsyncThunkConfig
>(
  `notifications/patch/one`,
  async ({ id, read = true }, { rejectWithValue, signal }) => {
    try {
      return await patchOneNotificationAsReadService({
        signal,
        id,
        read,
      });
    } catch (err) {
      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  }
);

export const patchOneNotificationActions = {
  markAsRead: patchOneNotificationAsRead,
};
