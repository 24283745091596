import { Bell } from "react-feather";
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { useEffect, useState } from "react";
import { getManyNotificationsActions } from "@src/redux/Message";
import { getDefaultAvatar, getGravatarUrl, relativeDate } from "@src/utility";
import Avatar from "@components/avatar";
import { Link } from "react-router-dom";
import { EntityId } from "@reduxjs/toolkit";
import { patchOneNotificationActions } from "@src/redux/Message/patchOneNotificationActions";

export type NavbarNotificationsProps = {};

export const NavbarNotifications: React.FC<NavbarNotificationsProps> = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector(
    (state) => state.app.notifications.allList
  );
  const { isAgent } = useAppSelector((state) => state.app.auth.data);

  const playAudio = () => {
    const audioElement = document.getElementById(
      "notification-audio"
    ) as HTMLAudioElement;
    if (audioElement) {
      audioElement.play().catch(() => {});
    }
  };

  const fetchNotifications = async () => {
    dispatch(getManyNotificationsActions.fetch());
  };

  useEffect(() => {
    if (isAgent && notifications.length > 0) {
      playAudio();
    }
  }, [notifications]);

  useEffect(() => {
    fetchNotifications();
    const FETCH_INTERVAL_SECONDS = 60;
    const interval = setInterval(
      () => fetchNotifications(),
      1000 * FETCH_INTERVAL_SECONDS
    );
    return () => clearInterval(interval);
  }, []);

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  const handleOnNotificationClick = (notificationId: EntityId) => {
    if (isAgent) {
      dispatch(
        patchOneNotificationActions.markAsRead({
          id: notificationId,
        })
      );
    }
    setShowDropdown(false);
  };

  if (!isAgent) return null

  return (
    <Dropdown
      isOpen={showDropdown}
      toggle={toggleDropdown}
      className="dropdown-notification nav-item"
      tag="li"
    >
      <DropdownToggle
        tag="a"
        data-toggle="dropdown"
        aria-expanded={false}
        className="nav-link nav-link-label"
      >
        <audio
          id="notification-audio"
          src="/audio/software-interface-start.wav"
          preload="auto"
        ></audio>
        <Bell size={21} />
        {!!notifications.length && (
          <Badge pill color="danger" className="badge-up">
            {notifications.length}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu tag="ul" right className="dropdown-menu-media">
        <li className="dropdown-menu-header">
          <div className="d-flex dropdown-header mt-0">
            <h4 className="notification-title mb-0 mr-auto">Notifications</h4>
            {!!notifications.length && (
              <Badge pill color="danger">
                {notifications.length} New
              </Badge>
            )}
          </div>
        </li>
        <PerfectScrollbar
          className="media-list overflow-hidden position-relative"
          options={{
            wheelPropagation: false,
          }}
        >
          {!!notifications.length ? (
            notifications.map((notification: any) => (
              <Link
                to={`/guests/${notification?.guestId}`}
                key={notification.id}
                className="d-flex justify-content-between"
                onClick={() => handleOnNotificationClick(notification.id)}
              >
                <Media className="d-flex align-items-start">
                  <Media left>
                    <Avatar
                      img={
                        notification?.authorEmail
                          ? getGravatarUrl(notification?.authorEmail)
                          : getDefaultAvatar(null)
                      }
                      imgClassName="guest-image"
                      fallbackSrc={getDefaultAvatar(null)}
                      imgHeight="37"
                      imgWidth="37"
                    />
                  </Media>
                  <Media body>
                    <Media heading className="primary media-heading d-flex">
                      <span
                        style={{ fontSize: "1rem" }}
                        className="mr-auto mb-0"
                      >
                        {notification?.authorName}
                      </span>
                      <small className="mr-0">
                        <time
                          className="media-meta"
                          dateTime="2015-06-11T18:29:20+08:00"
                        >
                          {relativeDate(notification?.sentAt)}
                        </time>
                      </small>
                    </Media>
                    <small className="notification-text">
                      {notification?.body}
                    </small>
                  </Media>
                </Media>
              </Link>
            ))
          ) : (
            <div className="m-2 text-center">You have no new notifications</div>
          )}
        </PerfectScrollbar>
        {false && (
          <li className="dropdown-menu-footer">
            <DropdownItem tag="a" className="p-1 text-center">
              Read all notifications
            </DropdownItem>
          </li>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
