import { ConversationEntity } from "@src/types";

export const messageDummyEntry: ConversationEntity = {
  id: -1,
  authorId: -2,
  authorName: "John Smith",
  authorType: "Guest",
  body: "Hello world",
  guestId: -3,
  guestName: "Jogn Smith",
  guestPhone: "1234567890",
  pmsPropertyId: "606",
  propertyName: "Bode Nashville",
  read: false,
  receivedAt: "",
  receivedMessageRead: false,
  respondAt: "",
  sentAt: "",
};
