import { ErrorState } from "@src/types";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export type ReservationKeyCodeModalProps = {
  isOpen: boolean;
  toggle: () => void;
  error?: ErrorState;
};

export const ErrorModal: React.FC<ReservationKeyCodeModalProps> = ({
  isOpen,
  toggle,
  error,
}) => {
  const errorText = error?.title || "Internal Server Error";
  const payloadBody = error?.message;
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Error</ModalHeader>
      <ModalBody>
        <div>{errorText}</div>
        {payloadBody && (
          <div className="mt-2 ml-2">
            <div>Your message:</div>
            <div>
              <code>{payloadBody}</code>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
