import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import axios from "axios";

export const postReservationPreCheckInService = async ({
  arg: { id, ...arg },
  signal,
}: {
  arg: { id: EntityId; guestId: EntityId };
  signal: AbortSignal;
}) => {
  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  await axios.request({
    method: "POST",
    url: `${apiConfig.baseUrl}/reservations/${id}/pre_check_in_completion`,
    headers: { Accept: "application/vnd.api+json" },
    withCredentials: true,
    cancelToken: source.token,
    data: arg,
  });
};
