export const isDateToday = (dateString: string) => {
  const comparedDate = new Date(dateString);
  const today = new Date();

  return (
    comparedDate.getDate() === today.getDate() &&
    comparedDate.getMonth() === today.getMonth() &&
    comparedDate.getFullYear() === today.getFullYear()
  );
};
