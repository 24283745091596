import { EntityId, createAsyncThunk } from "@reduxjs/toolkit";
import { postReservationAttachmentService } from "@src/services/Reservation/postReservationAttachmentsService";
import { AttachmentEntity, JsonapiAsyncThunkConfig } from "@src/types";

export const postOneReservationAttachmentActions = createAsyncThunk<
  AttachmentEntity,
  { id: EntityId; file: File },
  JsonapiAsyncThunkConfig
>(
  `reservation/one/attachments/post`,
  async (arg, { rejectWithValue, signal }) => {
    try {
      return await postReservationAttachmentService({
        arg,
        signal,
      });
    } catch (err) {
      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  }
);
