import { useToggle } from "@hooks/useToggle";
import { JsonapiSort } from "@src/types";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type EntityListSortDropdownProps<
  SortKey extends string,
  SortConfigKey extends string,
  SortConfig extends {
    [SCK in SortConfigKey]: {
      id: SCK;
      label: string;
      value: JsonapiSort<SortKey>;
    };
  }
> = {
  sort: JsonapiSort<SortKey>;
  sortConfig: SortConfig;
  setSort: (sort: JsonapiSort<SortKey>) => void;
};

export const EntityListSortDropdown = <
  SortKey extends string,
  SortConfigKey extends string,
  SortConfig extends {
    [SCK in SortConfigKey]: {
      id: SCK;
      label: string;
      value: JsonapiSort<SortKey>;
    };
  }
>({
  sort,
  sortConfig,
  setSort,
}: EntityListSortDropdownProps<
  SortKey,
  SortConfigKey,
  SortConfig
>): JSX.Element => {
  const [isDropdownOpen, toggleDropdown] = useToggle();

  const sortConfigKeys = Object.keys(sortConfig) as SortConfigKey[];

  const selectedSortOption = sortConfigKeys
    .map((indexName) => sortConfig[indexName])
    .find(
      ({ value: { key, order } }) => key === sort?.key && order === sort?.order
    );

  const dropdownItemElements = sortConfigKeys.map((key) => {
    const handleClick = () => {
      if (key !== selectedSortOption?.id) {
        setSort(sortConfig[key].value);
      }
    };

    return (
      <DropdownItem onClick={handleClick} key={key}>
        <span className="align-middle">{sortConfig[key].label}</span>
      </DropdownItem>
    );
  });

  return (
    <ButtonDropdown
      className="entity-list-sort-dropdown "
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        className="ellipsis-wrapper"
        color="secondary"
        outline={true}
        caret
      >
        <span
          className="align-middle ellipsis d-inline-block"
          style={{ maxWidth: "100%" }}
        >
          {selectedSortOption?.label}
        </span>
      </DropdownToggle>
      <DropdownMenu>{dropdownItemElements}</DropdownMenu>
    </ButtonDropdown>
  );
};
