import { EntityId } from "@reduxjs/toolkit";
import { requestStatusSelectors } from "@src/redux/RequestStatus";
import { AppState, AttachmentEntity, StatusState } from "@src/types";

import { attachmentPendingEntityAdapter } from ".";

const selectors = attachmentPendingEntityAdapter.getSelectors<AppState>(
  (state) => state.pendingEntities.attachment
);

const selectByIds = (state: AppState, ids: EntityId[]) => {
  const returnData: {
    data: Omit<AttachmentEntity, "url">;
    status: StatusState;
  }[] = [];

  ids.forEach((id) => {
    const entity = selectors.selectById(state, id);

    const status = requestStatusSelectors.selectById(state, id);

    if (entity && status) {
      returnData.push({ data: entity, status });
    }
  });

  return returnData;
};

export const attachmentPendingEntitySelectors = {
  ...selectors,
  selectByIds,
};
