import { arrayOf50 } from "@src/constants";
import {
  AbstractEntity,
  EntityListTableConfig,
  JsonapiSort,
  JsonapiSortOrder,
} from "@src/types";
import { createEntityListTablePlaceholderConfig } from "@src/utility";
import classnames from "classnames";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { ChevronDown } from "react-feather";
import ReactPlaceholder from "react-placeholder";
import { Card, CardBody, Col, Row } from "reactstrap";

export type EntityListTableViewProps<
  EK extends string,
  T extends AbstractEntity,
  SK extends keyof T
> = {
  className?: string;
  data?: T[] | null;
  isLoading: boolean;
  noDataNode: React.ReactNode;
  placeholderDummyEntry: T;
  sort?: JsonapiSort<SK>;
  tableConfig: EntityListTableConfig<T, EK>;

  onRowClicked?: (row: T) => void;
  onSort?: (sort: JsonapiSort<SK>) => void;
};

export const EntityListTableView = <
  EK extends string,
  T extends AbstractEntity,
  SK extends keyof T & string = keyof T & string
>({
  className = "",
  data,
  noDataNode,
  placeholderDummyEntry,
  sort,
  tableConfig,
  onRowClicked,
  onSort,
}: EntityListTableViewProps<EK, T, SK>) => {
  const placeholderTableConfig =
    createEntityListTablePlaceholderConfig(tableConfig);

  const placeholderData: T[] = arrayOf50.map((el, i) => ({
    ...placeholderDummyEntry,
    id: i,
  }));

  const handleSort = (
    { selector }: IDataTableColumn<T>,
    order: "desc" | "asc"
  ) => {
    if (selector) {
      onSort?.({
        key: selector as SK,
        order: order.toUpperCase() as JsonapiSortOrder,
      });
    }
  };

  const customPlaceholder = (
    <Card>
      <CardBody>
        <DataTable
          className="react-dataTable"
          data={placeholderData}
          columns={Object.values(placeholderTableConfig)}
          noHeader
          sortIcon={<ChevronDown size={10} />}
          defaultSortField={sort?.key}
          defaultSortAsc={sort?.order === "ASC"}
          sortServer
        />
      </CardBody>
    </Card>
  );

  const content =
    data && data.length === 0 ? (
      noDataNode
    ) : (
      <Card>
        <CardBody className="booking-list-card-table">
          <DataTable
            className={classnames("react-dataTable", className)}
            data={data || placeholderData}
            columns={
              data
                ? Object.values(tableConfig.columns)
                : Object.values(placeholderTableConfig)
            }
            noHeader
            sortIcon={<ChevronDown size={10} />}
            onRowClicked={onRowClicked}
            onSort={handleSort}
            defaultSortField={sort?.key}
            defaultSortAsc={sort?.order === "ASC"}
            sortServer
          />
        </CardBody>
      </Card>
    );

  return (
    <Row>
      <Col>
        <ReactPlaceholder
          ready={!!data}
          customPlaceholder={customPlaceholder}
          showLoadingAnimation
        >
          {content}
        </ReactPlaceholder>
      </Col>
    </Row>
  );
};
