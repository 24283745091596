import "./Attachment.scss";

import { AttachmentEntity, Override } from "@src/types";
import { getFileExtension } from "@src/utility";
import classnames from "classnames";
import { FileText, Image } from "react-feather";

export type AttachmentProps = Override<
  AttachmentEntity,
  Pick<Partial<AttachmentEntity>, "id" | "url">
>;

export const Attachment: React.FC<AttachmentProps> = ({ filename, url }) => {
  const extension = getFileExtension(filename);

  const icon =
    extension &&
    ["png", "jpg", "jpeg", "gif", "tiff"].indexOf(extension) > -1 ? (
      <Image size="24" />
    ) : (
      <FileText size="24" />
    );

  return (
    <p className="attachment ellipsis-wrapper align-items-center">
      {icon}
      <a
        className={classnames("ellipsis small", {
          "text-underline-hover": url,
        })}
        href={url || "javascript:void(0)"}
        download={filename}
        target="_blank"
        rel="noopener norefferer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {filename}
      </a>
    </p>
  );
};
