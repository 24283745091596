import "./StatisticsCard.scss";

import classnames from "classnames";
import { useState } from "react";
import Chart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";

export type StatisticsCardProps =
  | {
      className?: string;
      hideChart: true;
      iconRight?: boolean;
      iconBg?: string;
      icon: React.ReactNode;
      stat: string;
      statTitle: string;
      options?: undefined;
      series?: undefined;
      type?:
        | "line"
        | "area"
        | "bar"
        | "histogram"
        | "pie"
        | "donut"
        | "rangeBar"
        | "radialBar"
        | "scatter"
        | "bubble"
        | "heatmap"
        | "candlestick"
        | "radar"
        | "polarArea";
      height?: string;
      onClick?: () => void;

      statDataCy?: string;
    }
  | {
      className?: string;
      hideChart?: false | undefined;
      iconRight?: boolean;
      iconBg?: string;
      icon: React.ReactNode;
      stat: string;
      statTitle: string;
      options?: any;
      series?: any;
      type?:
        | "line"
        | "area"
        | "bar"
        | "histogram"
        | "pie"
        | "donut"
        | "rangeBar"
        | "radialBar"
        | "scatter"
        | "bubble"
        | "heatmap"
        | "candlestick"
        | "radar"
        | "polarArea";
      height?: string;
      onClick?: () => void;

      statDataCy?: string;
    };

export const StatisticsCard: React.FC<StatisticsCardProps> = (props) => {
  // ** Props
  const {
    className,
    hideChart,
    iconRight,
    iconBg = "primary",
    icon,
    stat,
    statTitle,
    options,
    series,
    type,
    height,
    onClick,

    statDataCy,
  } = props;

  const [isHover, setIsHover] = useState(false);

  const handleOnMouseEnter: React.MouseEventHandler<HTMLElement> = () => {
    if (onClick) {
      setIsHover(true);
    }
  };

  const handleOnMouseLeave: React.MouseEventHandler<HTMLElement> = () => {
    if (onClick) {
      setIsHover(false);
    }
  };

  return (
    <Card
      onClick={onClick}
      className={classnames("stats-card", {
        "cursor-pointer": !!onClick,
        "shadow-lg": isHover,
      })}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <CardBody
        className={classnames("pt-2", className, {
          "flex-column align-items-start": !iconRight && !hideChart,
          "justify-content-between flex-row-reverse align-items-center":
            iconRight,
          "justify-content-center flex-column text-center":
            hideChart && !iconRight,
          "pb-0": !hideChart,
          "pb-2": hideChart,
        })}
      >
        <div className="icon-section">
          <div
            className={classnames(
              `icon-container rounded-circle p-50 bg-light-${iconBg}`
            )}
          >
            <div className="icon-wrapper  rounded-circle">{icon}</div>
          </div>
        </div>
        <div className="title-section">
          <h2 className="font-weight-bold mt-1 mb-25" data-cy={statDataCy}>
            {stat}
          </h2>
          <p className="mb-0">{statTitle}</p>
        </div>
      </CardBody>
      {!hideChart && (
        <Chart
          options={options}
          series={series}
          type={type}
          height={height || 100}
        />
      )}
    </Card>
  );
};

export default StatisticsCard;
