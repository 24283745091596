import {
  Draft,
  EntityId,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { statusStateInit } from "@src/constants/statusStateInit";
import {
  AbstractListFilter,
  JsonapiErrorResponseData,
  JsonapiPayloadActionFetchMany,
  JsonapiSortOrder,
  ListStatusState,
  ListView,
  PageSize,
  ThunkServiceErrorExtended,
} from "@src/types";

import { createJsonapiAsyncThunkRejectedError } from "./createJsonapiAsyncThunkRejectedError";

export const asyncThunkListStatusReducers = {
  pending: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>
  ) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>,
    {
      payload,
      error,
    }: {
      error: SerializedError;
      payload: /** @fixme Investigate proper error object schema */
      ThunkServiceErrorExtended<JsonapiErrorResponseData> | undefined;
    }
  ) => {
    if (error.name !== "AbortError") {
      state.isLoading = false;
      state.error = createJsonapiAsyncThunkRejectedError(payload, error);
    }
  },
  fulfilled: <
    T extends { id: EntityId },
    SK extends string,
    F extends AbstractListFilter
  >(
    state: Draft<ListStatusState<SK, F>>,
    {
      payload: {
        data,
        meta: { totalCount, totalPages },
      },
    }: JsonapiPayloadActionFetchMany<T>
  ) => {
    state.isLoading = false;
    state.error = null;
    state.ids = data.map(({ id }) => id);
    state.meta.totalCount = totalCount;
    state.meta.totalPages = totalPages;
  },
  resetStatus: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>
  ) => {
    state.isLoading = statusStateInit.isLoading;
    state.error = statusStateInit.error;
  },
  setView: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>,
    { payload }: PayloadAction<ListView>
  ) => {
    state.view = payload;
  },
  setFilter: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>,
    { payload }: PayloadAction<Partial<F>>
  ) => {
    state.filter = payload as Draft<Partial<F>>;
    state.ids = undefined;
    state.meta.totalCount = undefined;
    state.meta.totalPages = undefined;
    state.page.number = 1;
  },
  resetFilter: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>
  ) => {
    state.filter = {} as Draft<Partial<F>>;
    state.ids = undefined;
    state.meta.totalCount = undefined;
    state.meta.totalPages = undefined;
    state.page.number = 1;
  },
  setPageSize: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>,
    { payload }: PayloadAction<PageSize>
  ) => {
    state.page.number = 1;
    state.page.size = payload;
    state.ids = undefined;
    state.meta.totalCount = undefined;
    state.meta.totalPages = undefined;
  },
  setPageNumber: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>,
    { payload }: PayloadAction<number>
  ) => {
    state.ids = undefined;
    state.page.number = payload;
  },
  setSort: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>,
    action: PayloadAction<{ key: SK; order: JsonapiSortOrder }>
  ) =>
    ({
      ...state,
      ids: undefined,
      page: { ...state.page, number: 1 },
      sort: action.payload,
    } as Draft<ListStatusState<SK, F>>),
  setSortKey: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>,
    action: PayloadAction<SK>
  ) => ({
    ...state,
    ids: undefined,
    page: { ...state.page, number: 1 },
    sort: { key: action.payload, order: "ASC" },
  }),
  setSortOrder: <SK extends string, F extends AbstractListFilter>(
    state: Draft<ListStatusState<SK, F>>,
    { payload }: PayloadAction<JsonapiSortOrder>
  ) => {
    state.sort.order = payload;
    state.ids = undefined;
  },
  __setGlobalFilterPmsPropertyId: <
    SK extends string,
    F extends AbstractListFilter
  >(
    state: Draft<ListStatusState<SK, F>>
  ) => {
    state.ids = undefined;
    state.meta.totalCount = undefined;
    state.meta.totalPages = undefined;
    state.page.number = 1;
  },
};
