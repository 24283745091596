import { createSlice } from "@reduxjs/toolkit";

interface ErrorState {
  url: string | null;
}

const initialState = { url: null } as ErrorState;

export const errorSlice = createSlice({
  name: "errors/setEndpoint",
  initialState,
  reducers: {
    setUrl(state, action) {
      state.url = action.payload;
    },
  },
});
