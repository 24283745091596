import "@styles/base/bootstrap-extended/_include.scss";

import "./NavbarAutocomplete.scss";

import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { EntityId } from "@reduxjs/toolkit";
import classnames from "classnames";
import {
  ChangeEvent,
  ComponentType,
  KeyboardEvent,
  FocusEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
  FC,
} from "react";
import ReactDOM from "react-dom";
import * as Icon from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactPlaceholder from "react-placeholder";
import { useHistory } from "react-router-dom";

import {
  NavbarSearchCustomItemPlaceholder,
  NavbarSearchCustomItemProps,
} from "..";

export type NavbarAutocompleteSuggestion = NavbarSearchCustomItemProps;

export type NavbarAutocompleteSuggestionGroup = {
  type: "guest" | "reservation";
  groupTitle: string;
  searchLimit: number;
  data: NavbarAutocompleteSuggestion[];
  isLoading: boolean;
};

export type NavbarOnSuggestionItemClick = (
  url: string,
  e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLInputElement>
) => void;

export type NavbarSuggestionComponentProps = {
  item: NavbarAutocompleteSuggestion;
  filteredData: any;
  activeSuggestion: number;
  onSuggestionItemClick: NavbarOnSuggestionItemClick;
  onSuggestionItemHover: (index: number) => void;
  userInput?: string;
};

export type NavbarSuggestionNodeProps<T extends string, P extends {}> = {
  id: EntityId;
  iconComponent?: ComponentType;
  link: string;
  type: T;
} & P;

export type NavbarAutocompleteProps = {
  autoFocus?: boolean;
  className?: string;
  placeholder?: string;
  suggestionGroups: NavbarAutocompleteSuggestionGroup[];
  value?: string;
  wrapperClass?: string;

  suggestionComponent: ComponentType<NavbarSuggestionComponentProps>;

  externalClick?: () => void;

  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>, userInput: string) => void;
  onSuggestionItemClick?: NavbarOnSuggestionItemClick;
};

export const NavbarAutocomplete: FC<NavbarAutocompleteProps> = (props) => {
  const {
    autoFocus,
    className,
    placeholder,
    suggestionGroups,
    value = "",
    wrapperClass,

    suggestionComponent: SuggestionComponent,

    externalClick,

    onBlur,
    onChange,
    onKeyDown,
    onSuggestionItemClick,
  } = props;

  const container = useRef<HTMLDivElement>(null);
  const inputElRef = useRef<HTMLInputElement>(null);
  const suggestionsListRef = useRef<PerfectScrollbar>(null);

  const [focused, setFocused] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(value);

  const history = useHistory();

  const filteredData: NavbarAutocompleteSuggestion[] = userInput
    ? suggestionGroups.reduce((prevVal, suggestion) => {
        prevVal.push(...suggestion.data);

        return prevVal;
      }, [] as NavbarAutocompleteSuggestion[])
    : [];

  useEffect(() => {
    setShowSuggestions(!!value?.length);
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      inputElRef.current?.focus();
    }
  }, [setShowSuggestions, focused, userInput, showSuggestions, props]);

  const handleSuggestionItemClick: NavbarOnSuggestionItemClick = (url, e) => {
    setActiveSuggestion(0);
    setShowSuggestions(false);
    setUserInput("");

    history.push(url);

    onSuggestionItemClick?.(url, e);
  };

  const handleSuggestionHover = (index: number) => {
    setActiveSuggestion(index);
  };

  const handleInputChange = ({
    currentTarget: { value: currentTargetValue },
  }: ChangeEvent<HTMLInputElement>) => {
    setActiveSuggestion(0);

    if (!currentTargetValue.length) {
      setShowSuggestions(false);
    }

    setUserInput(currentTargetValue);
  };

  const handleInputClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const suggestionList = ReactDOM.findDOMNode(suggestionsListRef.current);

    if (e.key === "ArrowUp" && activeSuggestion !== 0) {
      setActiveSuggestion(activeSuggestion - 1);

      if (
        e.currentTarget.value.length > -1 &&
        suggestionList instanceof Element &&
        activeSuggestion <= filteredData.length / 2
      ) {
        suggestionList.scrollTop = 0;
      }
    } else if (
      e.key === "ArrowDown" &&
      activeSuggestion < filteredData.length - 1
    ) {
      setActiveSuggestion(activeSuggestion + 1);

      if (
        e.currentTarget.value.length > -1 &&
        suggestionList instanceof Element &&
        activeSuggestion >= filteredData.length / 2
      ) {
        suggestionList.scrollTop = suggestionList.scrollHeight;
      }
    } else if (e.key === "Escape") {
      setShowSuggestions(false);
      setUserInput("");
    } else if (e.key === "Enter" && showSuggestions) {
      handleSuggestionItemClick(filteredData[activeSuggestion].link, e);
      setUserInput("");
      setShowSuggestions(false);
    }

    onKeyDown?.(e, userInput);
  };

  useOnClickOutside(container, () => {
    setShowSuggestions(false);

    externalClick?.();
  });

  const SuggestionGroup: FC<NavbarAutocompleteSuggestionGroup> = (
    suggestion
  ) => (
    <div key={`type-${suggestion.type}`}>
      <li className="suggestion-item suggestion-title-wrapper">
        <h6 className="suggestion-title">{suggestion.groupTitle}</h6>
      </li>
      <ReactPlaceholder
        ready={!suggestion.isLoading && !!suggestion.data}
        showLoadingAnimation
        customPlaceholder={
          <NavbarSearchCustomItemPlaceholder type={suggestion.type} />
        }
      >
        {suggestion.data.length ? (
          suggestion.data.map((item, i) => (
            <SuggestionComponent
              key={`suggestion-${item.type}-${item.id}-${i}`}
              {...{
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick: handleSuggestionItemClick,
                onSuggestionItemHover: handleSuggestionHover,
                userInput,
              }}
            />
          ))
        ) : (
          <li className="suggestion-item no-result">
            <div className="d-flex">
              <div>
                <Icon.AlertCircle size={17} />
              </div>
              <div className="flex-grow-1 text-center">
                <span className="align-middle ml-50">No Results.</span>
              </div>
            </div>
          </li>
        )}
      </ReactPlaceholder>
    </div>
  );

  const suggestionGroupElements = suggestionGroups.map(SuggestionGroup);

  const suggestionsListComponent = showSuggestions && (
    <PerfectScrollbar
      className={classnames(`suggestions-list`, wrapperClass)}
      ref={suggestionsListRef}
      component="ul"
      options={{ wheelPropagation: false }}
    >
      {suggestionGroupElements}
    </PerfectScrollbar>
  );

  return (
    <div className="autocomplete-container" ref={container}>
      <input
        type="text"
        onChange={(e) => {
          handleInputChange(e);
          onChange?.(e);
        }}
        onKeyDown={handleInputKeyDown}
        value={userInput}
        className={classnames(`autocomplete-search`, className)}
        placeholder={placeholder}
        onClick={handleInputClick}
        ref={inputElRef}
        onFocus={() => setFocused(true)}
        autoFocus={props.autoFocus}
        onBlur={(e) => {
          onBlur?.(e);
          setFocused(false);
        }}
      />

      {suggestionsListComponent}
    </div>
  );
};
