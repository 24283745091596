import { GuestNote } from "@src/types";

import { NoteListModal } from "..";

export type GuestNotesListModalProps = {
  pmsGuestId: string;
  name: string;
  notes: GuestNote[];

  isOpen: boolean;

  toggle: () => void;
};

export const GuestNotesListModal: React.FC<GuestNotesListModalProps> = ({
  pmsGuestId,
  isOpen,
  notes: guestNotes,
  name,

  toggle,
}) => {
  const notes = guestNotes.map((guestNote, i) => ({
    id: `guest-${pmsGuestId}-${i}`,
    content: guestNote.guest_note,
  }));

  const notesModalHeader = (
    <>
      Guest{" "}
      <span className="font-weight-bolder">{`${name} (${pmsGuestId})`}</span>{" "}
      notes
    </>
  );

  return (
    <NoteListModal
      isOpen={isOpen}
      toggle={toggle}
      notes={notes}
      header={notesModalHeader}
    />
  );
};
