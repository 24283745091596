import { ReservationNote } from "@src/types";

/**
 * Converts useless ReservationNote object to array of strings
 * and filters it out of duplicates and key code messages.
 */
export const reservationNoteAdapter = (notes: ReservationNote[]) => {
  const notesFiltered: string[] = [];

  for (let i = 0; i < notes.length; i++) {
    const noteText = notes[i].reservation_note;

    if (
      !(
        noteText.length > 18 && noteText.substr(0, 18) === "Key code for room "
      ) &&
      !notesFiltered.includes(noteText)
    ) {
      notesFiltered.push(noteText);
    }
  }

  return notesFiltered;
};
