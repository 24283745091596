import { EntityId } from "@reduxjs/toolkit";
import { Override } from "@src/types";
import { Link, LinkProps } from "react-router-dom";

export type GuestDetailLinkProps = Override<
  LinkProps,
  { id: EntityId; to?: undefined }
>;

export const GuestDetailLink: React.FC<GuestDetailLinkProps> = ({
  id,
  ...props
}) => <Link {...props} to={`/guests/${id}`} />;
