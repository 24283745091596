import "@styles/base/pages/app-chat.scss";
import "@styles/base/pages/app-chat-list.scss";
import "./ChatLogSimple.scss";

import { AvatarWithTooltip, ChatLogSimplePlaceholder } from "@src/components";
import { ChatContact, ChatLogEntry, ChatLogEntryFormatted } from "@src/types";
import {
  getDefaultAvatar,
  getGravatarUrl,
  maximizeChat,
  isChatMaximized,
} from "@src/utility";
import classnames from "classnames";
import {
  SyntheticEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Maximize2, Minimize2, Send } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import TextareaAutosize from "react-textarea-autosize";
import pluralize from "pluralize";

import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  InputGroup,
  Toast,
  ToastHeader,
  UncontrolledTooltip,
} from "reactstrap";

import { EntityId } from "@reduxjs/toolkit";
import ReactPlaceholder from "react-placeholder";
import {
  patchManyNotificationActions,
  postMessageActions,
} from "@src/redux/Message";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import Avatar from "@components/avatar";
import themeConfig from "@configs/themeConfig";
import { ThemeContext } from "@src/utility/context/Theme";
import { MessageTemplateAttributes } from "@src/types/_entities/MessageTemplateEntity";
import { isEmpty } from "lodash";
import axios from "axios";
import { apiConfig } from "@configs/apiConfig";
import { toast } from "react-toastify";
import Rollbar from "rollbar";

export type ChatLogSimpleProps = {
  chatLogEntries?: ChatLogEntry[];
  guestId: EntityId;
  bookingId: EntityId;
  selectedUser: ChatContact;
  isLoading: boolean;
  headerText?: string;
  htmlId: string;
  style?: React.CSSProperties;
  updateChatMessages: () => void;
};

type DropdownStateType = { [key: string]: { isDropdownOpen: boolean } };
type DropdownActionType = {
  type: "OPEN_DROPDOWN" | "CLOSE_DROPDOWN";
  payload: EntityId;
};

const dropdownReducer = (
  state: DropdownStateType,
  action: DropdownActionType
) => {
  switch (action.type) {
    case "OPEN_DROPDOWN": {
      return { ...state, [action.payload]: { isDropdownOpen: true } };
    }
    case "CLOSE_DROPDOWN": {
      return { ...state, [action.payload]: { isDropdownOpen: false } };
    }
    default: {
      return state;
    }
  }
};

export const ChatLogSimple = ({
  chatLogEntries,
  guestId,
  bookingId,
  selectedUser,
  isLoading,
  headerText,
  htmlId,
  style,
  updateChatMessages,
}: ChatLogSimpleProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { skin } = useContext(ThemeContext);

  const { entities: propertyEntities } = useAppSelector(
    (state) => state.entities.property
  );

  const { entities: propertyMessageTemplates } = useAppSelector(
    (state) => state.app.message.template
  );

  const [chatContainer, setChatContainer] = useState<HTMLElement | null>(null);
  const [isGuestView, setIsGuestView] = useState(false);
  const [msg, setMsg] = useState("");
  const [isQuickResponseDropdownOpen, setIsQuickResponseDropdownOpen] =
    useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const toggleQuickResponseDropdown = () => {
    setIsQuickResponseDropdownOpen(!isQuickResponseDropdownOpen);
  };

  const toggleSubmenu = () => setIsSubmenuOpen(!isSubmenuOpen);

  const { pmsPropertyId } = useAppSelector(
    (state) => state.app.__global.filter
  );

  const { isAgent } = useAppSelector((state) => state.app.auth.data);

  const scrollToBottom = () => {
    if (chatContainer) {
      chatContainer.scrollTop =
        chatContainer.scrollHeight + chatContainer.offsetHeight;
    }
  };
  const [isNewMessageToastOpen, setIsNewMessageToastOpen] = useState(false);
  const [nonReadCount, setNonReadCount] = useState<number>(0);
  const [lastSeenMessageId, setLastSeenMessageId] = useState<
    EntityId | undefined
  >(0);
  const [isFirstChatRender, setIsFirstChatRender] = useState(true);

  const indexOfLastMessage = (chatLogEntries?.length || 0) - 1;

  const dropdownInitialState = Object.keys({
    ...propertyEntities,
    other: {},
  }).reduce((acc, curr) => ({ ...acc, [curr]: { isDropdownOpen: false } }), {});

  const [dropdownState, dispatchDropdown] = useReducer(
    dropdownReducer,
    dropdownInitialState
  );

  useEffect(() => {
    setLastSeenMessageId(
      chatLogEntries && chatLogEntries[indexOfLastMessage]?.entityId
    );
  }, []);

  useEffect(() => {
    if (chatContainer?.scrollHeight && isFirstChatRender) {
      scrollToBottom();
      setIsFirstChatRender(false);
    }
  }, [chatContainer?.scrollHeight]);

  useEffect(() => {
    if (chatLogEntries) {
      const indexOfLastSeenMessage = chatLogEntries.findIndex(
        (message) => message.entityId === lastSeenMessageId
      );

      if (
        indexOfLastSeenMessage > 0 &&
        indexOfLastMessage > 0 &&
        indexOfLastMessage !== indexOfLastSeenMessage
      ) {
        setIsNewMessageToastOpen(true);
        setNonReadCount(indexOfLastMessage - indexOfLastSeenMessage);
      }
    }
  }, [chatLogEntries]);

  const toastAction = () => {
    setIsNewMessageToastOpen(false);
    setTimeout(() => {
      setNonReadCount(0);
      setLastSeenMessageId(
        chatLogEntries && chatLogEntries[indexOfLastMessage]?.entityId
      );
    }, 150);
  };

  const handleToastClick = () => {
    toastAction();
    scrollToBottom();
  };

  const handleCloseToast = () => {
    toastAction();
  };

  useEffect(() => {
    setIsGuestView(window.location.pathname.startsWith("/guests/"));
  }, [window.location]);

  // ** Formats chat data based on sender
  const formattedChatData = () => {
    let chatLog: ChatLogEntry[] = [];

    if (chatLogEntries) {
      chatLog = chatLogEntries;
    }

    const formattedChatLog: ChatLogEntryFormatted[] = [];

    chatLog.forEach((msg) => {
      formattedChatLog.push({
        authorType: msg.authorType,
        isSystem: msg.isSystem,
        senderId: msg.senderId,
        senderEmail: msg.senderEmail,
        senderName: msg.senderName,
        messages: [
          {
            msg: msg.message,
            time: msg.time,
            readBy: msg.readBy,
          },
        ],
      });
    });
    return formattedChatLog;
  };

  const dateToReadable = (input: Date) =>
    `${Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(input)} ${Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
    }).format(input)}`;

  const renderMessageFooter = (
    person: {
      id: EntityId;
      email: string;
      name: string;
      time: string;
    },
    key: string
  ) => {
    const { email, time, name } = person;
    const newDate = new Date(time);
    const timeFormatted = dateToReadable(newDate);
    const avatarSrc = email ? getGravatarUrl(email) : getDefaultAvatar(null);

    return (
      <span key={`${key}-${time}`}>
        <span id={key}>
          <Avatar
            img={avatarSrc}
            imgClassName="guest-image"
            fallbackSrc={getDefaultAvatar(null)}
            imgHeight="20"
            imgWidth="20"
          />
        </span>
        <UncontrolledTooltip
          className="chat-read-by-tooltip"
          target={key}
          placement="bottom"
        >
          <span>Seen by</span>
          <b>{name}</b>
          <span>on</span>
          <b>{timeFormatted}</b>
        </UncontrolledTooltip>
      </span>
    );
  };

  // ** Renders user chat
  const renderChats = () =>
    formattedChatData().map((item, index) => {
      const isGuestMessage = item.authorType
        ? item.authorType === "Guest"
        : false;

      const isDarkTheme = skin === "dark";
      const systemLogo = isDarkTheme
        ? themeConfig.app.appLogoImage.bode.compactDark
        : themeConfig.app.appLogoImage.bode.compactLight;
      const guestAvatar = item.senderEmail
        ? getGravatarUrl(item.senderEmail)
        : undefined;
      const personAvatar = !isGuestMessage ? guestAvatar : selectedUser.avatar;
      const avatarSrc = item.isSystem ? systemLogo : personAvatar;

      return (
        <div
          key={`${item.senderId}-${index}`}
          className={classnames("chat", {
            "chat-left": isGuestMessage,
          })}
        >
          <AvatarWithTooltip
            key={`${item.senderId}${item.isSystem ? skin : ""}`}
            img={avatarSrc}
            fallbackSrc={getDefaultAvatar(
              !isGuestMessage ? null : selectedUser.gender
            )}
            className="chat-avatar"
            avatarClassName="box-shadow-1"
            htmlId={`${htmlId}-${index}`}
            tooltipText={item.senderName ?? ""}
            tooltipPlacement="top"
          />

          <div className="chat-body">
            {item.messages.map((chat, msgIndex) => {
              const readByArray = chat.readBy ?? [];
              const visibleReaders = readByArray
                ? readByArray.slice(0, 5)
                : null;

              const restOfReaders =
                readByArray.length > 5
                  ? readByArray.slice(5, readByArray.length)
                  : null;

              return (
                <div key={`${index}-${msgIndex}`}>
                  <div className="chat-content" data-timestamp={chat.time}>
                    <p dangerouslySetInnerHTML={{ __html: chat.msg }} />
                  </div>
                  <div
                    className={classnames("text-small", {
                      "float-left": isGuestMessage,
                      "float-right": !isGuestMessage,
                      "ml-1": isGuestMessage,
                      "mr-1": !isGuestMessage,
                    })}
                    style={{ clear: "both" }}
                  >
                    {isGuestMessage && (
                      <div className="chat-message-footer">
                        <>
                          {visibleReaders?.map((person) =>
                            renderMessageFooter(
                              person,
                              `read-by-${person.id}-${index}-${msgIndex}`
                            )
                          )}
                          {!!restOfReaders?.length && (
                            <span>
                              <UncontrolledTooltip
                                className="chat-read-by-tooltip"
                                target={`rest-${index}-${msgIndex}`}
                                placement="bottom"
                              >
                                {restOfReaders.map((person, index) => (
                                  <div key={`${person.id}-${index}`}>
                                    <span>Seen by</span>
                                    <b>{person.name}</b>
                                    <span>on</span>
                                    <b>
                                      {dateToReadable(new Date(person.time))}
                                    </b>
                                  </div>
                                ))}
                              </UncontrolledTooltip>
                              <Badge
                                className="read-by-badge"
                                id={`rest-${index}-${msgIndex}`}
                                color="primary"
                                pill
                              >
                                +{restOfReaders.length}
                              </Badge>
                            </span>
                          )}
                        </>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });

  // ** Sends New Msg
  const handleSendMsg = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (pmsPropertyId && msg.length && msg.length > 0) {
      /** Send message here */
      setMsg("");
      try {
        await dispatch(
          postMessageActions.sendToOne({
            pmsPropertyId,
            guestId,
            body: msg,
          })
        );
        updateChatMessages();
      } catch (e) {}
    }
  };

  const handleOnFocus = () => {
    if (!isAgent) return;
    const unreadMessages = chatLogEntries?.filter((entry) => !entry.read);

    if (!!unreadMessages?.length) {
      const messageIds = unreadMessages.map((msg) => msg.entityId);
      if (messageIds.some((id) => id === undefined)) {
        // @ts-ignore
        Rollbar.warning("Undefined message id!", chatLogEntries); // additional info for further debugging
      }

      dispatch(
        patchManyNotificationActions.markAsRead({
          ids: messageIds,
        })
      );
    }
  };

  const chatIsEmpty = chatLogEntries && chatLogEntries.length === 0;
  const maximizedChatHeight = "calc(100vh - 65px)";
  const populatedChatHeight = isGuestView ? "calc(100vh - 210px)" : "290px";
  const minimizedChatHeight = chatIsEmpty ? "75px" : populatedChatHeight;

  const closeSubmenu = (id: EntityId) =>
    dispatchDropdown({
      type: "CLOSE_DROPDOWN",
      payload: id,
    });

  const handleMessageClick = (
    template: Pick<
      MessageTemplateAttributes,
      "id" | "body" | "requiredParameters"
    >
  ) => {
    const { requiredParameters, id, body } = template;
    if (id) closeSubmenu(id);
    toggleQuickResponseDropdown();
    if (isEmpty(requiredParameters)) {
      return setMsg(body);
    }

    axios
      .get(
        `${apiConfig.baseUrl}/quick_reply_templates/${id}?bookingId=${bookingId}&guestId=${guestId}`
      )
      .then(({ data }) => {
        setMsg(data.data.attributes.body);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong during setting up the message.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div
      style={style}
      className={classnames("chat-app-window", {
        "mb-2": !isChatMaximized,
      })}
    >
      <ReactPlaceholder
        ready={!isLoading}
        customPlaceholder={<ChatLogSimplePlaceholder />}
        showLoadingAnimation={true}
      >
        <>
          <div
            className="maximize-chat-btn"
            onClick={() => maximizeChat(bookingId ?? guestId, guestId)}
          >
            <Badge color="secondary">
              {isChatMaximized ? <Minimize2 /> : <Maximize2 />}
            </Badge>
          </div>
          {Object.keys(selectedUser).length ? (
            <div
              className={classnames("active-chat", {
                "d-none": selectedUser === null,
              })}
            >
              {headerText && (
                <div className="chat-navbar text-center">
                  <header className="chat-header justify-content-center">
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0">{headerText}</h6>
                    </div>
                    <div className="d-flex align-items-center"></div>
                  </header>
                </div>
              )}

              <PerfectScrollbar
                className={classnames("user-chats", {
                  "empty-chat-msg": chatIsEmpty,
                })}
                options={{ wheelPropagation: false }}
                style={{
                  transition: "height 0.3s",
                  height: isChatMaximized
                    ? maximizedChatHeight
                    : minimizedChatHeight,
                }}
                containerRef={(ref) => {
                  setChatContainer(ref);
                }}
              >
                {chatLogEntries ? (
                  <div className="chats">{renderChats()}</div>
                ) : null}
              </PerfectScrollbar>
              <div className="new-message-toast-wrapper">
                <Toast
                  className="bg-transparent"
                  isOpen={isNewMessageToastOpen}
                >
                  <ToastHeader toggle={handleCloseToast}>
                    <div
                      className="new-message-notification px-2 py-1"
                      onClick={handleToastClick}
                    >
                      {pluralize("new message", nonReadCount, true)}
                    </div>
                  </ToastHeader>
                </Toast>
              </div>
              <Form
                className="chat-app-form"
                onSubmit={(e: SyntheticEvent<Element, Event>) =>
                  handleSendMsg(e)
                }
              >
                <InputGroup className="input-group-merge mr-1 form-send-message">
                  <TextareaAutosize
                    className="form-control"
                    placeholder="Type your message here"
                    value={msg}
                    onChange={(e) => {
                      setMsg(e.target.value);
                    }}
                    onFocus={() => {
                      handleOnFocus();
                    }}
                  />
                </InputGroup>
                <ButtonGroup className="align-self-start ">
                  <Button className="send" color="primary">
                    <Send size={14} className="d-lg-none" />
                    <span className="d-none d-lg-block">Send</span>
                  </Button>
                  {propertyMessageTemplates && (
                    <ButtonDropdown
                      isOpen={isQuickResponseDropdownOpen}
                      toggle={toggleQuickResponseDropdown}
                    >
                      <DropdownToggle
                        color="primary"
                        caret
                        className="dropdown-quick-reply-toggle"
                      />
                      <DropdownMenu>
                        {Object.entries<MessageTemplateAttributes[]>(
                          propertyMessageTemplates
                        ).map((template) => {
                          const [templatePropertyId, propertyTemplates] =
                            template;

                          return (
                            <div
                              key={`qr-${templatePropertyId}`}
                              className="px-2"
                            >
                              <Dropdown
                                direction="left"
                                onMouseOver={() =>
                                  dispatchDropdown({
                                    type: "OPEN_DROPDOWN",
                                    payload: templatePropertyId,
                                  })
                                }
                                onMouseLeave={() =>
                                  closeSubmenu(templatePropertyId)
                                }
                                isOpen={
                                  dropdownState[templatePropertyId]
                                    .isDropdownOpen
                                }
                                toggle={toggleSubmenu}
                              >
                                <DropdownToggle
                                  caret
                                  className="w-100 property-templates"
                                >
                                  {propertyEntities[templatePropertyId]
                                    ?.property_name || "Other"}
                                </DropdownToggle>
                                <DropdownMenu className="property-submenu">
                                  {propertyTemplates.map(
                                    (propertyTemplate, index) => (
                                      <DropdownItem
                                        key={`dropdownItem=${index}`}
                                        onClick={() =>
                                          handleMessageClick(propertyTemplate)
                                        }
                                      >
                                        {propertyTemplate.name}
                                      </DropdownItem>
                                    )
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          );
                        })}
                      </DropdownMenu>
                    </ButtonDropdown>
                  )}
                </ButtonGroup>
              </Form>
            </div>
          ) : null}
        </>
      </ReactPlaceholder>
    </div>
  );
};
