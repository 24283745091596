import { ListView } from "@src/types";
import { Download, Layout, Server, Mail } from "react-feather";
import { Button, ButtonGroup, FormGroup } from "reactstrap";

export type EntityListViewSwitchProps = {
  view: ListView;
  onChangeView: (view: ListView) => void;
  onCsvClick?: () => void;
  onPdfClick?: () => void;
  onSmsClick?: () => void;
};

export const EntityListViewSwitch: React.FC<EntityListViewSwitchProps> = ({
  view,
  onChangeView,
  onCsvClick,
  onPdfClick,
  onSmsClick,
}) => (
  <FormGroup>
    <ButtonGroup>
      <Button
        outline={true}
        color="secondary"
        onClick={() => onChangeView("cards")}
        active={view === "cards"}
      >
        <Server size={14} className="align-middle" />
        <span className="d-none d-sm-inline ml-50 align-middle">Cards</span>
      </Button>
      <Button
        outline={true}
        color="secondary"
        onClick={() => onChangeView("table")}
        active={view === "table"}
      >
        <Layout size={14} className="align-middle" />
        <span className="d-none d-sm-inline ml-50 align-middle">Table</span>
      </Button>
      {onPdfClick && (
        <Button outline={true} color="secondary" onClick={onPdfClick}>
          <Download
            size={14}
            className="d-none d-sm-inline mr-50 align-middle"
          />
          <span className="align-middle">PDF</span>
        </Button>
      )}
      {onCsvClick && (
        <Button outline={true} color="secondary" onClick={onCsvClick}>
          <Download
            size={14}
            className="d-none d-sm-inline mr-50 align-middle"
          />
          <span className="align-middle">CSV</span>
        </Button>
      )}
      {onSmsClick && (
        <Button outline={true} color="secondary" onClick={onSmsClick}>
          <Mail size={14} className="align-middle" />
          <span className="d-none d-sm-inline ml-50 align-middle">SMS</span>
        </Button>
      )}
    </ButtonGroup>
  </FormGroup>
);
