import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { authServices } from "@src/services/Auth/authServices";
import { RestapiAsyncThunkConfig, UserLoginResponseData } from "@src/types";
import { AxiosResponse } from "axios";

import { apiAsyncThunkFactory } from "../apiAsyncThunkFactory";

const login = apiAsyncThunkFactory({
  name: "user/login",
  serviceFn: authServices.login,
});

const loginGoogle = createAsyncThunk<
  AxiosResponse<UserLoginResponseData>,
  { token: string; email: string },
  RestapiAsyncThunkConfig
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>("user/login/google", async ({ email, ...payload }, { rejectWithValue }) => {
  try {
    return await authServices.loginGoogle(payload);
  } catch (err) {
    return rejectWithValue(err as RestapiAsyncThunkConfig["rejectValue"]);
  }
});

const loginResetStatus = createAction("user/login/resetStatus");

const logout = createAction("user/logout");

export const userActions = {
  login,
  loginGoogle,
  loginResetStatus,
  logout,
};
