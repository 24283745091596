import {
  GuestDetailLink,
  PrettyCell,
  ReservationDetailsLink,
} from "@components/.";
import { EntityId } from "@reduxjs/toolkit";
import {
  GuestEntity,
  Override,
  PropertyEntity,
  ReservationEntity,
} from "@src/types";
import {
  formatDate,
  formatNumberToStringWithCommasAndCurrency,
} from "@src/utility";

export type ReservationListColumnNodeProps = Override<
  ReservationEntity,
  {
    property: PropertyEntity;
    mainGuestId: EntityId;
    mainGuest?: GuestEntity;
    guests?: undefined | GuestEntity[] | null;
  }
>;

export const DateParagraph: React.FC = ({ children }) => (
  <p className="text-bold-500 text-truncate mb-0">{children}</p>
);

export const RightParagraph: React.FC<ReservationListColumnNodeProps> = ({
  priceTotal,
}) => (
  <p
    className="text-bold-500 mb-0"
    style={{ textAlign: "right", width: "80%" }}
  >
    {formatNumberToStringWithCommasAndCurrency(priceTotal, "USD", 2)}
  </p>
);

export const PrettyCol: React.FC<ReservationListColumnNodeProps> = ({
  pmsStatus,
}) => (pmsStatus ? <PrettyCell status={pmsStatus} /> : null);

export const BookedAt: React.FC<ReservationListColumnNodeProps> = ({
  bookedAtInPropertyTz,
}) => <DateParagraph>{formatDate(bookedAtInPropertyTz)}</DateParagraph>;

export const CheckIn: React.FC<ReservationListColumnNodeProps> = ({
  checkInDate,
}) => <DateParagraph>{formatDate(checkInDate)}</DateParagraph>;

export const CheckOut: React.FC<ReservationListColumnNodeProps> = ({
  checkOutDate,
}) => <DateParagraph>{formatDate(checkOutDate)}</DateParagraph>;

export const LinkCol: React.FC<ReservationListColumnNodeProps> = ({
  id,
  confirmationNumber,
}) => (
  <ReservationDetailsLink id={id} style={{ textDecoration: "underline" }}>
    {confirmationNumber}
  </ReservationDetailsLink>
);

export const MainGuest: React.FC<ReservationListColumnNodeProps> = ({
  mainGuestId,
  mainGuestName,
}) => (
  <GuestDetailLink
    className="text-center text-decoration-underline"
    id={mainGuestId}
    style={{ textDecoration: "underline" }}
  >
    {mainGuestName}
  </GuestDetailLink>
);
