import Spinner from "@components/spinner/Fallback-spinner";
import { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Persistor } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary, Provider as RollbarProvider } from "@rollbar/react";

import { ThemeProvider } from "./utility/context/Theme";
import { rollbarConfig } from "@src/services/Rollbar/rollbarConfig";

const LazyRouter = lazy(() => import("./router/Router"));

export type AppProps = {
  persistor: Persistor;
  store: any;
};

export const App: React.FC<AppProps> = ({ persistor, store }) => {
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<Spinner />}>
              <ThemeProvider>
                <LazyRouter />
                <ToastContainer newestOnTop />
              </ThemeProvider>
            </Suspense>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};
