import { ReservationEntity } from "@src/types";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { ReservationTimeline } from ".";

export type ReservationTimelineCardProps = { data: ReservationEntity };

export const ReservationTimelineCard: React.FC<ReservationTimelineCardProps> =
  ({ data }) => (
    <Card>
      <CardHeader>
        <CardTitle>Timeline</CardTitle>
      </CardHeader>
      <CardBody>
        <ReservationTimeline data={data} />
      </CardBody>
    </Card>
  );
