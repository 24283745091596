import "./ReservationCard.scss";

import { useToggle } from "@hooks/.";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";
import classnames from "classnames";
import { Card } from "reactstrap";

import { ReservationCardHeader } from ".";
import { ReservationKeyCodeModal, ReservationNotesListModal } from "..";

export type ReservationCardProps = {
  className?: string;
  data: ReservationListColumnNodeProps;
};

export const ReservationCard: React.FC<ReservationCardProps> = ({
  className,
  data,
}) => {
  const [isKeycodeModalOpen, keycodeModalToggle] = useToggle();
  const [isNotesModalOpen, notesModalToggle] = useToggle();

  const { confirmationNumber, roomKeyCode, roomName, notes } = data;

  return (
    <>
      <Card className={classnames("reservation-card entity-card", className)}>
        <ReservationCardHeader
          data={data}
          onKeycodeActionClick={keycodeModalToggle}
          onNotesActionClick={notesModalToggle}
        />
      </Card>

      <ReservationKeyCodeModal
        isOpen={isKeycodeModalOpen}
        toggle={keycodeModalToggle}
        roomKeyCode={roomKeyCode}
        roomName={roomName}
      />

      <ReservationNotesListModal
        isOpen={isNotesModalOpen}
        toggle={notesModalToggle}
        confirmationNumber={confirmationNumber}
        notes={notes || []}
      />
    </>
  );
};
