import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  StatisticsServiceResult,
  __GlobalFilterServiceOptions,
  __GlobalFilterThunkConfig,
} from "@src/types";

export const createStatisticsPropertyTodayAsyncThunk = ({
  name,
  serviceFn,
}: {
  name: string;
  serviceFn: (
    options: __GlobalFilterServiceOptions
  ) => Promise<StatisticsServiceResult>;
}) =>
  createAsyncThunk<StatisticsServiceResult, void, __GlobalFilterThunkConfig>(
    name,
    async (arg, { rejectWithValue, getState, signal }) => {
      try {
        const { pmsPropertyId } = getState().app.__global.filter;

        if (pmsPropertyId) {
          return await serviceFn({
            params: {
              __globalFilter: { pmsPropertyId },
              filter: undefined,
            },
            signal,
          });
        } else {
          throw new Error("Missing property id");
        }
      } catch (err) {
        return rejectWithValue(err as __GlobalFilterThunkConfig["rejectValue"]);
      }
    }
  );
