import "./PrettyCellPlaceholder.scss";

import { usePlaceholderColor } from "@hooks/usePlaceholderColor";
import { HelpCircle } from "react-feather";
import { Badge } from "reactstrap";

export const PrettyCellPlaceholder: React.FC = () => {
  const color = usePlaceholderColor();

  return (
    <Badge
      pill
      style={{ backgroundColor: color, width: "80%" }}
      className="pretty-cell-placeholder text-left"
    >
      <HelpCircle className="mr-auto" />
    </Badge>
  );
};
