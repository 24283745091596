import "./Attachment.scss";
import "./AttachmentPlaceholder.scss";

import { PlaceholderRectShape, PlaceholderTextRow } from "..";

export const AttachmentPlaceholder: React.FC = () => (
  <div className="attachment attachment-placeholder ellipsis-wrapper align-items-center">
    <div className="svg">
      <PlaceholderRectShape />
    </div>
    <div className="ellipsis small">
      <PlaceholderTextRow />
    </div>
  </div>
);
