import { SerializedError } from "@reduxjs/toolkit";
import { ThunkServiceErrorExtended } from "@src/types";

export const createAsyncThunkRejectedError = <
  T extends { error?: { message: string; code: string } }
>(
  payload: ThunkServiceErrorExtended<T> | undefined,
  error: SerializedError
) => {
  if (!payload) {
    return {
      message: error.message || "Something went wrong. We are working on it",
      code: error.code,
    };
  }

  const { message, response } = payload;

  return {
    message: response?.data?.error?.message || message,
    code: response?.data?.error?.code || error.code,
    status: response?.status,
  };
};
