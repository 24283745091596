import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postMessageToManyService,
  PostMessageToOneArgs,
  postMessageToOneService,
} from "@src/services/Message";
import {
  App__GlobalState,
  JsonapiAsyncThunkConfig,
  ReservationListFilter,
} from "@src/types";
import { toast } from "react-toastify";

const postMessageToOne = createAsyncThunk<
  { response: string },
  PostMessageToOneArgs,
  JsonapiAsyncThunkConfig
>(`messages/sendByGuestId`, async (arg, { rejectWithValue, signal }) => {
  try {
    return await postMessageToOneService({
      signal,
      arg,
    });
  } catch (err) {
    return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
  }
});

const postMessageToMany = createAsyncThunk<
  { response: string },
  { body: string },
  JsonapiAsyncThunkConfig & {
    state: {
      app: {
        __global: App__GlobalState;
        reservation: { allList: { filter: Partial<ReservationListFilter> } };
      };
    };
  }
>(
  `messages/sendToMany`,
  async ({ body }, { rejectWithValue, signal, getState }) => {
    const {
      app: {
        __global: {
          filter: __globalFilter,
          search: { query },
        },
        reservation: {
          allList: { filter },
        },
      },
    } = getState();

    try {
      return await postMessageToManyService({
        signal,
        arg: {
          body,
          __globalFilter,
          filter: {
            ...filter,
            search: query,
          },
        },
      });
    } catch (err) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  }
);

export const postMessageActions = {
  sendToOne: postMessageToOne,
  sendToMany: postMessageToMany,
};
