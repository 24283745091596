import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getManyGuestsService } from "@src/services/Guest";
import {
  App__GlobalState,
  GuestEntity,
  JsonapiAsyncThunkConfig,
  JsonapiResponseFetchMany,
  PageSize,
} from "@src/types";

const refetch = createAsyncThunk<
  JsonapiResponseFetchMany<GuestEntity>,
  void,
  JsonapiAsyncThunkConfig & { state: { app: { __global: App__GlobalState } } }
>(
  `__global/guest/search/refetch`,
  async (payload, { rejectWithValue, getState, signal }) => {
    try {
      const {
        app: {
          __global: {
            filter: __globalFilter,
            search: { query },
          },
        },
      } = getState();

      if (query === "") {
        return { data: [], meta: { totalCount: 0, totalPages: 0 } };
      }

      return await getManyGuestsService({
        params: {
          __globalFilter,
          filter: {
            search: query,
          },
          isSearch: true,
          page: { number: 1, size: 5 },
          sort: { key: "lastName", order: "ASC" },
        },
        signal,
      });
    } catch (err) {
      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  }
);
const fetchMore = createAsyncThunk<
  JsonapiResponseFetchMany<GuestEntity>,
  void,
  JsonapiAsyncThunkConfig & { state: { app: { __global: App__GlobalState } } }
>(
  `__global/guest/search/fetchMore`,
  async (payload, { rejectWithValue, getState, signal }) => {
    try {
      const {
        app: {
          __global: {
            filter: __globalFilter,
            search: {
              query,
              data: {
                guest: { page },
              },
            },
          },
        },
      } = getState();

      if (query === "") {
        return { data: [], meta: { totalCount: 0, totalPages: 0 } };
      }

      return await getManyGuestsService({
        params: {
          __globalFilter,
          filter: {
            search: query,
          },
          page: { number: page.number, size: 5 },
          sort: { key: "lastName", order: "ASC" },
        },
        signal,
      });
    } catch (err) {
      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  }
);

const resetStatus = createAction(`__global/guest/search/resetStatus`);

const setPageSize = createAction<PageSize>(`__global/guest/search/setPageSize`);

const setPageNumber = createAction<number>(
  `__global/guest/search/setPageNumber`
);

export const __globalSearchGuestsActions = {
  refetch,
  fetchMore,
  resetStatus,
  setPageSize,
  setPageNumber,
};
