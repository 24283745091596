import { serviceConfig } from "@configs/serviceConfig";
import { EntityId } from "@reduxjs/toolkit";
import {
  AbstractEntity,
  GuestEntity,
  Override,
  SingleServiceTConfig,
} from "@src/types";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";

import { moduleServicesFactory } from "../utils";

export type GuestServiceTConfig = {
  fetchMany: SingleServiceTConfig<GuestEntity[], void>;
  fetchOne: SingleServiceTConfig<
    GuestEntity & {
      reservations?:
        | Override<
            ReservationListColumnNodeProps,
            {
              guests?: undefined;
              mainGuest: AbstractEntity;
              mainGuestId?: undefined | EntityId;
              notes?: string[] | undefined;
            }
          >[]
        | null;
    },
    EntityId
  >;
};

export const guestServices = moduleServicesFactory<GuestServiceTConfig>(
  serviceConfig.guest
);
