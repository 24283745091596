import "./ReservationListFilterBar.scss";

import { ReservationListFilterInputWrapper } from "@components/.";
import { reservationStatusesConfig } from "@configs/Reservation/reservationStatusesConfig";
import { useAppDispatch } from "@hooks/.";
import { InputDate, InputDateRange } from "@src/components";
import { selectColourStyles } from "@src/components/DataTable/selectStylesVuexy";
import { reservationListFilterKeys, reservationStatuses } from "@src/constants";
import { getManyReservationsActions } from "@src/redux/Reservation";
import {
  DateRange,
  ReservationListFilter,
  ReservationStatus,
} from "@src/types";
import { ChangeEvent, SyntheticEvent, useState } from "react";
import Select, { OptionTypeBase, OptionsType } from "react-select";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";

export type ReservationListFilterBarProps = {
  filter: Partial<ReservationListFilter>;
};

export const ReservationListFilterBar: React.FC<ReservationListFilterBarProps> =
  ({ filter }) => {
    const dispatch = useAppDispatch();

    const [displayFilterField, setDisplayFilterField] = useState({
      checkInDateRange: false,
      checkOutDateRange: false,
      pmsStatus: false,
      bookedAtInPropertyTz: false,
      isVip: false,
    });

    const { checkInDateRange, checkOutDateRange, bookedAtInPropertyTz } =
      filter;

    const statusSelectOptionsMapper = (status: ReservationStatus) => ({
      value: status,
      label: reservationStatusesConfig[status].alias,
      color: reservationStatusesConfig[status].colorHex,
    });

    const statusSelectOptions = reservationStatuses.map(
      statusSelectOptionsMapper
    );

    const statusValue = filter["pmsStatus"];

    const statusSelectValue = statusValue
      ? statusValue.map(statusSelectOptionsMapper)
      : null;

    const handleStatusChange = (
      options: OptionTypeBase | OptionsType<OptionTypeBase> | null
    ) => {
      if (options) {
        const value = Array.isArray(options)
          ? options.map((option) => option.value)
          : [options.values];

        const newStatus = value.length > 0 ? value : undefined;

        dispatch(
          getManyReservationsActions.setFilter({
            ...filter,
            ["pmsStatus"]: newStatus,
          })
        );
      }
    };

    const handleCheckInDateRangeChange = (value: DateRange) => {
      dispatch(
        getManyReservationsActions.setFilter({
          ...filter,
          checkInDateRange: value && !value[0] && !value[1] ? undefined : value,
        })
      );
    };

    const handleCheckOutDateRangeChange = (value: DateRange) => {
      dispatch(
        getManyReservationsActions.setFilter({
          ...filter,
          checkOutDateRange:
            value && !value[0] && !value[1] ? undefined : value,
        })
      );
    };

    const handleBookedAtInPropertyTzChange = (value: string | null) => {
      dispatch(
        getManyReservationsActions.setFilter({
          ...filter,
          bookedAtInPropertyTz: value || undefined,
        })
      );
    };

    const handleIsVipChange = (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        getManyReservationsActions.setFilter({
          ...filter,
          isVip:
            e.currentTarget.value === "true"
              ? true
              : e.currentTarget.value === "false"
              ? false
              : undefined,
        })
      );
    };

    const filterSetting = {
      pmsStatus: {
        id: "pmsStatus",
        children: (
          <Select
            closeMenuOnSelect={false}
            isMulti={true}
            options={statusSelectOptions}
            className="React cursor-pointer"
            classNamePrefix="select"
            styles={selectColourStyles}
            onChange={handleStatusChange}
            defaultValue={statusSelectValue}
            value={statusSelectValue}
          />
        ),
        label: "Status",
      },
      checkInDateRange: {
        id: "checkInDateRange",
        children: (
          <InputDateRange
            value={checkInDateRange || [null, null]}
            onChange={handleCheckInDateRangeChange}
          />
        ),
        label: "Check-in date",
      },
      checkOutDateRange: {
        id: "checkOutDateRange",
        children: (
          <InputDateRange
            value={checkOutDateRange || [null, null]}
            onChange={handleCheckOutDateRangeChange}
          />
        ),
        label: "Check-out date",
      },
      bookedAtInPropertyTz: {
        id: "bookedAtInPropertyTz",
        children: (
          <InputDate
            value={bookedAtInPropertyTz}
            onChange={handleBookedAtInPropertyTzChange}
          />
        ),
        label: "Booked-at date in property TZ",
      },
      isVip: {
        id: "isVip",
        children: (
          <Input
            className="w-100"
            type="select"
            id="sort-key-select"
            defaultValue={undefined}
            value={filter["isVip"]?.toString()}
            onChange={handleIsVipChange}
          >
            {filter["isVip"] === undefined && <option value={undefined} />}
            <option value="true">VIPs only</option>
            <option value="false">All except VIPs</option>
          </Input>
        ),
        label: "VIP",
      },
    } as const;

    const filterElements = reservationListFilterKeys
      .filter(
        (filterKey) =>
          filter[filterKey] !== undefined || displayFilterField[filterKey]
      )
      .map((filterKey) => {
        const handleRemoveClick = () => {
          setDisplayFilterField((state) => ({
            ...state,
            [filterKey]: false,
          }));

          if (filter[filterKey] !== undefined) {
            dispatch(
              getManyReservationsActions.setFilter({
                ...filter,
                [filterKey]: undefined,
              })
            );
          }
        };

        return (
          <ReservationListFilterInputWrapper
            key={filterKey}
            label={filterSetting[filterKey].label}
            onRemoveClick={handleRemoveClick}
          >
            {filterSetting[filterKey].children}
          </ReservationListFilterInputWrapper>
        );
      });

    const addFilterDropdownItems = reservationListFilterKeys
      .filter(
        (filterKey) =>
          filter[filterKey] === undefined && !displayFilterField[filterKey]
      )
      .map((filterKey) => {
        const handleClick = (e: SyntheticEvent) => {
          e.preventDefault();

          setDisplayFilterField((state) => ({
            ...state,
            [filterKey]: true,
          }));
        };

        return (
          <DropdownItem tag="a" onClick={handleClick} key={filterKey}>
            {filterSetting[filterKey].label}
          </DropdownItem>
        );
      });

    const addFilterDropdown = addFilterDropdownItems.length > 0 && (
      <ReservationListFilterInputWrapper label={"Select new filter"}>
        <UncontrolledButtonDropdown className="d-flex">
          <DropdownToggle outline caret>
            Filters
          </DropdownToggle>
          <DropdownMenu>{addFilterDropdownItems}</DropdownMenu>
        </UncontrolledButtonDropdown>
      </ReservationListFilterInputWrapper>
    );

    return (
      <Row className="filter-row">
        {filterElements}
        {addFilterDropdown}
      </Row>
    );
  };
