import ReactPaginate from "react-paginate";

export type EntityListPaginationProps = {
  /** Natural number */
  pageNumber?: number;
  totalPages?: number;
  /** Natural number */
  onPageChange: (pageNumber: number) => void;
};

export const EntityListPagination: React.FC<EntityListPaginationProps> = ({
  pageNumber,
  totalPages,
  onPageChange,
}) => {
  const handlePageChange = ({ selected }: { selected: number }) => {
    onPageChange(selected + 1);
  };

  return !!pageNumber && !!totalPages ? (
    <ReactPaginate
      forcePage={pageNumber - 1}
      pageCount={totalPages}
      nextLabel={""}
      breakLabel={"..."}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      activeClassName={"active"}
      pageClassName={"page-item"}
      previousLabel={""}
      nextLinkClassName={"page-link"}
      nextClassName={"page-item next-item"}
      previousClassName={"page-item prev-item"}
      previousLinkClassName={"page-link"}
      pageLinkClassName={"page-link"}
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName={"pagination react-paginate justify-content-center"}
      onPageChange={handlePageChange}
    />
  ) : null;
};
