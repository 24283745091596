import themeConfig from "@configs/themeConfig";
import { useSkin } from "@hooks/useSkin";
import { Skin, ThemeColorsConfig } from "@src/types";
import { createContext, useEffect, useState } from "react";

export type ThemeCtxColors = Partial<Omit<ThemeColorsConfig, "placeholder">> & {
  placeholder: { dark: string; main: string };
};

const initialThemeCtxColors = {
  primary: undefined,
  secondary: undefined,
  success: undefined,
  danger: undefined,
  warning: undefined,
  info: undefined,
  placeholder: {
    dark: "#6e6b7b",
    main: "#cccccc",
  },
};

export const ThemeContext = createContext<{
  colors: ThemeCtxColors;
  setBrandColor(hex: string): void;
  skin: Skin;
  setSkin: (
    value: React.SetStateAction<"light" | "dark" | "bordered" | "semi-dark">
  ) => void;
  placeholderColor: string;
}>({
  colors: initialThemeCtxColors,
  setBrandColor: () => {},
  skin: themeConfig.layout.skin,
  setSkin: () => {},
  placeholderColor: "#6e6b7b",
});

export const ThemeProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const selectPlaceholderColor = ({
    colors: { dark, main },
    skin: skinArg,
  }: {
    colors: { dark: string; main: string };
    skin: Skin;
  }) => (skinArg === "dark" ? dark : main);

  const [skin, setSkin] = useSkin();
  const [colors, setColors] = useState<ThemeCtxColors>(initialThemeCtxColors);

  const [placeholderColor, setPlaceholderColor] = useState(() =>
    selectPlaceholderColor({ colors: colors.placeholder, skin })
  );

  const setBrandColor = (hex: string) => {
    document.documentElement.style.setProperty("--brand", hex);
  };

  useEffect(() => {
    setPlaceholderColor(
      selectPlaceholderColor({ colors: colors.placeholder, skin })
    );
  }, [skin, colors.placeholder]);

  const getHex = (color: string) =>
    window.getComputedStyle(document.body).getPropertyValue(color).trim();

  useEffect(() => {
    const obj = {
      primary: {
        light: getHex("--primary").concat("1a"),
        main: getHex("--primary"),
      },
      secondary: {
        light: getHex("--secondary").concat("1a"),
        main: getHex("--secondary"),
      },
      success: {
        light: getHex("--success").concat("1a"),
        main: getHex("--success"),
      },
      danger: {
        light: getHex("--danger").concat("1a"),
        main: getHex("--danger"),
      },
      warning: {
        light: getHex("--warning").concat("1a"),
        main: getHex("--warning"),
      },
      info: {
        light: getHex("--info").concat("1a"),
        main: getHex("--info"),
      },
      dark: {
        light: getHex("--dark").concat("1a"),
        main: getHex("--dark"),
      },
      placeholder: {
        main: getHex("--primary").concat("1a"),
        dark: "#6e6b7b",
      },
    };

    setColors(obj);
  }, []);

  return (
    <ThemeContext.Provider
      value={{ colors, setBrandColor, skin, setSkin, placeholderColor }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
