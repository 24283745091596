import "react-datepicker/dist/react-datepicker.css";

import "./InputDate.scss";

import { DateRange } from "@src/types";
import { apiFormatDate } from "@src/utility";
import DatePicker from "react-datepicker";
import { X } from "react-feather";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

export type InputDateRangeProps = {
  className?: string;
  value: DateRange;
  onChange: (value: DateRange) => void;
};

export const InputDateRange: React.FC<InputDateRangeProps> = ({
  value: [from, to],
  onChange: onChangeDateRange,
}) => {
  const fromDate = from ? new Date(from) : null;
  const toDate = to ? new Date(to) : null;

  //@ts-ignore
  const CustomInputFrom: React.FC = ({ onClick, onChange, value }) => (
    <InputGroup className="input-group-merge">
      <Input
        type="text"
        value={value}
        onClick={onClick}
        onChange={onChange}
        placeholder="from"
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      />
      {value && (
        <InputGroupAddon addonType="append">
          <InputGroupText
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <X
              size="16"
              className="cursor-pointer"
              onClick={() => {
                onChangeDateRange([null, to]);
              }}
            ></X>
          </InputGroupText>
        </InputGroupAddon>
      )}
    </InputGroup>
  );

  //@ts-ignore
  const CustomInputTo: React.FC = ({ onClick, onChange, value }) => (
    <InputGroup className="input-group-merge">
      <Input
        type="text"
        value={value}
        onClick={onClick}
        onChange={onChange}
        placeholder="to"
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 0,
        }}
      />
      {value && (
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <X
              size="16"
              className="cursor-pointer"
              onClick={() => {
                onChangeDateRange([from, null]);
              }}
            ></X>
          </InputGroupText>
        </InputGroupAddon>
      )}
    </InputGroup>
  );

  return (
    <div className="d-flex ">
      <DatePicker
        selected={fromDate}
        maxDate={toDate}
        onChange={(date) => {
          // @ts-ignore
          onChangeDateRange([date ? apiFormatDate(date) : null, to]);
        }}
        customInput={<CustomInputFrom />}
      />
      <DatePicker
        selected={toDate}
        minDate={fromDate}
        onChange={(date) => {
          // @ts-ignore
          onChangeDateRange([from, date ? apiFormatDate(date) : null]);
        }}
        customInput={<CustomInputTo />}
      />
    </div>
  );
};
