import { serviceConfig } from "@configs/serviceConfig";
import { EntityId } from "@reduxjs/toolkit";
import { ReservationEntity, SingleServiceTConfig } from "@src/types";

import { moduleServicesFactory } from "../utils";

export type ReservationServiceTConfig = {
  fetchMany: SingleServiceTConfig<ReservationEntity[], void>;
  fetchOne: SingleServiceTConfig<ReservationEntity, EntityId>;
};

/**
 * Very early concept for config driven moduleServicesFactory. Currently
 * only fetchOne service is useful. There has to be announced a lot of
 * type arguments to make fetchAll working in every use case
 */
export const reservationServices =
  moduleServicesFactory<ReservationServiceTConfig>(serviceConfig.reservation);
