import {
  Draft,
  EntityId,
  EntityState,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  AbstractEntity,
  GuestEntity,
  JsonapiResponseFetchMany,
  Override,
  ReservationEntity,
} from "@src/types";

import {
  __globalSearchGuestsActions,
  __globalSearchReservationsActions,
} from "../__Global";
import { userActions } from "../actions";
import { entityAdapters } from "../entityAdapters";
import { getManyGuestsActions } from "../Guest/getManyGuestsActions";
import { getOneGuestActions } from "../Guest/getOneGuestActions";
import { ConversationEntity } from "../../types/_entities/ConversationEntity";
import {
  getManyReservationsActions,
  getOneReservationActions,
  postOneReservationPreCheckInActions,
} from "../Reservation";
import { getManyConversationsActions } from "../Message";

const guestSetManyReducer = (
  state: Draft<EntityState<GuestEntity & { reservations?: EntityId[] | null }>>,
  {
    payload,
  }: PayloadAction<
    JsonapiResponseFetchMany<
      GuestEntity & { reservations?: AbstractEntity[] | null }
    >
  >
) => {
  const guests = payload.data.map((guest) => {
    const reservations =
      guest.reservations?.map((reservation) => reservation.id) ||
      state.entities[guest.id]?.reservations;

    return { ...guest, reservations };
  });

  entityAdapters["guest"].upsertMany(state, guests);
};

const messageSetManyReducer = (
  state: Draft<EntityState<ConversationEntity>>,
  { payload }: PayloadAction<JsonapiResponseFetchMany<ConversationEntity>>
) => {
  entityAdapters["conversation"].upsertMany(state, payload.data);
};

const guestSetManyFromReservationsReducer = (
  state: Draft<EntityState<GuestEntity & { reservations?: EntityId[] | null }>>,
  {
    payload,
  }: PayloadAction<
    JsonapiResponseFetchMany<
      Override<
        ReservationEntity,
        {
          guests?: undefined | null;
          mainGuest: GuestEntity & { reservations?: AbstractEntity[] | null };
        }
      >
    >
  >
) => {
  const entities = payload.data.map(({ mainGuest }) => ({
    ...mainGuest,
    reservations:
      mainGuest.reservations?.map((reservation) => reservation.id) ||
      state.entities[mainGuest.id]?.reservations,
  }));

  entityAdapters["guest"].upsertMany(state, entities);
};

const guest = createSlice({
  name: `entities/guest`,
  initialState: entityAdapters["guest"].getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getManyGuestsActions.fetch.fulfilled, guestSetManyReducer)
      .addCase(
        __globalSearchGuestsActions.refetch.fulfilled,
        guestSetManyReducer
      )
      .addCase(
        __globalSearchGuestsActions.fetchMore.fulfilled,
        guestSetManyReducer
      )
      .addCase(
        getOneGuestActions.fetch.fulfilled,
        (
          state,
          { payload: { reservations: reservationsData, ...payload }, ...action }
        ) => {
          const reservations = reservationsData
            ? reservationsData.map((reservation) => reservation.id)
            : reservationsData;

          entityAdapters.guest.setOne(state, {
            ...action,
            payload: { ...payload, reservations },
          });
        }
      )
      .addCase(
        getManyReservationsActions.fetch.fulfilled,
        guestSetManyFromReservationsReducer
      )
      .addCase(
        __globalSearchReservationsActions.refetch.fulfilled,
        guestSetManyFromReservationsReducer
      )
      .addCase(
        __globalSearchReservationsActions.fetchMore.fulfilled,
        guestSetManyFromReservationsReducer
      )
      .addCase(
        getOneReservationActions.fetch.fulfilled,
        (state, { payload, ...action }) => {
          if (payload.mainGuest) {
            entityAdapters["guest"].setOne(state, {
              ...action,
              payload: payload.mainGuest,
            });
          }

          if (payload.guests) {
            entityAdapters["guest"].setMany(state, {
              ...action,
              payload: payload.guests,
            });
          }
        }
      );
  },
});

const property = createSlice({
  name: `entities/property`,
  initialState: entityAdapters["property"].getInitialState(),
  reducers: {
    removeOne: entityAdapters.property.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(userActions.login.fulfilled, (state, action) => {
        entityAdapters["property"].addMany(
          state,
          action.payload.data.scopes.map(({ property_id, ...item }) => ({
            ...item,
            id: property_id,
          }))
        );
      })
      .addCase(userActions.loginGoogle.fulfilled, (state, action) => {
        entityAdapters["property"].addMany(
          state,
          action.payload.data.scopes.map(({ property_id, ...item }) => ({
            ...item,
            id: property_id,
          }))
        );
      });
  },
});

const reservationSetManyReducer = (
  state: Draft<
    EntityState<
      Override<
        ReservationEntity,
        {
          guests?: EntityId[] | undefined | null;
          mainGuest?: undefined;
          mainGuestId: EntityId;
        }
      >
    >
  >,
  action: PayloadAction<
    JsonapiResponseFetchMany<
      Override<
        ReservationEntity,
        {
          guests?: undefined | null;
        }
      >
    >
  >
) => {
  entityAdapters["reservation"].setMany(state, {
    ...action,
    payload: action.payload.data
      .filter((obj) => obj.mainGuest)
      .map(({ mainGuest, ...item }) => ({
        ...item,
        mainGuestId: mainGuest.id,
      })),
  });
};

const reservation = createSlice({
  name: `entities/reservation`,
  initialState: entityAdapters["reservation"].getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getManyReservationsActions.fetch.fulfilled,
        reservationSetManyReducer
      )
      .addCase(
        __globalSearchReservationsActions.refetch.fulfilled,
        reservationSetManyReducer
      )
      .addCase(
        __globalSearchReservationsActions.fetchMore.fulfilled,
        reservationSetManyReducer
      )
      .addCase(
        getOneReservationActions.fetch.fulfilled,
        (state, { payload: { guests, mainGuest, ...item }, ...action }) => {
          if (mainGuest) {
            entityAdapters["reservation"].setOne(state, {
              ...action,
              payload: {
                ...item,
                mainGuestId: mainGuest?.id,
                guests: guests?.map(({ id }) => id),
              },
            });
          }
        }
      )
      .addCase(
        getOneGuestActions.fetch.fulfilled,
        (state, { payload: { reservations }, ...action }) => {
          if (reservations) {
            entityAdapters["reservation"].setMany(state, {
              ...action,
              payload: reservations.map(({ mainGuest, ...reservation }) => ({
                ...reservation,
                mainGuestId: mainGuest.id,
                mainGuest: undefined,
                guests: undefined,
              })),
            });
          }
        }
      )
      .addCase(
        postOneReservationPreCheckInActions.fetch.fulfilled,
        (state, { meta }) => {
          entityAdapters.reservation.updateOne(state, {
            id: meta.arg.id,
            changes: { preCheckInCompleted: true },
          });
        }
      );
  },
});

const conversation = createSlice({
  name: `entities/conversation`,
  initialState: entityAdapters["conversation"].getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getManyConversationsActions.fetch.fulfilled as any,
      messageSetManyReducer
    );
  },
});

export const entitySlices = {
  guest,
  property,
  reservation,
  conversation,
};
