import "bs-stepper/dist/css/bs-stepper.min.css";
import "@src/@core/scss/base/plugins/forms/form-wizard.scss";

import "./GuestCard.scss";

import { useToggle } from "@hooks/useToggle";
import { GuestEntity } from "@src/types";
import classnames from "classnames";
import { Card } from "reactstrap";

import { GuestNotesListModal } from "../GuestNotesListModal";
import { GuestCardHeader } from ".";

export type GuestCardProps = {
  className?: string;
  data: GuestEntity;
  extraActionElements?: React.ReactNode;
};

export const GuestCard: React.FC<GuestCardProps> = ({
  className,
  data,
  extraActionElements,
}) => {
  const { pmsGuestId, notes } = data;

  const [isNotesModalOpen, notesModalToggle] = useToggle();

  const name = [data.firstName, data.lastName]
    .filter((s) => s && s.length > 0)
    .join(" ");

  return (
    <>
      <Card className={classnames("guest-card entity-card", className)}>
        <GuestCardHeader
          data={data}
          extraActionElements={extraActionElements}
          onNotesActionClick={notesModalToggle}
        />
      </Card>

      {notes && (
        <GuestNotesListModal
          isOpen={isNotesModalOpen}
          toggle={notesModalToggle}
          pmsGuestId={pmsGuestId}
          notes={notes}
          name={name}
        />
      )}
    </>
  );
};
