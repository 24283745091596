const formatText = (num: number, text: string) =>
  `${num} ${text}${num > 1 ? "s" : ""}`;

export const relativeDate = (dateString: string) => {
  const then = Math.floor(new Date(dateString).getTime() / 1000);
  const now = Math.floor(new Date().getTime() / 1000);
  const numberOfSeconds = now - then;
  let output = "";

  if (numberOfSeconds < 60) {
    // Less than a minute has passed:
    output = `${formatText(numberOfSeconds, "seconds")} ago`;
  } else if (numberOfSeconds < 3600) {
    // Less than an hour has passed:
    const numberOfMinutes = Math.floor(numberOfSeconds / 60);
    output = `${formatText(numberOfMinutes, "minute")} ago`;
  } else if (numberOfSeconds < 86400) {
    // Less than a day has passed:
    const numberOfHours = Math.floor(numberOfSeconds / 3600);
    output = `${formatText(numberOfHours, "hour")} ago`;
  } else if (numberOfSeconds < 2620800) {
    // Less than a month has passed:
    const numerOfDays = Math.floor(numberOfSeconds / 86400);
    output = `${formatText(numerOfDays, "day")} ago`;
  } else {
    // difference < 2620800 - less than a month (## days ago)
    output = dateString
      ? `${Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }).format(new Date(dateString))} ${Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(dateString))}`
      : "";
  }

  return output;
};
