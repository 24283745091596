import { EntityId, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AbstractEntity,
  App__GlobalState,
  JsonapiAsyncThunkConfig,
  JsonapiPage,
  JsonapiResponseFetchMany,
  JsonapiResponseFetchManyMeta,
  JsonapiSort,
  JsonapiSortOrder,
  ListView,
  PageSize,
} from "@src/types";

export const createJsonapiListActions = <
  Entity extends AbstractEntity,
  Filter extends { [key: string]: any },
  SortKey extends string,
  ModuleName extends Readonly<string>,
  ListName extends Readonly<string>
>({
  moduleName,
  listName,
  fetchManyServiceFn,
}: {
  moduleName: ModuleName;
  listName: ListName;
  fetchManyServiceFn: ({
    params: { __globalFilter, filter, page, sort },
  }: {
    params: {
      __globalFilter: {
        pmsPropertyId?: EntityId | null;
      };
      filter: Filter;
      page: JsonapiPage;
      sort: JsonapiSort<SortKey>;
    };
    signal: AbortSignal;
  }) => Promise<{
    data: Entity[];
    meta: JsonapiResponseFetchManyMeta;
  }>;
}) => {
  const actionBaseName = `${moduleName}/${listName}` as const;

  const fetch = createAsyncThunk<
    JsonapiResponseFetchMany<Entity>,
    void,
    JsonapiAsyncThunkConfig & {
      state: {
        app: {
          __global: App__GlobalState;
        } & {
          [key in ModuleName]: {
            [key in ListName]: {
              filter: Filter;
              page: JsonapiPage;
              sort: JsonapiSort<SortKey>;
            };
          };
        };
      };
    }
  >(
    `${actionBaseName}/fetch`,
    async (payload, { rejectWithValue, getState, signal }) => {
      try {
        const {
          app: {
            __global: { filter: __globalFilter },
            [moduleName]: {
              [listName]: { filter, page, sort },
            },
          },
        } = getState();

        return await fetchManyServiceFn({
          params: { __globalFilter, filter, page, sort },
          signal,
        });
      } catch (err) {
        return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
      }
    }
  );

  const resetStatus = createAction(`${actionBaseName}/resetStatus`);
  const setView = createAction<ListView>(`${actionBaseName}/setView`);
  const setFilter = createAction<Filter>(`${actionBaseName}/setFilter`);
  const resetFilter = createAction(`${actionBaseName}/resetFilter`);
  const setPageSize = createAction<PageSize>(`${actionBaseName}/setPageSize`);
  const setPageNumber = createAction<number>(`${actionBaseName}/setPageNumber`);
  const setSort = createAction<{ key: SortKey; order: JsonapiSortOrder }>(
    `${actionBaseName}/setSort`
  );
  const setSortKey = createAction<SortKey>(`${actionBaseName}/setSortKey`);
  const setSortOrder = createAction<JsonapiSortOrder>(
    `${actionBaseName}/setSortOrder`
  );
  const resetSort = createAction(`${actionBaseName}/resetSort`);

  return {
    fetch,
    resetFilter,
    resetSort,
    resetStatus,
    setFilter,
    setView,
    setPageSize,
    setPageNumber,
    setSort,
    setSortKey,
    setSortOrder,
  };
};
