import md5 from "md5";
import { getDefaultAvatar } from "@src/utility";

export const getGravatarUrl = (
  email: string | undefined,
  gender: "F" | "M" | null = "M",
  size: number = 105,
) => {
  const defaultAvatar = getDefaultAvatar(gender);
  if (email === undefined) return defaultAvatar;
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=blank`;
};
