import "./EntityListCardsView.scss";

import { arrayOf50 } from "@src/constants";
import { AbstractEntity } from "@src/types";
import ReactPlaceholder from "react-placeholder";
import { Col, Row } from "reactstrap";

export type EntityListCardsViewProps<T> = {
  data?: T[] | null;

  cardComponent: React.ComponentType<{ className?: string; data: T }>;
  cardPlaceholderComponent: React.ComponentType<{}>;

  noDataNode: React.ReactNode;
};

export const EntityListCardsView = <T extends AbstractEntity>({
  data,
  cardComponent: EntityCard,
  cardPlaceholderComponent: EntityCardPlaceholder,
  noDataNode,
}: EntityListCardsViewProps<T>) => {
  const cardsViewPlaceholder = (
    <>
      {arrayOf50.map((v, i) => (
        <Col md={12} key={`reservation-card-placeholder-${i}`}>
          <EntityCardPlaceholder />
        </Col>
      ))}
    </>
  );

  const content = data ? (
    data.length > 0 ? (
      data.map((o, i) => (
        <Col md={12} key={`reservation-card-${i}`}>
          <EntityCard className="entity-card" data={o} />
        </Col>
      ))
    ) : (
      <Col>{noDataNode}</Col>
    )
  ) : null;

  return (
    <Row>
      <ReactPlaceholder
        ready={!!data}
        customPlaceholder={cardsViewPlaceholder}
        showLoadingAnimation
      >
        {content}
      </ReactPlaceholder>
    </Row>
  );
};
