export const apiFormatDate = (date: Date, timeZone: string) =>
  `${Intl.DateTimeFormat("en-US", {
    year: "numeric",
    timeZone,
  }).format(date)}-${Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    timeZone,
  }).format(date)}-${Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    timeZone,
  }).format(date)}`;
