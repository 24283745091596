import { EntityId } from "@reduxjs/toolkit";
import { getOneGuestActions } from "@src/redux/Guest";
import { guestIncludedPropertyReservationsByIdSelector } from "@src/redux/selectors";

import { useEntityDetails } from "./useEntityDetails";

export const useGuestDetails = (id: EntityId) =>
  useEntityDetails(id, {
    fetch: getOneGuestActions.fetch,
    entitySelector: guestIncludedPropertyReservationsByIdSelector,
    statusSelector: (state, id) => state.app.guest.byId[id],
  });
