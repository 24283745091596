import { EntityId, createAction } from "@reduxjs/toolkit";
import { guestServices } from "@src/services";
import { ListView } from "@src/types";

import { jsonapiAsyncThunkFactory } from "../jsonapiAsyncThunkFactory";

const fetch = jsonapiAsyncThunkFactory({
  name: "guest/one/fetch",
  serviceFn: guestServices.fetchOne,
});

const resetStatus = createAction<EntityId>("guest/one/resetStatus");

const setReservationsView = createAction<{ id: EntityId; view: ListView }>(
  `guest/one/setReservationsView`
);

export const getOneGuestActions = {
  fetch,
  resetStatus,
  setReservationsView,
};
