import { JsonapiSort } from "@src/types";

import { statusStateInit } from "./statusStateInit";

export const listStatusStateInit = <SortKey extends string>(
  sort: JsonapiSort<SortKey>
) =>
  ({
    ...statusStateInit,
    filter: {},
    meta: {
      totalCount: undefined,
      totalPages: undefined,
    },
    page: {
      number: 1,
      size: 50,
    },
    sort,
    view: "cards",
  } as const);
