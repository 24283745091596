import themeConfig from "@configs/themeConfig";
import { RouterTransition } from "@src/types";
import { SetStateAction, useState } from "react";

export const useRouterTransition = () => {
  const [transition, setTransition] = useState<RouterTransition>(() => {
    try {
      return themeConfig.layout.routerTransition;
    } catch (error) {
      console.log(error);
      return themeConfig.layout.routerTransition;
    }
  });

  const setValue = (value: SetStateAction<RouterTransition>) => {
    try {
      const valueToStore =
        value instanceof Function ? value(transition) : value;

      setTransition(valueToStore);
    } catch (error) {
      console.log(error);
    }
  };

  return [transition, setValue] as const;
};
