import { useAppDispatch } from "@hooks/.";
import { LayoutFallbackComponent } from "@src/components";
import { userActions } from "@src/redux/actions";
import { Override } from "@src/types";
import { ErrorBoundary } from "react-error-boundary";
import { Route, RouteProps } from "react-router-dom";

export type RouteErrorBoundaryProps = RouteProps;

export const RouteErrorBoundary: React.FC<
  Override<RouteErrorBoundaryProps, { component?: undefined }>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = ({ render, children, component, ...props }) => {
  const dispatch = useAppDispatch();

  const handleResetError = () => {
    dispatch(userActions.logout());
  };

  if (render) {
    return (
      <Route
        {...props}
        render={(componentProps) => (
          <ErrorBoundary
            FallbackComponent={LayoutFallbackComponent}
            onReset={handleResetError}
          >
            {render(componentProps)}
          </ErrorBoundary>
        )}
      ></Route>
    );
  }

  return (
    <Route {...props}>
      <ErrorBoundary
        FallbackComponent={LayoutFallbackComponent}
        onReset={handleResetError}
      >
        {children}
      </ErrorBoundary>
    </Route>
  );
};
