/**
 * @param dateString "YYYY-MM-DD" format
 * @param cfg [number, number][] config for month, day and year slice
 * @returns Simple MM/DD/YYYY format
 */
export const formatDate = (
  dateString: string,
  cfg: [number, number][] = [
    [5, 7],
    [8, 10],
    [0, 4],
  ]
) => {
  if (!dateString) return "";
  const dS = (a: number, b: number) => dateString.slice(a, b);

  const formattedDate = cfg.map((cfgEntry) => dS(...cfgEntry)).join("/");

  return formattedDate;
};
