import {
  GlobalFilterByPropertyForm,
  NavbarNotifications,
  NavbarSearch,
} from "@components/.";
import UserDropdown from "@layouts/components/navbar/UserDropdown";
import { Skin } from "@src/types";
import { Menu } from "react-feather";
import { NavItem, NavLink } from "reactstrap";
import { useLocation } from "react-router-dom";

export type NavbarNavigationProps = {
  skin: Skin;

  toggleSidebarNav: () => void;
};

export const NavbarNavigation: React.FC<NavbarNavigationProps> = ({
  skin,

  toggleSidebarNav,
}) => {
  const currentURL = useLocation().pathname;
  const showPropertySelect = currentURL !== "/messages";

  return (
    <>
      <ul className="navbar-nav d-xl-none d-flex align-items-center">
        <NavItem className="mobile-menu mr-auto">
          <NavLink
            className="nav-menu-main menu-toggle hidden-xs is-active mr-1"
            onClick={toggleSidebarNav}
          >
            <Menu className="ficon" />
          </NavLink>
        </NavItem>
      </ul>
      <div className="bookmark-wrapper d-flex align-items-center flex-grow-1">
        <GlobalFilterByPropertyForm
          skin={skin}
          className="flex-grow-1"
          showPropertySelect={showPropertySelect}
        />
      </div>
      <ul className="nav navbar-nav align-items-center ml-auto">
        <NavbarSearch />
        <NavbarNotifications />
        <UserDropdown />
      </ul>
    </>
  );
};
