import { guestDummyEntry } from "@src/constants/guest";
import { GuestEntity, Override } from "@src/types";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";

export const reservationDummyEntry: Override<
  ReservationListColumnNodeProps,
  { mainGuest: GuestEntity }
> = {
  id: "",
  confirmationNumber: "",
  bookedAt: "_",
  bookedAtInPropertyTz: "_",
  checkInDate: "",
  checkOutDate: "",
  mainGuest: guestDummyEntry,
  mainGuestName: "",
  roomName: "",
  roomType: "",
  property: { id: "", property_time_zone: "", property_name: "" },
  pmsStatus: "no_show",
  priceTotal: "",
  pmsPropertyId: "",
  adults: 0,
  children: 0,
  estimatedArrivalTime: "",
  estimatedDepartureTime: "",
  mainGuestIsVip: false,
  notes: [],
  preCheckInCompleted: false,
  rent: "",
  roomHousekeepingStatus: null,
  roomKeyCode: null,
  mainGuestId: "",
  isVip: false,
};
