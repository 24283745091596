import {
  reservationListFilterKeys,
  reservationListSortKeys,
} from "@src/constants";
import { getManyReservationsActions } from "@src/redux/Reservation";
import { reservationsByIdsSelector } from "@src/redux/selectors";

import { useEntityList } from "./useEntityList";

export const useReservationList = () =>
  useEntityList({
    fetch: getManyReservationsActions.fetch,
    setPageNumber: getManyReservationsActions.setPageNumber,
    setView: getManyReservationsActions.setView,
    setSort: getManyReservationsActions.setSort,

    __globalFiltersActive: { pmsPropertyId: true },
    filterKeys: reservationListFilterKeys,
    sortKeys: reservationListSortKeys,

    entitiesSelector: reservationsByIdsSelector,
    statusSelector: (state) => state.app.reservation.allList,
  });
