import { userActions } from "@src/redux/actions";

import { useForm } from "./useForm";

export const useUserLoginForm = () =>
  useForm({
    initValues: {
      email: "",
      password: "",
    },

    statusSelector: (state) => state.app.user.login,

    resetFormErrorActionCreator: userActions.loginResetStatus,
    submitFormActionCreator: userActions.login,
  });
