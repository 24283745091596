import "@styles/base/pages/app-chat.scss";
import "@styles/base/pages/app-chat-list.scss";

import "./ChatLogSimple.scss";
import "./ChatLogSimplePlaceholder.scss";

import { usePlaceholderColor } from "@hooks/usePlaceholderColor";
import { PlaceholderTextRow } from "@src/components";
import { ChatContact, ChatLogEntryFormatted } from "@src/types";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ButtonGroup, Form, InputGroup } from "reactstrap";

import { isChatMaximized } from "./../../utility";
import { PlaceholderRoundShape } from "../Placeholder";

export type ChatLogSimplePlaceholderProps = {};

export const ChatLogSimplePlaceholder: React.FC<ChatLogSimplePlaceholderProps> =
  () => {
    const placeholderColor = usePlaceholderColor();

    const selectedUser: ChatContact = {
      avatar: "",
      fullName: "",
      gender: null,
      status: "online",
    };

    const groupedChatData: ChatLogEntryFormatted[] = [
      {
        messages: [{ msg: "", time: "" }],
      },
      {
        senderId: "",
        messages: [{ msg: "", time: "" }],
      },
      {
        messages: [{ msg: "", time: "" }],
      },
    ];

    const groupedMessageElements = groupedChatData.map((item, index) => {
      const isGuestMessage = item.senderId !== undefined;

      return (
        <div
          key={index}
          className={classnames("chat", {
            "chat-left": isGuestMessage,
          })}
        >
          <div className="chat-avatar">
            <PlaceholderRoundShape />
          </div>

          <div className="chat-body">
            {item.messages.map((chat) => (
              <div key={chat.msg}>
                <div
                  className="chat-content"
                  style={{
                    backgroundColor: placeholderColor,
                  }}
                ></div>
              </div>
            ))}

            <div
              className={classnames("text-small mb-2", {
                "float-left": isGuestMessage,
                "float-right": !isGuestMessage,
                "ml-1": isGuestMessage,
                "mr-1": !isGuestMessage,
              })}
            >
              <PlaceholderTextRow />
            </div>
          </div>
        </div>
      );
    });

    return (
      <div
        className={classnames("chat-app-window chat-log-simple-placeholder", {
          "mb-2": !isChatMaximized,
        })}
      >
        <div
          className={classnames("active-chat", {
            "d-none": selectedUser === null,
          })}
        >
          <PerfectScrollbar
            className="user-chats"
            options={{ wheelPropagation: false }}
          >
            <div className="chats">{groupedMessageElements}</div>
          </PerfectScrollbar>

          <Form className="chat-app-form">
            <InputGroup className="input-group-merge mr-1 form-send-message">
              <PlaceholderTextRow className="form-control" />
            </InputGroup>
            <ButtonGroup>
              <PlaceholderTextRow className="send btn" />
            </ButtonGroup>
          </Form>
        </div>
      </div>
    );
  };
