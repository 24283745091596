import { reservationListAllTableConfig } from "@configs/Reservation/reservationListAllTableConfig";
import { EntityId, createEntityAdapter } from "@reduxjs/toolkit";
import {
  Filter,
  GuestEntity,
  Override,
  PropertyEntity,
  PropertyStatistics,
  ReservationEntity,
  ConversationEntity,
} from "@types";

/** Filters */

const appReservationBookingTableFilter =
  createEntityAdapter<Filter<keyof typeof reservationListAllTableConfig>>();

const appStatisticsProperty = createEntityAdapter<PropertyStatistics>();

/** Database entities */

const guest = createEntityAdapter<
  GuestEntity & { reservations?: EntityId[] | undefined | null }
>();
const property = createEntityAdapter<PropertyEntity>();
const reservation = createEntityAdapter<
  Override<
    ReservationEntity,
    {
      guests?: EntityId[] | null;
      mainGuest?: undefined;
      mainGuestId: EntityId;
    }
  >
>();
const conversation = createEntityAdapter<ConversationEntity>();

export const entityAdapters = {
  appReservationBookingTableFilter,
  appStatisticsProperty,

  guest,
  property,
  reservation,
  conversation,
};
