import { IconPlaceholder, PlaceholderTextRow } from "@components/.";

export const GuestCardSimplePlaceholder: React.FC = () => (
  <a style={{ pointerEvents: "none", cursor: "default" }}>
    <li className="suggestion-item suggestion-item-placeholder">
      <div>
        <div className="item-container d-flex ellipsis-wrapper">
          <div style={{ marginTop: "3px" }}>
            <IconPlaceholder size={17} />
          </div>
          <div
            className="d-flex align-items-center item-info ml-1 d-flex flex-grow-1 ellipsis-wrapper"
            style={{ whiteSpace: "nowrap" }}
          >
            <div
              className="align-middle mb-0 ellipsis"
              style={{ fontSize: "calc(1.35 * 1rem)" }}
            >
              <PlaceholderTextRow style={{ width: "150px" }} />
            </div>
            <div
              className="d-flex align-items-center mx-1 ellipsis-wrapper"
              style={{ overflow: "hidden", whiteSpace: "nowrap" }}
            >
              <div
                className="align-middle mb-0 ellipsis"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "1.1rem",
                }}
              >
                <PlaceholderTextRow style={{ width: "120px" }} />
              </div>
            </div>
            <div className="d-flex align-items-center text-right ml-auto ellipsis-wrapper">
              <div
                className="align-middle mb-0 ellipsis"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "1.1rem",
                }}
              >
                <PlaceholderTextRow style={{ width: "180px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </a>
);
