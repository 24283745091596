import { userActions } from "@src/redux/actions";
import { store } from "@src/redux/storeConfig/store";
import axios from "axios";
import { errorSlice } from "@store/slices/errorSlices";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    store.dispatch(errorSlice.actions.setUrl(error.config.url));
    if (error?.response?.status === 401) {
      store.dispatch(userActions.logout());
    }

    return Promise.reject(error);
  }
);
