import "./PropertyStatistics.scss";

import { StatisticsCard } from "@components/.";
import { useAppDispatch } from "@hooks/.";
import { useAppSelector } from "@hooks/useAppSelector";
import { reservationListFilterPredefinedConfig } from "@src/configs/Reservation/reservationListFilterPredefinedConfig";
import { statisticActions } from "@src/redux/actions/statisticsActions";
import { getManyReservationsActions } from "@src/redux/Reservation";
import { PropertyEntity } from "@src/types";
import {
  apiFormatDate,
  formatNumberToStringWithCommasAndCurrency,
  getDateNow,
  numberToString,
} from "@src/utility";
import { useEffect } from "react";
import { Book, DollarSign, Eye, Smile } from "react-feather";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";

type PropertyStatisticsTodayProps = {
  property: PropertyEntity;
};

export const PropertyStatisticsToday: React.FC<PropertyStatisticsTodayProps> =
  ({
    property: { id: pmsPropertyId, property_time_zone: propertyTimeZone },
  }) => {
    const dispatch = useAppDispatch();

    const history = useHistory();

    const propertyStatistics = useAppSelector(
      (state) => state.app.statistics.property.entities[pmsPropertyId]
    );

    const todayLocalApiDateString =
      propertyTimeZone && apiFormatDate(getDateNow(), propertyTimeZone);

    const predefinedFilterConfig =
      reservationListFilterPredefinedConfig(propertyTimeZone);

    const {
      fetchPropertyTodayReservationsTotalCount,
      fetchPropertyTodayRentRevenueStatistics,
      fetchPropertyTodayNotYetChecked,
      fetchPropertyTodayCheckedIn,
    } = statisticActions;

    useEffect(() => {
      const promises = [
        dispatch(fetchPropertyTodayReservationsTotalCount()),
        dispatch(fetchPropertyTodayRentRevenueStatistics()),
        dispatch(fetchPropertyTodayNotYetChecked()),
        dispatch(fetchPropertyTodayCheckedIn()),
      ];

      return () => promises.forEach((promise) => promise.abort());
    }, [pmsPropertyId]);

    const handleTodayBookingsClick = todayLocalApiDateString
      ? () => {
          dispatch(
            getManyReservationsActions.setFilter(
              predefinedFilterConfig.bookedToday.value
            )
          );

          history.push("/reservations");
        }
      : undefined;

    const handleGuestOnPropertyClick = () => {
      dispatch(
        getManyReservationsActions.setFilter(
          predefinedFilterConfig.checkedIn.value
        )
      );

      history.push("/reservations");
    };

    const handleArrivalsNotYetCheckedInClick = todayLocalApiDateString
      ? () => {
          dispatch(
            getManyReservationsActions.setFilter(
              predefinedFilterConfig.arrivalsNotYetCheckedIn.value
            )
          );

          history.push("/reservations");
        }
      : undefined;

    const bookingsCount =
      numberToString(propertyStatistics?.today.reservationsTodayTotalCount) ||
      "n/d";

    /** @fixme Monetary values shouldn't be delivered as floats */
    const rentRevenue = propertyStatistics?.today.rentRevenue;

    const formattedRentRevenue =
      rentRevenue !== undefined
        ? formatNumberToStringWithCommasAndCurrency(rentRevenue)
        : "n/d";

    const guestsCheckedInCount =
      numberToString(
        propertyStatistics?.today.reservationsCheckedInTodayTotalCount
      ) || "n/d";

    const arrivalsNotYetCheckedInCount =
      numberToString(
        propertyStatistics?.today.reservationsNotYetCheckedInTodayTotalCount
      ) || "n/d";

    return (
      <div className="property-statistics-today">
        <Row className="mb-2">
          <Col sm="12" className="ml-50 property-statistics-title">
            <p
              className="font-medium-5 mt-1 extension-title"
              data-tour="extension-title"
            >
              Today
            </p>
          </Col>
        </Row>
        <Row className="match-height">
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconBg="warning"
              className="stats-card-body-mobile"
              icon={<Book className="warning" size={22} />}
              stat={bookingsCount}
              statTitle="Total Bookings"
              onClick={handleTodayBookingsClick}
              statDataCy="property-statistics-today-total-bookings"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconBg="success"
              className="stats-card-body-mobile"
              icon={<DollarSign className="success" size={22} />}
              stat={formattedRentRevenue}
              statTitle="Rent Revenue"
              statDataCy="property-statistics-today-rent-revenue"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconBg="danger"
              className="stats-card-body-mobile"
              icon={<Smile className="info" size={22} />}
              stat={guestsCheckedInCount}
              statTitle="Reservations checked-in"
              onClick={handleGuestOnPropertyClick}
              statDataCy="property-statistics-today-reservations-checked-in"
            />
          </Col>
          <Col lg="3" sm="6">
            <StatisticsCard
              hideChart
              iconBg="secondary"
              className="stats-card-body-mobile"
              icon={<Eye className="secondary" size={22} />}
              stat={arrivalsNotYetCheckedInCount}
              statTitle="Arrivals not yet checked in"
              onClick={handleArrivalsNotYetCheckedInClick}
              statDataCy="property-statistics-today-arrivals-not-yet-checked-in"
            />
          </Col>
        </Row>
      </div>
    );
  };
