import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import axios from "axios";

export const patchOneNotificationAsReadService = async ({
  signal,
  id,
  read = true,
}: {
  signal: AbortSignal;
  id: EntityId;
  read: boolean;
}) => {
  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  if (!!id) {
    await axios.request({
      method: "PATCH",
      url: `${apiConfig.baseUrl}/notifications/${id}`,
      headers: { Accept: "application/vnd.api+json" },
      withCredentials: true,
      cancelToken: source.token,
      data: {
        read,
      },
    });
  }

  return { data: { id } };
};
