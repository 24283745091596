import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
  AsyncThunk,
  Draft,
  EntityId,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  AbstractEntity,
  AbstractListFilter,
  JsonapiErrorResponseData,
  JsonapiPayloadFetchMany,
  JsonapiSortOrder,
  ListStatusState,
  ListView,
  Override,
  PageSize,
  ThunkServiceErrorExtended,
} from "@src/types";

import { asyncThunkListStatusReducers } from "./asyncThunkListStatusReducers";
import { AsyncThunkStatusSliceFactoryOptions } from "./asyncThunkStatusSliceFactory";

export type AsyncThunkListStatusSliceFactoryOptions<
  Name extends string,
  Entity extends AbstractEntity,
  SortKey extends string,
  Filter extends AbstractListFilter
> = Override<
  AsyncThunkStatusSliceFactoryOptions<Name>,
  {
    initialState: ListStatusState<SortKey, Filter>;
    fetch: AsyncThunk<
      JsonapiPayloadFetchMany<Entity>,
      any,
      { rejectValue: ThunkServiceErrorExtended<JsonapiErrorResponseData> }
    >;
  }
> & {
  setView: ActionCreatorWithPayload<ListView>;
  setFilter: ActionCreatorWithPayload<Partial<Filter>>;
  resetFilter: ActionCreatorWithoutPayload;
  setPageSize: ActionCreatorWithPayload<PageSize>;
  setPageNumber: ActionCreatorWithPayload<number>;
  setSort: ActionCreatorWithPayload<{ key: SortKey; order: JsonapiSortOrder }>;
  setSortKey: ActionCreatorWithPayload<SortKey>;
  setSortOrder: ActionCreatorWithPayload<JsonapiSortOrder>;
  resetSort: ActionCreatorWithoutPayload;

  __setGlobalFilterPmsPropertyId?: ActionCreatorWithPayload<EntityId>;
};

export const asyncThunkListStatusSliceFactory = <
  Name extends string,
  T extends AbstractEntity,
  SortKey extends string,
  Filter extends AbstractListFilter
>({
  name,
  initialState,

  fetch,
  resetStatus,
  resetFilter,
  setFilter,
  setPageNumber,
  setPageSize,
  setSort,
  setSortKey,
  setSortOrder,
  setView,

  __setGlobalFilterPmsPropertyId,
}: AsyncThunkListStatusSliceFactoryOptions<Name, T, SortKey, Filter>) =>
  createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetch.pending, asyncThunkListStatusReducers.pending)
        .addCase(fetch.rejected, asyncThunkListStatusReducers.rejected)
        .addCase(fetch.fulfilled, asyncThunkListStatusReducers.fulfilled)
        .addCase(resetStatus, asyncThunkListStatusReducers.resetStatus)
        .addCase(setView, asyncThunkListStatusReducers.setView)
        .addCase(setFilter, asyncThunkListStatusReducers.setFilter)
        .addCase(resetFilter, asyncThunkListStatusReducers.resetFilter)
        .addCase(setPageSize, asyncThunkListStatusReducers.setPageSize)
        .addCase(setPageNumber, asyncThunkListStatusReducers.setPageNumber)
        .addCase(setSort, asyncThunkListStatusReducers.setSort)
        .addCase(
          setSortKey,
          (
            state: Draft<ListStatusState<SortKey, Filter>>,
            action: PayloadAction<SortKey>
          ) =>
            ({
              ...state,
              ids: undefined,
              page: { ...state.page, number: 1 },
              sort: { key: action.payload, order: "ASC" },
            } as Draft<ListStatusState<SortKey, Filter>>)
        )
        .addCase(setSortOrder, asyncThunkListStatusReducers.setSortOrder);

      if (__setGlobalFilterPmsPropertyId) {
        builder.addCase(
          __setGlobalFilterPmsPropertyId,
          asyncThunkListStatusReducers.__setGlobalFilterPmsPropertyId
        );
      }
    },
  });
