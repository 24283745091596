import { useEffect } from "react";
import { FallbackProps } from "react-error-boundary";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

export type LayoutFallbackComponentProps = FallbackProps;

export const LayoutFallbackComponent: React.FC<LayoutFallbackComponentProps> =
  ({ resetErrorBoundary }) => {
    useEffect(() => {
      const resetTimeout = setTimeout(resetErrorBoundary, 4000);

      return () => clearTimeout(resetTimeout);
    });

    return (
      <div className="wrapper bg-primary">
        <Container className="bg-primary">
          <Row>
            <Col
              className=""
              md={{ size: 10, offset: 1 }}
              lg={{ size: 8, offset: 2 }}
              xxl={{ size: 6, offset: 3 }}
            >
              <Card className="my-5 mx-1 mx-md-0">
                <CardBody className="p-5">
                  <CardTitle className="mb-4">
                    <h1 className="display-4">
                      Ooops... Something went wrong...
                    </h1>
                  </CardTitle>
                  <CardTitle className="mb-3">
                    <h1 className="mb-2">
                      In a moment you will be redirected to the login page.
                    </h1>
                    <h3 className="">Please try to sign in again.</h3>
                  </CardTitle>
                  <CardText className="lead mb-3">
                    Most of the screens like this are forced by application
                    updates.
                  </CardText>
                  <CardText className="">
                    If the problem persists, please try again in a moment. We
                    are probably working on it right now.
                  </CardText>
                  <Button
                    className="mt-3"
                    color="primary"
                    onClick={resetErrorBoundary}
                  >
                    Go to login page...
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
