export const reservationReportColumnNames = [
  "number",
  "guestName",
  "repeatGuest",
  "arrivalTime",
  "checkInDate",
  "checkOutDate",
  "nightsCount",
  "guestsCount",
  "roomTypes",
  "rooms",
  "checkedIn",
  "pmsStatus",
  "notes",
  "priceTotal",
  "paidTotal",
  "remainingBalance",
  "source",
  "cancellationDate",
] as const;
