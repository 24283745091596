import Avatar from "@components/avatar";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { userActions } from "@src/redux/actions";
import { getDefaultAvatar, getGravatarUrl } from "@src/utility";
import { Power, User } from "react-feather";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const UserDropdown = () => {
  const dispatch = useAppDispatch();

  const { email } = useAppSelector((state) => state.app.auth.data);

  const handleLogout = () => {
    dispatch(userActions.logout());
  };

  const userAvatar = email ? getGravatarUrl(email) : getDefaultAvatar(null);

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          {email && (
            <span className="user-name font-weight-bold mb-0">{email}</span>
          )}
        </div>
        <Avatar
          img={userAvatar}
          fallbackSrc={getDefaultAvatar(null)}
          icon={<User />}
          imgHeight="40"
          imgWidth="40"
        />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={handleLogout}>
          <Power size={14} className="mr-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
