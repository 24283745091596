import { EntityId } from "@reduxjs/toolkit";
import {
  AppState,
  ConversationEntity,
  GuestEntity,
  Override,
} from "@src/types";
import { ReservationListColumnNodeProps } from "@src/views/Reservation/reservationListColumnNodes";

import { entityAdapters } from "./entityAdapters";

const guest = entityAdapters.guest.getSelectors<AppState>(
  (state) => state.entities.guest
);

const property = entityAdapters.property.getSelectors<AppState>(
  (state) => state.entities.property
);

const reservation = entityAdapters.reservation.getSelectors<AppState>(
  (state) => state.entities.reservation
);

const conversation = entityAdapters.conversation.getSelectors<AppState>(
  (state) => state.entities.conversation
);

export const selectors = {
  guest,
  property,
  reservation,
  conversation,
};

export const guestsByIdsSelector = (
  state: AppState,
  ids?: EntityId[] | null
) => {
  if (!ids) {
    return;
  }

  const returnData: GuestEntity[] = [];

  ids.forEach((id) => {
    const entity = selectors.guest.selectById(state, id);

    if (entity) {
      returnData.push(entity);
    }
  });

  return returnData;
};

export const messagesByIdsSelector = (
  state: AppState,
  ids?: EntityId[] | null
) => {
  if (!ids) {
    return;
  }

  const returnData: ConversationEntity[] = [];

  ids.forEach((id) => {
    const entity = selectors.conversation.selectById(state, id);
    if (entity) {
      returnData.push(entity);
    }
  });

  return returnData;
};

export const reservationSelectById = (state: AppState, id: EntityId) => {
  const normalizedEntity = selectors.reservation.selectById(state, id);

  if (normalizedEntity) {
    const mainGuestEntity = selectors.guest.selectById(
      state,
      normalizedEntity.mainGuestId
    );

    const propertyEntity = selectors.property.selectById(
      state,
      normalizedEntity.pmsPropertyId
    );

    if (mainGuestEntity && propertyEntity) {
      const entity = {
        ...normalizedEntity,
        mainGuest: mainGuestEntity,
        guests: undefined,
        property: propertyEntity,
      };

      return entity;
    }
  }
};

export const reservationIncludedGuestsSelectById = (
  state: AppState,
  id: EntityId
) => {
  const normalizedEntity = selectors.reservation.selectById(state, id);

  if (normalizedEntity) {
    const mainGuestEntity = selectors.guest.selectById(
      state,
      normalizedEntity.mainGuestId
    );

    const guestsEntities: GuestEntity[] | null =
      guestsByIdsSelector(state, normalizedEntity.guests) || null;

    const propertyEntity = selectors.property.selectById(
      state,
      normalizedEntity.pmsPropertyId
    );

    if (mainGuestEntity && propertyEntity) {
      const entity = {
        ...normalizedEntity,
        mainGuest: mainGuestEntity,
        guests: guestsEntities,
        property: propertyEntity,
      };

      return entity;
    }
  }
};

export const reservationsByIdsSelector = (
  state: AppState,
  ids?: EntityId[] | null
) => {
  const returnData: Override<
    ReservationListColumnNodeProps,
    {
      guests?: undefined;
      mainGuest: GuestEntity;
      mainGuestId: EntityId;
      attachments?: undefined;
    }
  >[] = [];

  if (!ids) {
    return;
  }

  ids.forEach((id) => {
    const entity = selectors.reservation.selectById(state, id);

    if (entity) {
      const property = selectors.property.selectById(
        state,
        entity.pmsPropertyId
      );

      const mainGuest = selectors.guest.selectById(state, entity.mainGuestId);

      if (property && mainGuest) {
        returnData.push({
          ...entity,
          attachments: undefined,
          property,
          mainGuest,
          guests: undefined,
          mainGuestId: mainGuest.id,
        });
      }
    }
  });

  return returnData;
};

export const guestIncludedReservationsByIdSelector = (
  state: AppState,
  id: EntityId
) => {
  const entity = selectors.guest.selectById(state, id);

  if (entity) {
    const reservations:
      | Override<
          ReservationListColumnNodeProps,
          {
            guests?: undefined;
            mainGuest: GuestEntity;
            mainGuestId: EntityId;
            notes?: string[] | undefined;
          }
        >[]
      | undefined
      | null = entity.reservations
      ? reservationsByIdsSelector(state, entity.reservations)
      : entity.reservations;

    return { ...entity, reservations };
  }
};

export const guestIncludedPropertyReservationsByIdSelector = (
  state: AppState,
  id: EntityId
) => {
  const entity = selectors.guest.selectById(state, id);

  if (entity) {
    const reservations:
      | Override<
          ReservationListColumnNodeProps,
          {
            guests?: undefined;
            mainGuest: GuestEntity;
            mainGuestId: EntityId;
            notes?: string[] | undefined;
          }
        >[]
      | undefined
      | null = entity.reservations
      ? reservationsByIdsSelector(state, entity.reservations)?.filter(
          (reservation) =>
            reservation.pmsPropertyId ===
            state.app.__global.filter.pmsPropertyId
        )
      : entity.reservations;

    return { ...entity, reservations };
  }
};
