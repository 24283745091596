import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonapiAsyncThunkConfig } from "@src/types";

export const jsonapiAsyncThunkFactory = <R, P, Name extends string>({
  name,
  serviceFn,
}: {
  name: Name;
  serviceFn: (payload: P) => Promise<R>;
}): AsyncThunk<R, P, JsonapiAsyncThunkConfig> =>
  createAsyncThunk(name, async (payload, { rejectWithValue }) => {
    try {
      return await serviceFn(payload);
    } catch (err) {
      return rejectWithValue(err as JsonapiAsyncThunkConfig["rejectValue"]);
    }
  });
