import Rollbar from "rollbar";
import { toast } from "react-toastify";

function saveBlob(fileName: string, blob: Blob) {
  // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob);
    return;
  }

  // For other browsers: create a link pointing to the ObjectURL containing the blob.
  const objUrl = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objUrl;
  link.download = fileName;
  link.click();

  // For Firefox it is necessary to delay revoking the ObjectURL.
  setTimeout(() => {
    window.URL.revokeObjectURL(objUrl);
  }, 250);
}

interface Options {
  url: string;
  body?: BodyInit;
  onDownloadProgress?: (receivedLength: number, contentLength: number) => void;
  fetchOptions?:
    | RequestInit
    | ((fetchOptions: RequestInit) => Promise<RequestInit>);
}

export async function downloadFile(options: Options) {
  const { url, onDownloadProgress, fetchOptions, body } = options;

  let requestInit: RequestInit = {
    method: "POST",
    body,
  };

  if (typeof fetchOptions === "function") {
    requestInit = await fetchOptions(requestInit);
  } else if (typeof fetchOptions === "object") {
    requestInit = { ...requestInit, ...fetchOptions };
  }

  const response = await fetch(url, requestInit);

  if (!response.ok) {
    const responseBody = await response.text();
    throw new Error(responseBody ?? "Error try again");
  }

  const reader = response.body?.getReader();

  const contentLength = Number(response.headers.get("Content-Length"));

  let receivedLength = 0;
  const chunks: Uint8Array[] = [];
  while (true) {
    const { done, value } = await reader!.read();

    if (done) break;

    chunks.push(value as Uint8Array);
    receivedLength += value!.length;

    if (typeof onDownloadProgress !== "undefined") {
      onDownloadProgress(receivedLength, contentLength);
    }
  }

  const type = response.headers.get("content-type")?.split(";")[0];

  // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
  const blob = new Blob(chunks, { type });

  return {
    blob,
  };
}

interface DownloadAndSaveFileOptions extends Options {
  defaultFileName: string;
  fileName?: string;
}

export default async function downloadAndSaveFile(
  options: DownloadAndSaveFileOptions
) {
  try {
    const { fileName, defaultFileName, ...rest } = options;

    const { blob } = await downloadFile(rest);

    saveBlob(fileName ?? defaultFileName, blob);
  } catch (error) {
    //@ts-ignore
    Rollbar.error("Reservation report error", error);
    console.error("DOWNLOAD ERROR", error);
    toast.error("Something went wrong, try again.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}
