import { ConditionConfig } from "@src/types/ConditionConfig";
import moment from "moment";

const compareFns = {
  number: (a: number, b: number) => a - b,
  date: (a: string, b: string) => moment(a).diff(moment(b)),
};

const diffFns = {
  lt: (diff: number) => diff < 0,
  lte: (diff: number) => diff <= 0,
  eq: (diff: number) => diff === 0,
  neq: (diff: number) => diff !== 0,
  gt: (diff: number) => diff > 0,
  gte: (diff: number) => diff >= 0,
};

const testObjectFactory = (diffFn: (diff: number) => boolean) => ({
  number: (a: number, b: number) => diffFn(compareFns["number"](a, b)),
  date: (a: string, b: string) => diffFn(compareFns["date"](a, b)),
});

export const filterConditionsConfig: ConditionConfig = {
  lt: {
    label: "Less than",
    test: testObjectFactory(diffFns["lt"]),
  },
  lte: {
    label: "Less than or equal",
    test: testObjectFactory(diffFns["lte"]),
  },
  eq: {
    label: "Equal",
    test: testObjectFactory(diffFns["eq"]),
  },
  neq: {
    label: "Not equal",
    test: testObjectFactory(diffFns["neq"]),
  },
  gt: {
    label: "Greater than",
    test: testObjectFactory(diffFns["gt"]),
  },
  gte: {
    label: "Greater than or equal",
    test: testObjectFactory(diffFns["gte"]),
  },
  oneOf: {
    label: "One of",
    test: {
      number: (a, b) => b.includes(a) as boolean,
      date: (a, b) => b.includes(a) as boolean,
    },
  },
} as const;
