import classnames from "classnames";
import {
  AlertCircle,
  Check,
  MoreVertical,
  Minus,
  Plus,
  X,
} from "react-feather";

export const mapActionToIcon = {
  add: <Plus size={16} />,
  remove: <Minus size={16} />,
  alert: <AlertCircle size={16} />,
  success: <Check size={16} />,
  error: <X size={16} />,
  info: <Check size={16} />,
  wait: <MoreVertical size={16} />,
};

export type TimeLineData = {
  tag?: React.ComponentType<{ className?: string; id?: string }> | string;
  className?: string;
  color?: keyof typeof mapActionToIcon;
  icon?: React.ReactNode;
  title?: string;
  meta?: React.ReactNode;
  metaClassName?: string;
  content?: React.ReactNode;
  customContent?: React.ReactNode;
};

export type TimelineProps = {
  className?: string;
  data: TimeLineData[];
  tag?: React.ComponentType<{ className?: string; id?: string }> | string;
};

export const Timeline: React.FC<TimelineProps> = (props) => {
  // ** Props
  const { data, tag, className } = props;

  // ** Custom Tagg
  const Tag = tag ? tag : "ul";

  return (
    <Tag className={classnames("timeline", className)}>
      {data.map((item, i) => {
        const ItemTag = item.tag ? item.tag : "li";

        if (!item.icon && item.color) {
          item.icon = mapActionToIcon[item.color];
        }

        return (
          <ItemTag
            key={i}
            id={`timeline-item-${i}`}
            /** @fixme Probably shouldn't check className */
            className={classnames("timeline-item", className && item.className)}
          >
            <span
              className={classnames("timeline-point", {
                [`timeline-point-${item.color}`]: item.color,
                "timeline-point-indicator": !item.icon,
              })}
            >
              {item.icon ? item.icon : null}
            </span>
            <div className="timeline-event">
              <div
                className={classnames(
                  "d-flex justify-content-between flex-sm-row flex-column",
                  {
                    "mb-sm-0 mb-1": item.meta,
                  }
                )}
              >
                <h6>{item.title}</h6>
                {item.meta ? (
                  <span
                    className={classnames(
                      "timeline-event-time",
                      item.metaClassName
                    )}
                  >
                    {item.meta}
                  </span>
                ) : null}
              </div>
              <p
                className={classnames({
                  "mb-0": i === data.length - 1 && !item.customContent,
                })}
              >
                {item.content}
              </p>
              {item.customContent}
            </div>
          </ItemTag>
        );
      })}
    </Tag>
  );
};

export default Timeline;
