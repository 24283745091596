import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import { AttachmentEntity } from "@src/types";
import axios from "axios";
import { deserialize } from "jsonapi-fractal";

export const postReservationAttachmentService = async ({
  arg: { id, file },
  signal,
}: {
  arg: { id: EntityId; file: File };
  signal: AbortSignal;
}) => {
  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  const formData = new FormData();

  formData.append("attachment[file]", file);

  const { data } = await axios.request({
    method: "POST",
    url: `${apiConfig.baseUrl}/reservations/${id}/attachments`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
    cancelToken: source.token,
    data: formData,
  });

  return deserialize(data, apiConfig.deserializeOptions) as AttachmentEntity;
};
