import { ErrorState } from "@src/types";
import { Alert, AlertProps } from "reactstrap";
import { useAppSelector } from "@src/utility/hooks";

export type ErrorStateAlertProps = AlertProps & {
  error: ErrorState;
};

export const ErrorStateAlert: React.FC<ErrorStateAlertProps> = ({
  error: { code, message, status, title },
  color = "danger",
  ...alertProps
}) => {
  const { url } = useAppSelector(
    (state) => state.app.__global.error.errorEndpoint
  );
  return (
    <Alert color={color} {...alertProps}>
      <h4 className="alert-heading">
        [{status}] {title || code}
      </h4>
      <div className="alert-body">{message}</div>
      <div className="alert-body">{`Endpoint: ${url}`}</div>
      <div className="alert-body">{`Local: ${new Date().toLocaleString()}`}</div>
      <div className="alert-body">{`UTC: ${new Date().toUTCString()}`}</div>
    </Alert>
  );
};
