import { forwardRef, memo, useEffect } from "react";
import classNames from "classnames";
import type { DraggableSyntheticListeners } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import { Menu } from "react-feather";
import { Button } from "reactstrap";

import "./ReservationReportColumnItem.scss";

export type ReservationReportColumnItemProps = {
  dragOverlay?: boolean;
  disabled?: boolean;
  dragging?: boolean;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  selected?: boolean;
  transition?: string | null;
  children: string;
  onClick?: () => void;
};

export const ReservationReportColumnItem = memo(
  forwardRef<HTMLLIElement, ReservationReportColumnItemProps>(
    (
      {
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        index,
        listeners,
        sorting,
        transition,
        transform,
        children,
        onClick,
        selected,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      return (
        <li
          className={classNames("reservation-report-column-item-wrapper", {
            fadeIn,
            sorting,
            dragOverlay,
          })}
          style={
            {
              transition,
              "--translate-x": transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              "--translate-y": transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              "--scale-x": transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              "--scale-y": transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              "--index": index ? `${index}` : undefined,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <div
            className={classNames("reservation-report-column-item", {
              dragging,
              dragOverlay,
              disabled,
              clickable: !!onClick,
              selected,
            })}
            {...props}
            onClick={onClick}
          >
            <div className="reservation-report-column-item-content ellipsis-wrapper">
              <p className="ellipsis">{children}</p>
            </div>
            <div className="reservation-report-column-item-actions">
              <Button size="sm" color="link" className="handle">
                <Menu size="14" {...listeners} />
              </Button>
            </div>
          </div>
        </li>
      );
    }
  )
);
