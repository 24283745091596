import { useToggle } from "@hooks/useToggle";
import { AbstractListFilter, ListFilterPredefinedConfig } from "@src/types";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export type EntityListFilterDropdownProps<
  FK extends string,
  RCN extends string,
  Filter extends AbstractListFilter,
  FilterPredefinedConfig extends ListFilterPredefinedConfig<
    Filter,
    RCN,
    FK
  > = ListFilterPredefinedConfig<Filter, RCN, FK>
> = {
  value: FK | "all" | null;
  predefinedFilters: FilterPredefinedConfig;
  toggleText: string;
  defaultOptionLabel?: string;

  onClick: (key: FK) => void;
  onCustomClick?: () => void;
  onResetClick: () => void;
};

export const EntityListFilterDropdown = <
  FK extends string,
  RCN extends string,
  Filter extends AbstractListFilter,
  FilterPredefinedConfig extends ListFilterPredefinedConfig<
    Filter,
    RCN,
    FK
  > = ListFilterPredefinedConfig<Filter, RCN, FK>
>({
  value,
  predefinedFilters,
  toggleText,
  defaultOptionLabel,
  onClick,
  onCustomClick,
  onResetClick,
}: EntityListFilterDropdownProps<
  FK,
  RCN,
  Filter,
  FilterPredefinedConfig
>): JSX.Element => {
  const [isDropdownOpen, toggleDropdown] = useToggle();

  const configKeys = Object.keys(predefinedFilters) as FK[];

  const dropdownItemElements = configKeys.map((key) => {
    const handleClick = () => {
      if (key !== value) {
        onClick(key);
      }
    };

    return (
      <DropdownItem onClick={handleClick} key={key}>
        <span className="align-middle">{predefinedFilters[key].label}</span>
      </DropdownItem>
    );
  });

  const handleResetClick = () => {
    if (value !== "all") {
      onResetClick();
    }
  };

  return (
    <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle
        className="ellipsis-wrapper"
        color="secondary"
        outline={true}
        caret
      >
        <span className="align-middle ellipsis">{toggleText}</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleResetClick}>
          {defaultOptionLabel}
        </DropdownItem>
        <DropdownItem divider />

        {dropdownItemElements}

        {onCustomClick && (
          <>
            <DropdownItem divider />
            <DropdownItem onClick={onCustomClick}>Custom</DropdownItem>
          </>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};
