import "./AttachmentCard.scss";

import { AttachmentProps } from "@src/components";
import { JsonapiResponseFetchManyMeta, StatusState } from "@src/types";
import classnames from "classnames";
import throttle from "lodash/throttle";
import { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import { Attachment } from ".";

export type AttachmentCardProps = {
  data: AttachmentProps[] | undefined;
  pendingData: { data: AttachmentProps; status: StatusState }[];
  statusState:
    | (StatusState & {
        meta:
          | JsonapiResponseFetchManyMeta
          | {
              totalCount?: undefined;
              totalPages?: undefined;
            };
      })
    | undefined;

  onFilesDrop: (files: File[]) => void;
};

export const AttachmentCard: React.FC<AttachmentCardProps> = ({
  data,
  pendingData,
  statusState,

  onFilesDrop,
}) => {
  const [isDroppableOver, setIsDroppableOver] = useState(false);
  const [isDroppableLeaving, setIsDroppableLeaving] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAddAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  /** @todo Temporary solution to present UI */
  const handleFilesDrop = (files: File[]) => {
    setIsDroppableOver(false);
    setIsDroppableLeaving(false);

    onFilesDrop(files);
  };

  /** useDropzone setup */

  const onDrop = useCallback(handleFilesDrop, [
    setIsDroppableOver,
    setIsDroppableLeaving,
    /**
     * This dependency is absolutely necessary for proper operation
     * of useDropzone (onFilesDrop changes when parent id changes).
     */
    onFilesDrop,
  ]);

  const onDragEnter = useCallback(() => {
    setIsDroppableLeaving(false);
    setIsDroppableOver(true);
  }, []);

  const fnToDelay = (isLeaving: boolean) => {
    if (isLeaving) {
      setIsDroppableOver(false);
      setIsDroppableLeaving(false);
    }
  };

  const onDragLeaveDelayed = throttle(fnToDelay, 100, {
    leading: false,
    trailing: true,
  });

  const onDragLeave = useCallback(() => {
    setIsDroppableLeaving(true);
    onDragLeaveDelayed(isDroppableLeaving);
  }, [isDroppableLeaving, setIsDroppableLeaving, onDragLeaveDelayed]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    multiple: false,
  });

  const isAnyAttachment = (data && data.length > 0) || pendingData.length > 0;

  const attachmentElements =
    data &&
    data.length > 0 &&
    data.map((attachmentProps, index) => (
      <Col
        key={index}
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="12"
        className="col-xxl-6"
      >
        <Attachment {...attachmentProps} />
      </Col>
    ));

  const pendingAttachmentElements =
    pendingData.length > 0 &&
    pendingData.map(({ data }, index) => (
      <Col
        key={index}
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="12"
        className="col-xxl-6"
      >
        <Attachment {...data} />
      </Col>
    ));

  const emptyElement = !isAnyAttachment && (
    <Col>
      <div className="attachments-droppable">
        Drag &amp; drop or click here to upload attachment
      </div>
    </Col>
  );

  return (
    <div
      className={classnames("card attachment-card", {
        "drag-over-empty": isDroppableOver,
        "drag-over-active": isAnyAttachment && isDroppableOver,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <CardHeader className="align-items-end">
        <CardTitle>
          Attachments
          {statusState?.meta.totalCount !== undefined &&
            ` (${statusState?.meta.totalCount})`}
        </CardTitle>
        {isAnyAttachment && (
          <a className="add small" onClick={handleAddAttachmentClick}>
            Add more
          </a>
        )}
      </CardHeader>
      <CardBody>
        <Row>
          {attachmentElements}
          {pendingAttachmentElements}
          {emptyElement}
        </Row>
      </CardBody>
    </div>
  );
};
