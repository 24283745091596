import "./Note.scss";

import { EntityId } from "@reduxjs/toolkit";
import trim from "lodash/trim";

export type NoteProps = { id?: EntityId; content: string };

export const Note: React.FC<NoteProps> = ({ id, content }) => (
  <div className="note-body">
    {content
      .split("\n")
      .filter((note) => trim(note).length > 0)
      .map((str, i) => (
        <p key={`note-p-${id}-${i}`}>{str}</p>
      ))}
  </div>
);
