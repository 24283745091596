import "./NavbarSearchCustomItemPlaceholder.scss";

import {
  GuestCardSimplePlaceholder,
  ReservationCardSimplePlaceholder,
} from "..";

export const NavbarSearchCustomItemPlaceholder: React.FC<{
  type: "reservation" | "guest";
}> = ({ type }) => {
  switch (type) {
    case "guest":
      return <GuestCardSimplePlaceholder />;
    case "reservation":
      return <ReservationCardSimplePlaceholder />;
  }
};
