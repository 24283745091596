import {
  MessageTemplateAttributes,
  MessageTemplateEntity,
  PropertyMessageTemplate,
} from "@src/types/_entities/MessageTemplateEntity";
import axios from "axios";
import { apiConfig } from "@configs/apiConfig";
import { EntityId } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import {
  appMessagesTemplatesSlice,
  validateMessageTemplatesSlice,
} from "@store/slices";
import { store } from "@store/storeConfig/store";
import { ExpandedPropertyEntity } from "@types";

type TemplatePropsType = {
  today: number;
  week: number;
  propertyEntities: { [key: EntityId]: ExpandedPropertyEntity | undefined };
};

function getMessageTemplatesService({
  today,
  week,
  propertyEntities,
}: TemplatePropsType) {
  const propertyIDs = Object.keys({ ...propertyEntities, other: [] });

  const templatesByProperty: PropertyMessageTemplate = propertyIDs.reduce(
    (acc, curr) => ({ ...acc, [curr]: [] }),
    {}
  );

  axios.get(`${apiConfig.baseUrl}/quick_reply_templates`).then(({ data }) => {
    const templatesData = data.data.map(
      (templateItem: MessageTemplateEntity) => {
        return { id: templateItem.id, ...templateItem.attributes };
      }
    );

    templatesData.forEach((template: MessageTemplateAttributes) => {
      const templatePropertyIds = template.propertyIds;

      const isMatch = templatePropertyIds.some((id: EntityId) =>
        propertyIDs.includes(id as string)
      );

      if (isMatch && !isEmpty(template.propertyIds)) {
        templatePropertyIds.forEach((propertyId: EntityId) => {
          templatesByProperty[propertyId].push(template);
        });
      }

      if (isEmpty(template.propertyIds)) {
        templatesByProperty["other"].push(template);
      }
    });

    store.dispatch(
      validateMessageTemplatesSlice.actions.setExpirationTime(today + week)
    );

    store.dispatch(
      appMessagesTemplatesSlice.actions.setTemplates(templatesByProperty)
    );
  });
}

export default getMessageTemplatesService;
