import {
  Reducer,
  applyMiddleware,
  compose,
  createStore,
} from "@reduxjs/toolkit";
import { rootReducer } from "@src/redux/rootReducer";
import { AppState } from "@src/types";
import createDebounce from "redux-debounced";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const reducerToPersist: Reducer<AppState> = (state, action) => {
  const newState = action.type === "user/logout" ? undefined : state;

  return rootReducer(newState, action);
};

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistReducer(persistConfig, reducerToPersist),
  composeEnhancers(applyMiddleware(thunk, createDebounce(), logger))
);

const persistor = persistStore(store);

export { persistor, store };
