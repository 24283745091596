import { Dispatch } from "@reduxjs/toolkit";

export const handleContentWidth =
  (value: "full" | "boxed") => (dispatch: Dispatch) =>
    dispatch({ type: "HANDLE_CONTENT_WIDTH", value });

export const handleMenuCollapsed = (value: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: "HANDLE_MENU_COLLAPSED", value });

export const handleMenuHidden = (value: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: "HANDLE_MENU_HIDDEN", value });

export const handleRTL = (value: boolean) => (dispatch: Dispatch) =>
  dispatch({ type: "HANDLE_RTL", value });
