import { GuestEntity } from "@src/types";

type GuestListColumn = React.FC<GuestEntity>;

export const GuestListColumnAddress: GuestListColumn = ({
  address1,
  address2,
  city,
  state,
  zipCode,
  country,
}) => {
  const addressLines: string[][] = [[], []];

  if (address1) {
    addressLines[0].push(address1);
  }
  if (address2) {
    addressLines[0].push(address2);
  }

  if (city) {
    addressLines[1].push(`${city},`);
  }
  if (state) {
    addressLines[1].push(state);
  }
  if (zipCode && zipCode !== "Not provided") {
    addressLines[1].push(zipCode);
  }
  if (country) {
    addressLines[1].push(country);
  }

  const content = addressLines
    .map((lineElements) => lineElements.join(" ").trim())
    .filter((addressLine) => addressLine.length > 0)
    .map((addressLine) => <p className="mb-0">{addressLine}</p>);

  return <div>{content}</div>;
};
