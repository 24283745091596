import { EntityId } from "@reduxjs/toolkit";
import { isChatMaximized } from "./isChatMaximized";

export const maximizeChat = (guestId: EntityId, tabId?: EntityId) => {
  if (isChatMaximized) {
    window.close();
    return;
  }

  const windowWidth = 1000;
  const windowHeight = 800;

  const { pathname } = window.location;
  const path = pathname.startsWith("/reservations/")
    ? "reservations"
    : "guests";
  const pathCont = path === "guests" ? tabId : guestId;

  const chatWindow = window.open(
    `/${path}/${pathCont}?chat=true&id=${tabId ?? guestId}`,
    "Chat App",
    `location=0,status=1,scrollbars=1,width=${windowWidth},height=${windowHeight}`
  );

  chatWindow?.moveTo(Math.floor((window.screen.width - windowWidth) / 2), 100);
};
