import "./GuestCardHeader.scss";

import Avatar from "@components/avatar";
import {
  CakeIcon,
  CalendarIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/outline";
import {
  AvatarWithTooltip,
  EmphasisedName,
  GuestDetailLink,
  ReservationDetailsLink,
} from "@src/components/";
import { GuestEntity } from "@src/types";
import {
  formatDate,
  getDefaultAvatar,
  getGravatarUrl,
  isDateToday,
} from "@src/utility";
import classnames from "classnames";
import {
  BookOpen,
  FileText,
  Gift,
  Mail,
  MapPin,
  Moon,
  Phone,
  Star,
} from "react-feather";
import { Button, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap";

export type GuestCardHeaderProps = {
  data: GuestEntity;
  toggler?: JSX.Element;

  extraActionElements?: React.ReactNode;

  onNotesActionClick?: () => void;
};

export const GuestCardHeader: React.FC<GuestCardHeaderProps> = ({
  data,
  toggler,
  extraActionElements,

  onNotesActionClick,
}) => {
  const {
    birthday,
    city,
    country,
    email,
    gender,
    id,
    isCurrentlyStaying,
    isVip,
    lastStay,
    lastStayDate,
    notes,
    phone,
    state,
    totalNights,
    totalReservations,
    totalSpent,
  } = data;

  const avatarSrc = email ? getGravatarUrl(email) : getDefaultAvatar(gender);

  const hasBirthdayToday = birthday ? isDateToday(birthday) : false;

  const locationFormatted = [city, state, country]
    .filter((v) => v && typeof v === "string" && v.length > 0)
    .join(", ");

  const avatarElement = isCurrentlyStaying ? (
    <AvatarWithTooltip
      img={avatarSrc}
      imgClassName={classnames("guest-image", {
        "is-currently-staying": isCurrentlyStaying,
      })}
      fallbackSrc={getDefaultAvatar(gender)}
      imgHeight="105"
      imgWidth="105"
      htmlId={`guest-${id}-card-avatar`}
      tooltipText="Guest in-house"
      tooltipPlacement="right"
    />
  ) : (
    <Avatar
      img={avatarSrc}
      imgClassName="guest-image"
      fallbackSrc={getDefaultAvatar(gender)}
      imgHeight="105"
      imgWidth="105"
    />
  );

  const guestIconsWrapper = (isVip || hasBirthdayToday) && (
    <div className="d-inline-flex">
      {isVip && <Star className="filled-orange" />}
      {hasBirthdayToday && <Gift className="filled-orange" />}
    </div>
  );

  const isNotesButtonDisabled = notes?.length === 0;

  const notesTooltip = isNotesButtonDisabled && (
    <UncontrolledTooltip
      target={`reservation-card-header-actions-button-container-${id}`}
    >
      No notes yet.
    </UncontrolledTooltip>
  );

  const lastStayDateFormatted = lastStayDate ? formatDate(lastStayDate) : null;

  const lastStayValue = lastStay?.id ? (
    <ReservationDetailsLink id={lastStay.id}>
      {lastStayDateFormatted}
    </ReservationDetailsLink>
  ) : (
    lastStayDateFormatted || "---"
  );

  const birthDateFormatted = birthday ? formatDate(birthday) : null;

  const extraActionElementsContainer = (onNotesActionClick ||
    extraActionElements) && (
    <div className="entity-card-header-actions">
      {extraActionElements}
      <div
        className="entity-card-header-actions-button-container"
        id={`reservation-card-header-actions-button-container-${id}`}
      >
        <Button
          disabled={isNotesButtonDisabled}
          color="action"
          outline
          onClick={onNotesActionClick}
          title="Notes"
        >
          <FileText size={18} />
        </Button>
      </div>
      {notesTooltip}
    </div>
  );

  const emailDataRow = email && (
    <div className="entity-card-header-data-row ellipsis-wrapper">
      <Mail size={18} />
      <p className="ellipsis interactive">
        <a href={`mailto:${email}`}>{email}</a>
      </p>
    </div>
  );

  const phoneDataRow = phone && (
    <div className="entity-card-header-data-row ellipsis-wrapper">
      <Phone size={18} />
      <p className="ellipsis font-weight-bolder">{phone}</p>
    </div>
  );

  const locationAndBirthdayRow = (locationFormatted || birthday) && (
    <Row>
      {locationFormatted && (
        <Col sm={4}>
          <div className="entity-card-header-data-row ellipsis-wrapper">
            <MapPin size={18} />
            <p className="ellipsis font-weight-bolder">{locationFormatted}</p>
          </div>
        </Col>
      )}
      {birthday && (
        <Col sm={4}>
          <UncontrolledTooltip target={`guest-card-header-birthday-${id}`}>
            Birthday
          </UncontrolledTooltip>
          <div className="entity-card-header-data-row ellipsis-wrapper">
            <CakeIcon width="22" height="22" />
            <p
              className="ellipsis font-weight-bolder"
              id={`guest-card-header-birthday-${id}`}
            >
              {birthDateFormatted}
            </p>
          </div>
        </Col>
      )}
    </Row>
  );

  return (
    <CardBody className="guest-card-header entity-card-header">
      <div className="entity-card-header-toggler-wrapper">
        <div className="entity-card-header-content">
          <div className="mr-1">
            <GuestDetailLink id={id} key={`guest-details-${id}`}>
              {avatarElement}
            </GuestDetailLink>
            <UncontrolledTooltip
              target={`guest-card-header-total-bookings-nights-${id}`}
            >
              Total bookings / nights
            </UncontrolledTooltip>
            <div
              id={`guest-card-header-total-bookings-nights-${id}`}
              className="ellipsis guest-card-header-total-bookings-nights"
            >
              {totalReservations}
              <BookOpen size={13} /> / {totalNights}
              <Moon size={13} />
            </div>
          </div>
          <div className="entity-card-header-info">
            <div style={{ display: "flex" }}>
              <GuestDetailLink id={id}>
                <span className="entity-card-header-name">
                  <span className="interactive">
                    <EmphasisedName guest={data} />
                  </span>
                  {guestIconsWrapper}
                </span>
              </GuestDetailLink>
              {extraActionElementsContainer}
            </div>
            <div className="entity-card-header-data-row-wrapper">
              {emailDataRow}
              <Row>
                <Col sm={4}>{phoneDataRow}</Col>
                <Col sm={4}>
                  <UncontrolledTooltip
                    target={`guest-card-header-total-spent-${id}`}
                  >
                    Total spent
                  </UncontrolledTooltip>
                  <div className="entity-card-header-data-row ellipsis-wrapper">
                    <CurrencyDollarIcon width="22" height="22" />
                    <p
                      className="ellipsis font-weight-bolder"
                      id={`guest-card-header-total-spent-${id}`}
                    >
                      ${Number(totalSpent)}
                    </p>
                  </div>
                </Col>
                <Col sm={4}>
                  <UncontrolledTooltip
                    target={`guest-card-header-last-stay-${id}`}
                  >
                    Last stay
                  </UncontrolledTooltip>
                  <div className="entity-card-header-data-row ellipsis-wrapper">
                    <CalendarIcon width="22" height="22" />
                    <p
                      className="ellipsis font-weight-bolder"
                      id={`guest-card-header-last-stay-${id}`}
                    >
                      {lastStayValue}
                    </p>
                  </div>
                </Col>
              </Row>
              {locationAndBirthdayRow}
            </div>
          </div>
        </div>

        {toggler}
      </div>
    </CardBody>
  );
};
